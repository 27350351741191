import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "./index.css";

class SimpleTable extends Component {
  constructor(props) {
    super();
  }

  render() {
    const { list } = this.props;

    if (!list || Object.keys(list).length === 0) {
      return <div>Nenhum dado fornecido.</div>;
    }

    const columns = [
      ...new Set(
        Object.values(list)
          .map(item => Object.keys(item))
          .flat(),
      ),
    ];

    return (
      <div className="table-index-container">
        <Table bordered hover>
          <thead className="thead-simple-table">
            <tr>
              {columns.map((column, index) => {
                var nomeModulo = 'nomeModulo';
                if (index !== 6 && index !== 3 && column !== 'nomeModulo' ) {
                  return <th key={index}>{column}</th>;
                }
              })}
            </tr>
          </thead>

          <tbody>
            {Object.keys(list).map((key, index) => {
              var backgroundColor1 = list[index].Cor1;
              var backgroundColor2 = list[index].Cor2;
              var NomeColumn = list[index].nomeModulo;
              
              return (
                <tr key={index}>
                 
                  {columns.map((column, columnIndex) => {
                    
                    
                     
                    let backgroundColor = null;

                    if (backgroundColor1 !== null && (column == "ContatoN1" || column == "N1")) {
                      backgroundColor = backgroundColor1;
                    } else if (backgroundColor2 !== null && (column == "ContatoN2" || column == "N2")) {
                      backgroundColor = backgroundColor2;
                    }
                    const cellValue = list[key][column] != null ? list[key][column].toString() : "-";
                    if (cellValue.includes(";") && column !== "imagem" && cellValue.includes(";") && columnIndex !== 6 && columnIndex !== 3 && columnIndex !== 7) {
                      const subValues = cellValue.split(";");
                      return (
                        <td key={columnIndex} style={{ background: backgroundColor }} className="td-simple-table">
                          {subValues.map((subValue, subIndex) => (
                            <React.Fragment key={subIndex}>
                              {subIndex > 0 && <br />} {/* Adiciona quebra de linha entre os valores */}
                              {subValue}
                            </React.Fragment>
                          ))}
                        </td>
                      );
                    } else if (column === "imagem") {
                      
                      if (!cellValue) {
                        return (
                          <td key={columnIndex} style={{ background: backgroundColor }} className="td-simple-table">
                            <h4>{NomeColumn}</h4>
                          </td>
                        );
                      } else {
                        return (
                          <td key={columnIndex} style={{ background: backgroundColor }} className="td-simple-table">
                            <img src={cellValue} alt="Imagem" className="imagem-table" />
                          </td>
                        );
                      }
                    } else if (columnIndex !== 6 && columnIndex !== 3  && columnIndex !== 7) {
                      return (
                        <td key={columnIndex} style={{ background: backgroundColor }} className="td-simple-table">
                          {cellValue}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default SimpleTable;
