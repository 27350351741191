import React, { Component } from "react";
import BuscaPorFaturas from "./tela-busca-por-faturas/busca-faturas";
import BuscaPorStatus from "./tela-busca-por-status/busca-status";
import MenuHorizontal from "../components/menu/menu-horizontal/index.js";
import Back from "../main/back";
import ResumoLateral from "../components/resumo-lateral";
import { getRedes } from "../Functions/redes/getRedes";
import Moment from "moment";

import "./financeiro.css";
import AppContext from "../appContext";
import { redirectPage } from "../components/utils/redirectpage";

import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";

class Financeiro extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected_option: 1,
      redeOticaAux: false,
      menuOptions: [],
      listaPapeis: [],
      laboratorios: [],
      laboratorio: "",
      cnpj_number: "",
      redes: "",
      cnpj_number_aux: "",
      detailOrder: "",
      laboratorio_cnpj: "",
      laboratorio_nome: "",
      campoCnpjLiberado: false,
      input_cnpj: false,
    };
    this.handleChangeProps = this.handleChangeProps.bind(this);
    if (localStorage.getItem("token") !== null) {
      Financeiro.contextType = AppContext;

      let role = false;
      role = Financeiro.contextType._currentValue.userSession.modulos.find(element => element === "Financeiro");
      if (!role) {
        this.state = { redirect: true };
      }
    } else {
      this.state = { redirectL: true };
    }
  }
  componentDidMount() {
    redirectPage(this.context.userSession, "/financeiro");
    if (localStorage.getItem("token")) {
      if (this.context.userSession.redeOticas) {
        this.setState({ redeOticaAux: false });
      }

      // integração no OEC para ver todos os papéis que tem marcação de multiplas óticas
      const papeis = [];
      const requestOptions = {
        method: "GET",
      };

      fetch(process.env.REACT_APP_LABS_API + "get_multioptics_roles.php", requestOptions)
        .then(response => {
          return response.json();
        })
        .then(json => {
          if (json.status === "sucesso") {
            if (json.items) {
              for (var i = 0; i < json.items.length; i++) {
                var papel = {
                  nome: json.items[i].recordName,
                };
                papeis.push(papel);
              }
            }
            this.setState({ listaPapeis: papeis });

            var user_role = this.context.userSession.papel;
            var roles = JSON.stringify(this.state.listaPapeis);

            // compara os papéis de multiplas oticas do OEC com o papel do usuário logado, se ele estiver na lista, o campo CNPJ aparece na tela
            if (roles && roles.match(user_role)) {
              this.setState({
                input_cnpj: true,
              });
            }
          }
        });
    } else {
      this.setState({ redirectL: true });
    }
    if (this.context.userSession.papelCategoria === "ESSILOR") {
      this.setState({ redeOticaAux: true });
    }
  }

  borderColor(idElement, color = "#f70017") {
    //#f70017  #B5B5B5
    if (typeof idElement === "object") {
      idElement.map(el => {
        if (document.getElementById(el)) {
          return (document.getElementById(el).style.boxShadow = `0 0 0 1px ${color}`);
        }
        return null;
      });
    } else {
      document.getElementById(idElement).style.boxShadow = `0 0 0 1px ${color}`;
    }
  }

  setLabData(e, label, detailOrder, params) {
    if (e.value) {
      if (this.context.userSession.papelCategoria === "LABORATORIO") {
        if (e.value === this.context.userSession.conta) {
          this.setState({ campoCnpjLiberado: true, cnpj_number: this.state.cnpj_number_aux });
        } else {
          this.setState({ campoCnpjLiberado: false, cnpj_number_aux: this.state.cnpj_number, cnpj_number: "" });
        }
      } else {
        this.setState({ campoCnpjLiberado: true });
      }

      if (this.context.userSession.redeOticas) {
        this.context.showLoading("Buscando...");
        this.listarRedesOticas(e.value);
      }

      this.setState({
        lab: params,
      });
      this.setState({
        laboratorio_nome: label,
        laboratorio_cnpj: e.value,
        detailOrder: detailOrder,
      });
      this.borderColor("lab", "#B5B5B5");
    } else {
      this.setState({
        laboratorio_nome: "",
        laboratorio_cnpj: "",
        detailOrder: false,
        lab: null,
      });
    }
  }

  obterOtica(laboratorio) {
    const requestOptions1 = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cnpj: this.context.userSession.conta }),
    };

    fetch(process.env.REACT_APP_LABS_API + "get_account_data.php", requestOptions1)
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (this.context.userSession.redeOticas) {
          this.context.showLoading("Buscando...");
          this.setState({ oticaPrincipal: json.nome, cnpjPrincipal: json.cnpj }, this.listarRedesOticas(laboratorio));
        } else {
          const redes = [];
          var rede = {
            label: json.nome,
            value: json.cnpj,
          };
          redes.push(rede);
          this.setState({ redes: redes, cnpjPrincipal: json.cnpj });
        }
      });
  }

  handleChange(e) {
    let state = {};
    const name = e.target.getAttribute("name");
    const { value } = e.target;

    //valida o formato do numero do pedido laboratorio
    //pedido otica pode ser qualquer coisa, então não tem validação
    if (e.target.getAttribute("name") === "pedido_laboratorio") {
      if (!Number(value) && value !== "") {
        return;
      }
    }
    //seta o numero do pedido no state
    state[name] = value ? value : "";
    this.setState({ status: value }, () => {
      if (value === "PENDENTE") {
        this.setState({ statusMessage: 'O resultado da busca com status "Pendente" contempla as parcelas pendentes de pagamento, incluindo as já vencidas.' });
      } else if (value === "BAIXADO") {
        this.setState({ statusMessage: 'O resultado da busca com status "Pago" contempla as parcelas dos últimos 120 dias.' });
      } else if (value === "AVENCER") {
        this.setState({ statusMessage: 'O resultado da busca com status "A vencer" contempla as parcelas vencendo em 15 dias.' });
      }
    });
    this.setState(state);
    this.borderColor(name, "#B5B5B5");
  }

  handleChange_data_inicio = date => {
    //trata o formato da data
    const diaInicio = date ? Moment(date).format("YYYY-MM-DD") : "";
    this.setState({
      //seta data para integração
      data_inicio: diaInicio,
      //seta data para exibir no input date
      dia_inicio: date,
    });
  };

  handleChange_data_fim = date => {
    //trata o formato da data
    const diaFim = date ? Moment(date).format("YYYY-MM-DD") : "";
    this.setState({
      //seta data para integração
      data_fim: diaFim,
      //seta data para exibir no input date
      dia_fim: date,
    });
  };

  changeState() {
    this.borderColor(["lab", "cnpj_number"], "#B5B5B5");
    this.borderColor(["data_inicio", "data_fim", "pedido_otica"], "#FFF");
    this.setState({
      loading: true,
      data_tabela: [],
      resposta_tabela: false,
      classOpacity: "Opacity",
    });

    let valido = this.validarCampos();
    if (valido) {
      this.context.showLoading("Buscando...");
      this.integraPedido();
    }
  }

  openSummary(e) {
    this.setState({ [e.target.id]: e.target.value }, () => {
      if (this.state.open) {
        this.setState({ open: "", arrow: "" });
      } else {
        this.setState({ open: "show-summary", arrow: "active-arrow" });
      }
    });
  }

  checkHealth() {
    const requestOptions = {
      method: "GET",
      headers: {
        laboratorio: this.state.laboratorio,
      },
    };

    fetch(process.env.REACT_APP_ESSILOR_API + "gestao-produtos-laboratorio/v1/health", requestOptions).then(response => {
      this.setState({ statusProduct: response.status }, () => {
        fetch(process.env.REACT_APP_ESSILOR_API + "gestao-pedidos-laboratorio/v1/health", requestOptions).then(response => {
          this.setState({ statusOrder: response.status }, async () => {
            if (this.state.statusProduct === 200 || this.state.statusOrder === 200) {
              // se passar no health o codigo ja busca as redes
              this.listarRedesOticas(this.state.laboratorio);
              return true;
            } else {
              this.context.hideLoading();
              Swal.fire({
                html: "O serviço está temporariamente indisponível para este laboratório.<br /> O time do Essilor Conecta já está atuando para resolver o problema.",
                width: "auto",
                icon: "warning",
                title: "Aviso",
                confirmButtonColor: "#00518b",
                customClass: {
                  confirmButton: "x-follow-order__filter-btn",
                },
              }).then(result => {
                if (result.value) {
                  this.setState({
                    redirect: false,
                  });
                }
              });

              return false;
            }
          });
        });
      });
    });
  }

  validarCampos() {
    if (!this.state.laboratorio_nome) {
      this.setState({
        loading: false,
        classOpacity: "Nopacity",
      });
      this.context.hideLoading();
      Swal.fire({
        text: "Selecione um laboratório.",
        title: "Aviso",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      this.borderColor("lab");
      return;
    } else if (this.context.userSession.redeOticas && this.state.redeOticaAux && !this.state.cnpj_number) {
      this.setState({
        loading: false,
        classOpacity: "Nopacity",
      });
      this.context.hideLoading();
      Swal.fire({
        text: "Selecione uma ótica.",
        title: "Aviso",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      this.borderColor("cnpj_number");
      return;
    } else if (this.state.input_cnpj && this.state.redeOticaAux && !this.state.cnpj_number && !this.context.userSession.redeOticas) {
      this.setState({
        loading: false,
        classOpacity: "Nopacity",
      });
      this.context.hideLoading();
      Swal.fire({
        text: "O campo CNPJ é obrigatório.",
        title: "Aviso",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      this.borderColor("cnpj_number");
      return;
    } else if (this.state.status != "PENDENTE" && this.state.status != "BAIXADO" && this.state.status != "AVENCER") {
      this.setState({
        loading: false,
        classOpacity: "Nopacity",
      });
      this.context.hideLoading();
      Swal.fire({
        text: "Preencha o status da Parcela.",
        title: "Aviso",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      this.borderColor(["status"]);
      return;
    }

    if (this.state.data_inicio === "" || this.state.data_fim === "") {
      this.setState({
        loading: false,
        classOpacity: "Nopacity",
      });
      this.context.hideLoading();
      Swal.fire({
        text: " Por favor, informe um período válido para o seu pedido.",
        title: "Aviso",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      this.borderColor(["data_inicio", "data_fim"]);
      return;
    } else if (this.state.data_inicio > this.state.data_fim) {
      this.setState({
        loading: false,
        classOpacity: "Nopacity",
      });
      this.context.hideLoading();
      Swal.fire({
        text: "Período da busca inválido: Data inicial maior que data final.",
        title: "Aviso",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      this.borderColor(["data_inicio", "data_fim"]);
      return;
    } else {
      let ini = new Date(this.state.data_inicio);
      let fim = new Date(this.state.data_fim);
      let diff = (fim.getTime() - ini.getTime()) / 1000 / 3600 / 24;
      if (diff > 60) {
        this.setState({
          loading: false,
          classOpacity: "Nopacity",
        });
        this.context.hideLoading();
        Swal.fire({
          text: "O período de busca não pode ser superior a 60 dias.",
          title: "Aviso",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
        this.borderColor(["data_inicio", "data_fim"]);
        return;
      }
    }
    return true;
  }

  integraPedido() {
    var status = this.state.status !== "AVENCER" ? this.state.status : "PENDENTE";
    var table = [];
    const requestOptions = {
      method: "GET",
      headers: {
        access_token: localStorage.getItem("token"),
        client_id: localStorage.getItem("clientid"),
        laboratorio: this.state.laboratorio_cnpj,
        otica: this.state.cnpj_number.replace(/[^0-9]+/g, ""),
      },
    };
    fetch(process.env.REACT_APP_ESSILOR_API + `gestao-financeira-cliente-laboratorio/v1/faturas?status=${status}`, requestOptions)
      .then(response => {
        return response.json();
      })
      .then(response => {
        let data = [];
        let today = new Date();
        let next15Days = new Date();
        next15Days.setDate(next15Days.getDate() + 15);

        if (this.state.status !== "AVENCER") {
          response.forEach(fatura => {
            let dataVencimento = new Date(fatura.dataVencimento);
            var statusFatura = fatura.status;
            if (dataVencimento >= today && dataVencimento <= next15Days) {
              statusFatura = "AVENCER";
            }
            data.push({
              codigo: fatura.codigo,
              parcela: fatura.parcela.substring(0, 2) + "/" + fatura.parcela.substring(2),
              nDocumento: fatura.numeroDocumento,
              dataEmissao: fatura.dataEmissao,
              dataVencimento: fatura.dataVencimento,
              valor: fatura.valor,
              valorAberto: fatura.valorAberto,
              status: statusFatura,
            });
          });
          data.sort((a, b) => {
            if (Moment(a.dataVencimento) < Moment(b.dataVencimento)) return -1;
            if (Moment(a.dataVencimento) > Moment(b.dataVencimento)) return 1;
            return 0;
          });
        } else {
          response.forEach(fatura => {
            let dataVencimento = new Date(fatura.dataVencimento);
            var statusFatura = fatura.status;
            if (dataVencimento >= today && dataVencimento <= next15Days) {
              statusFatura = "AVENCER";
              data.push({
                codigo: fatura.codigo,
                parcela: fatura.parcela.substring(0, 2) + "/" + fatura.parcela.substring(2),
                nDocumento: fatura.numeroDocumento,
                dataEmissao: fatura.dataEmissao,
                dataVencimento: fatura.dataVencimento,
                valor: fatura.valor,
                valorAberto: fatura.valorAberto,
                status: fatura.status,
              });
            }
            table.push({
              codigo: fatura.codigo,
              parcela: fatura.parcela.substring(0, 2) + "/" + fatura.parcela.substring(2),
              nDocumento: fatura.numeroDocumento,
              dataEmissao: fatura.dataEmissao,
              dataVencimento: fatura.dataVencimento,
              valor: fatura.valor,
              valorAberto: fatura.valorAberto,
              status: statusFatura,
            });
          });
          data.sort((a, b) => {
            if (Moment(a.dataVencimento) < Moment(b.dataVencimento)) return -1;
            if (Moment(a.dataVencimento) > Moment(b.dataVencimento)) return 1;
            return 0;
          });
        }
        this.setState({
          data_tabela: data,
          table: table,
          resposta_tabela: true,
          loading: false,
          classOpacity: "Nopacity",
        });
        this.context.hideLoading();
      })
      .catch(() => {
        this.setState({
          data_tabela: [],
          resposta_tabela: false,
          loading: false,
          classOpacity: "Nopacity",
        });
        this.context.hideLoading();
        //TK-083-000169 - Adicionado a mensagem abaixo em caso de falha na integração.
        Swal.fire({
          text: "Falha de comunicação com o laboratório. Tente novamente mais tarde.",
          title: "Aviso",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
      });
  }

  listarRedesOticas = async laboratorio => {
    if (this.context.userSession.modulos.includes("Consulta Rede")) {
      const body = {
        usuario: this.context,
        laboratorio: laboratorio,
        consultaRede: this.context.userSession.modulos.includes("Consulta Rede"),
      };

      try {
        const result = await getRedes(body);
        if (result) {
          if (result.status !== 200) {
            this.setState({ cnpj_number_aux: this.state.cnpj_number, cnpj_number: "", redeOticaAux: false });
          } else if (result.status === 200) {
            this.setState({ redes: result.redes, redeOticaAux: true, cnpj_number: this.state.cnpj_number_aux });
          }
        }
      } catch (error) {
        console.error("Erro ao usar a função getRedes:", error.message);
      }
    } else {
      this.setState({ cnpj_number_aux: this.state.cnpj_number, cnpj_number: "" });
    }
    setTimeout(() => {
      this.context.hideLoading();
    }, 1000);
  };

  handleChangeOption(option) {
    if (option !== this.state.selected_option) {
      this.setState({
        selected_option: option,
      });
    }
  }
  handleChangeProps(param, tipo = null, valor = null) {
    if (param.name === "lab") {
      this.setState({ laboratorio: param.value }, () => {
        if (Boolean(param.value)) {
          this.checkHealth();
        }
      });
    } else if (tipo === "boolean") {
      this.setState({ [param]: valor });
    } else if (param.name === "cnpj_number") {
      this.setState({ cnpj_number: param.value });
    } else if (tipo === "laboratorios") {
      this.setState({ laboratorios: param });
    } else if (tipo === "dadosLab") {
      this.setState({ lab: param });
    } else if (param === "detailOrder" || param === "laboratorio_cnpj" || param === "laboratorio_nome") {
      this.setState({ [param]: param });
    }
  }
  render() {
    const menuOptions = [
      {
        id: 1,
        label: "Faturas",
        component: (
          <BuscaPorFaturas
            redeOticaAux={this.state.redeOticaAux}
            input_cnpj={this.state.input_cnpj}
            handleChangeProps={this.handleChangeProps}
            statusOrder={this.state.statusOrder}
            statusProduct={this.state.statusProduct}
            listaPapeis={this.state.listaPapeis}
            laboratorio={this.state.laboratorio}
            cnpj_number={this.state.cnpj_number}
            cnpj_number_aux={this.state.cnpj_number_aux}
            redes={this.state.redes}
            listarRedesOticas={this.listarRedesOticas}
            laboratorio_dados={this.state.lab}
            laboratorios={this.state.laboratorios}
            campoCnpjLiberado={this.state.campoCnpjLiberado}
          />
        ),
      },
      {
        id: 2,
        label: "Busca por Status",
        component: (
          <BuscaPorStatus
            redeOticaAux={this.state.redeOticaAux}
            input_cnpj={this.state.input_cnpj}
            handleChangeProps={this.handleChangeProps}
            status={this.state.statusOrder}
            statusProduct={this.state.statusProduct}
            listaPapeis={this.state.listaPapeis}
            laboratorio={this.state.laboratorio}
            cnpj_number={this.state.cnpj_number}
            cnpj_number_aux={this.state.cnpj_number_aux}
            redes={this.state.redes}
            listarRedesOticas={this.listarRedesOticas}
            laboratorio_dados={this.state.lab}
            laboratorios={this.state.laboratorios}
            campoCnpjLiberado={this.state.campoCnpjLiberado}
          />
        ),
      },
    ];

    return (
      <main className="main-financeiro">
        <div id="container-financeiro-page">
          <Back />
          <div className="title-container">
            <h1 className="main-title">Acompanhe sua movimentação</h1>
            <span className="title-shape"></span>
          </div>

          <div>
            <MenuHorizontal menuOptions={menuOptions} />
          </div>
        </div>

        <ResumoLateral
          logoTransparente={"https://ec2.beta.essilorconecta.com.br/modulos/financeiroTransparente.png"}
          lab={this.state?.lab?.dados}
          lab_phone={this.state.lab?.dados?.phone}
          lab_email={this.state.lab?.dados?.email}
          lab_site={this.state.lab?.dados?.site}
          lab_logo={this.state.lab?.dados?.logo}
        />
      </main>
    );
  }
}

export default Financeiro;
