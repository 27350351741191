import React, { Component } from "react";

//Import CSS

//Import internal components
import ButtonYN from "../../../components/buttons/button-yn";
import ButtonRL from "../../../components/buttons/button-rightleft";
import Select from "../../../components/selects/select";
import SelectAuto from "../../../components/selects/select-auto";
import Input from "../../../components/inputs/input";
import CustomButton from "../../../components/buttons/button";

import * as valueList from "./order-perscription-lists";

//Import external components
import Alert from "sweetalert2";

//Import images
const esferico = valueList.ESFERICO;
const cilindrico = valueList.CILINDRICO;
const eixo = valueList.EIXO;
const adicao = valueList.ADICAO;
const prisma = valueList.PRISMA;

class OrderPerscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      adicao: "N/A",
      dnp: "N/A",
      altura: "N/A",
      dnpPerto: "N/A",
      iniciais: "N/A",
      distLeitura: "N/A",
      cvp: "N/A",
      cro: "N/A",
      wMap: "N/A",
      angPanto: "N/A",
      curvArma: "N/A",
      coefHE: "N/A",
      coefST: "N/A",
      distProg: "N/A",
      distVert: "N/A",
      olhoDom: "N/A",
      selectedOption: null,
      et: "N/A",
      ct: "N/A",
      base: "N/A",
    };

    this.esfericoEsquerdoRef = React.createRef();

    this.cilindricoDireitoRef = React.createRef();
    this.cilindricoEsquerdoRef = React.createRef();

    this.eixoDireitoRef = React.createRef();
    this.eixoEsquerdoRef = React.createRef();

    this.adicaoDireitoRef = React.createRef();
    this.adicaoEsquerdoRef = React.createRef();

    this.prismaHorizontalDireitoRef = React.createRef();
    this.prismaVerticalDireitoRef = React.createRef();
    this.prismaHorizontalEsquerdoRef = React.createRef();
    this.prismaVerticalEsquerdoRef = React.createRef();

    this.baseHorizontalDireitoRef = React.createRef();
    this.baseVerticalDireitoRef = React.createRef();
    this.baseHorizontalEsquerdoRef = React.createRef();
    this.baseVerticalEsquerdoRef = React.createRef();
  }

  componentDidMount() {
    this.handleClickNext(false);
    const { order } = this.props;

    if (order.json_parametros.iniciaisPaciente.preenchimento === "NAO_ACEITA") {
      this.props.handleOrderChange({ prescricao_ini_clientes: "" });
    }

    let newState = {};

    switch (order.json_parametros.receita.adicao.preenchimento) {
      case "NAO_ACEITA":
        break;
      case "OPCIONAL":
        newState["adicao"] = "OP";
        break;
      case "OBRIGATORIO":
        newState["adicao"] = "OB";
        break;
    }
    switch (order.json_parametros.receita.dnp.preenchimento) {
      case "NAO_ACEITA":
        // 201110 - Nathalia: gambiarra para corrigir erro da api e mostrar dnp sempre que retornar "nao_aceita", tirar isso depois
        newState["dnp"] = "OP";
        break;
      case "OPCIONAL":
        newState["dnp"] = "OP";
        break;
      case "OBRIGATORIO":
        newState["dnp"] = "OB";
        break;
    }
    switch (order.json_parametros.receita.altura.preenchimento) {
      case "NAO_ACEITA":
        break;
      case "OPCIONAL":
        newState["altura"] = "OP";
        break;
      case "OBRIGATORIO":
        newState["altura"] = "OB";
        break;
    }
    switch (order.json_parametros.receita.et?.preenchimento) {
      case "NAO_ACEITA":
        break;
      case "OPCIONAL":
        newState["et"] = "OP";
        break;
      case "OBRIGATORIO":
        newState["et"] = "OB";
        break;
    }
    switch (order.json_parametros.receita.ct?.preenchimento) {
      case "NAO_ACEITA":
        break;
      case "OPCIONAL":
        newState["ct"] = "OP";
        break;
      case "OBRIGATORIO":
        newState["ct"] = "OB";
        break;
    }
    switch (order.json_parametros.receita.base?.preenchimento) {
      case "NAO_ACEITA":
        break;
      case "OPCIONAL":
        newState["base"] = "OP";
        break;
      case "OBRIGATORIO":
        newState["base"] = "OB";
        break;
    }
    switch (order.json_parametros.dnpPerto.preenchimento) {
      case "NAO_ACEITA":
        this.props.handleOrderChange({ dnpPerto: false });
        break;
      case "OPCIONAL":
        newState["dnpPerto"] = "OP";
        this.props.handleOrderChange({ dnpPerto: true });
        break;
      case "OBRIGATORIO":
        newState["dnpPerto"] = "OB";
        this.props.handleOrderChange({ dnpPerto: true });
        break;
    }
    switch (order.json_parametros.iniciaisPaciente.preenchimento) {
      case "NAO_ACEITA":
        break;
      case "OPCIONAL":
        newState["iniciais"] = "OP";
        break;
      case "OBRIGATORIO":
        newState["iniciais"] = "OB";
        break;
    }
    switch (order.json_parametros.distanciaLeitura.preenchimento) {
      case "NAO_ACEITA":
        break;
      case "OPCIONAL":
        newState["distLeitura"] = "OP";
        break;
      case "OBRIGATORIO":
        newState["distLeitura"] = "OB";
        break;
    }
    switch (order.json_parametros.cvp.preenchimento) {
      case "NAO_ACEITA":
        break;
      case "OPCIONAL":
        newState["cvp"] = "OP";
        break;
      case "OBRIGATORIO":
        newState["cvp"] = "OB";
        break;
    }
    switch (order.json_parametros.cro.preenchimento) {
      case "NAO_ACEITA":
        break;
      case "OPCIONAL":
        newState["cro"] = "OP";
        break;
      case "OBRIGATORIO":
        newState["cro"] = "OB";
        break;
    }
    switch (order.json_parametros.vmap.preenchimento) {
      case "NAO_ACEITA":
        break;
      case "OPCIONAL":
        newState["wmap"] = "OP";
        break;
      case "OBRIGATORIO":
        newState["wmap"] = "OB";
        break;
    }
    switch (order.json_parametros.anguloPantoscopico.preenchimento) {
      case "NAO_ACEITA":
        break;
      case "OPCIONAL":
        newState["angPanto"] = "OP";
        break;
      case "OBRIGATORIO":
        newState["angPanto"] = "OB";
        break;
    }
    switch (order.json_parametros.anguloCurvatura.preenchimento) {
      case "NAO_ACEITA":
        break;
      case "OPCIONAL":
        newState["curvArma"] = "OP";
        break;
      case "OBRIGATORIO":
        newState["curvArma"] = "OB";
        break;
    }
    switch (order.json_parametros.coeficienteHE.preenchimento) {
      case "NAO_ACEITA":
        break;
      case "OPCIONAL":
        newState["coefHE"] = "OP";
        break;
      case "OBRIGATORIO":
        newState["coefHE"] = "OB";
        break;
    }
    switch (order.json_parametros.coeficienteST.preenchimento) {
      case "NAO_ACEITA":
        break;
      case "OPCIONAL":
        newState["coefST"] = "OP";
        break;
      case "OBRIGATORIO":
        newState["coefST"] = "OB";
        break;
    }
    switch (order.json_parametros.distanciaProgressao.preenchimento) {
      case "NAO_ACEITA":
        break;
      case "OPCIONAL":
        newState["distProg"] = "OP";
        break;
      case "OBRIGATORIO":
        newState["distProg"] = "OB";
        break;
    }
    switch (order.json_parametros.distanciaVertice.preenchimento) {
      case "NAO_ACEITA":
        break;
      case "OPCIONAL":
        newState["distVert"] = "OP";
        break;
      case "OBRIGATORIO":
        newState["distVert"] = "OB";
        break;
    }
    switch (order.json_parametros.olhoDominante.preenchimento) {
      case "NAO_ACEITA":
        break;
      case "OPCIONAL":
        newState["olhoDom"] = "OP";
        break;
      case "OBRIGATORIO":
        newState["olhoDom"] = "OB";
        break;
    }
    this.setState(newState);
  }

  checkDecimal = value => {
    if (value.toString().split(".")[1] !== undefined) {
      return value.toString().split(".")[1].length;
    } else if (value.toString().split(",")[1] !== undefined) {
      return value.toString().split(".")[1].length;
    } else {
      return 0;
    }
  };

  handleClickNext = (next = true) => {
    var mensagem = "";
    const { order } = this.props;

    var selectFields = document.getElementsByTagName("SELECT");
    for (var item in selectFields) {
      if (selectFields[item].required && selectFields[item].value === "") {
        mensagem = "Campos obrigatórios pendentes!<br/>";
        if (next) selectFields[item].style.boxShadow = "0 0 0 1px #f70017";
      }
    }

    var inputFields = document.getElementsByTagName("INPUT");
    for (var item in inputFields) {
      if (inputFields[item].required && inputFields[item].value === "") {
        mensagem = "Campos obrigatórios pendentes!<br/>";
        if (next) inputFields[item].style.boxShadow = "0 0 0 1px #f70017";
      }
    }

    var esferico_esquerdo = document.getElementById("prescricao_olho_esquerdo_esferico");
    var esferico_direito = document.getElementById("prescricao_olho_direito_esferico");
    var cilindrico_esquerdo = document.getElementById("prescricao_olho_esquerdo_cilindrico");
    var cilindrico_direito = document.getElementById("prescricao_olho_direito_cilindrico");
    var eixo_esquerdo = document.getElementById("prescricao_olho_esquerdo_eixo");
    var eixo_direito = document.getElementById("prescricao_olho_direito_eixo");
    var prisma_direito_h = document.getElementById("prescricao_olho_direito_pris_h");
    var base_direito_h = document.getElementById("prescricao_olho_direito_base_h");
    var prisma_esquerdo_h = document.getElementById("prescricao_olho_esquerdo_pris_h");
    var base_esquerdo_h = document.getElementById("prescricao_olho_esquerdo_base_h");
    var prisma_direito_v = document.getElementById("prescricao_olho_direito_pris_v");
    var base_direito_v = document.getElementById("prescricao_olho_direito_base_v");
    var prisma_esquerdo_v = document.getElementById("prescricao_olho_esquerdo_pris_v");
    var base_esquerdo_v = document.getElementById("prescricao_olho_esquerdo_base_v");
    var adicao_direito_v = document.getElementById("prescricao_olho_direito_adicao");
    var adicao_esquerdo_v = document.getElementById("prescricao_olho_esquerdo_adicao");

    if (order.prescricao_olhos === "ambos" || order.prescricao_olhos === "direito") {
      //Prisma Horizontal
      if (order.prescricao_olho_direito_base_h !== "" && order.prescricao_olho_direito_base_h !== undefined) {
        if (order.prescricao_olho_direito_pris_h === undefined || order.prescricao_olho_direito_pris_h === "") {
          mensagem = "Campos obrigatórios pendentes!<br/>";
          if (next) {
            prisma_direito_h.style.boxShadow = "0 0 0 1px #f70017";
            prisma_direito_h.style.borderRadius = "4px";
          }
        }
      }

      if (order.prescricao_olho_direito_pris_h !== "" && order.prescricao_olho_direito_pris_h !== undefined) {
        if (order.prescricao_olho_direito_base_h === undefined || order.prescricao_olho_direito_base_h === "") {
          mensagem = "Campos obrigatórios pendentes!<br/>";
          if (next) {
            base_direito_h.style.boxShadow = "0 0 0 1px #f70017";
            base_direito_h.style.borderRadius = "4px";
          }
        }
      }

      // Prisma Vertical
      if (order.prescricao_olho_direito_base_v !== "" && order.prescricao_olho_direito_base_v !== undefined) {
        if (order.prescricao_olho_direito_pris_v === undefined || order.prescricao_olho_direito_pris_v === "") {
          mensagem = "Campos obrigatórios pendentes!<br/>";
          if (next) {
            prisma_direito_v.style.boxShadow = "0 0 0 1px #f70017";
            prisma_direito_v.style.borderRadius = "4px";
          }
        }
      }

      if (order.prescricao_olho_direito_pris_v !== "" && order.prescricao_olho_direito_pris_v !== undefined) {
        if (order.prescricao_olho_direito_base_v === undefined || order.prescricao_olho_direito_base_v === "") {
          mensagem = "Campos obrigatórios pendentes!<br/>";
          if (next) {
            base_direito_v.style.boxShadow = "0 0 0 1px #f70017";
            base_direito_v.style.borderRadius = "4px";
          }
        }
      }
    }

    if (order.prescricao_olhos === "ambos" || order.prescricao_olhos === "esquerdo") {
      //Prisma Horizontal
      if (order.prescricao_olho_esquerdo_pris_h !== "" && order.prescricao_olho_esquerdo_pris_h !== undefined) {
        if (order.prescricao_olho_esquerdo_base_h === undefined || order.prescricao_olho_esquerdo_base_h === "") {
          mensagem = "Campos obrigatórios pendentes!<br/>";
          if (next) {
            base_esquerdo_h.style.boxShadow = "0 0 0 1px #f70017";
            base_esquerdo_h.style.borderRadius = "4px";
          }
        }
      }

      if (order.prescricao_olho_esquerdo_base_h !== "" && order.prescricao_olho_esquerdo_base_h !== undefined) {
        if (order.prescricao_olho_esquerdo_pris_h === undefined || order.prescricao_olho_esquerdo_pris_h === "") {
          mensagem = "Campos obrigatórios pendentes!<br/>";
          if (next) {
            prisma_esquerdo_h.style.boxShadow = "0 0 0 1px #f70017";
            prisma_esquerdo_h.style.borderRadius = "4px";
          }
        }
      }

      //Prisma Vertical
      if (order.prescricao_olho_esquerdo_pris_v !== "" && order.prescricao_olho_esquerdo_pris_v !== undefined) {
        if (order.prescricao_olho_esquerdo_base_v === undefined || order.prescricao_olho_esquerdo_base_v === "") {
          mensagem = "Campos obrigatórios pendentes!<br/>";
          if (next) {
            base_esquerdo_v.style.boxShadow = "0 0 0 1px #f70017";
            base_esquerdo_v.style.borderRadius = "4px";
          }
        }
      }

      if (order.prescricao_olho_esquerdo_base_v !== "" && order.prescricao_olho_esquerdo_base_v !== undefined) {
        if (order.prescricao_olho_esquerdo_pris_v === undefined || order.prescricao_olho_esquerdo_pris_v === "") {
          mensagem = "Campos obrigatórios pendentes!<br/>";
          if (next) {
            prisma_esquerdo_v.style.boxShadow = "0 0 0 1px #f70017";
            prisma_esquerdo_v.style.borderRadius = "4px";
          }
        }
      }
    }

    if (order.prescricao_olho_esquerdo_adicao === undefined && order.json_parametros.receita.adicao.preenchimento === "OBRIGATORIO") {
      mensagem = "Campos obrigatórios pendentes!<br/>";
      if (next) {
        adicao_esquerdo_v.style.boxShadow = "rgb(247, 0, 23) 0px 0px 0px 1px";
        adicao_esquerdo_v.style.borderRadius = "4px";
      }
    }
    if (order.prescricao_olho_direito_adicao === undefined && order.json_parametros.receita.adicao.preenchimento === "OBRIGATORIO") {
      mensagem = "Campos obrigatórios pendentes!<br/>";
      if (next) {
        adicao_direito_v.style.boxShadow = "rgb(247, 0, 23) 0px 0px 0px 1px";
        adicao_direito_v.style.borderRadius = "4px";
      }
    }

    if (order.prescricao_olhos === "esquerdo" && !order.prescricao_olho_esquerdo_esferico) {
      mensagem = "Campos obrigatórios pendentes!<br/>";
      if (next) {
        esferico_esquerdo.style.boxShadow = "0 0 0 1px #f70017";
        esferico_esquerdo.style.borderRadius = "4px";
      }
    }
    if (order.prescricao_olhos === "direito" && !order.prescricao_olho_direito_esferico) {
      mensagem = "Campos obrigatórios pendentes!<br/>";
      if (next) {
        esferico_direito.style.boxShadow = "0 0 0 1px #f70017";
        esferico_direito.style.borderRadius = "4px";
      }
    }
    if (order.prescricao_olhos === "ambos" && !order.prescricao_olho_esquerdo_esferico) {
      mensagem = "Campos obrigatórios pendentes!<br/>";
      if (next) {
        esferico_esquerdo.style.boxShadow = "0 0 0 1px #f70017";
        esferico_esquerdo.style.borderRadius = "4px";
      }
    }
    if (order.prescricao_olhos === "ambos" && !order.prescricao_olho_direito_esferico) {
      mensagem = "Campos obrigatórios pendentes!<br/>";
      if (next) {
        esferico_direito.style.boxShadow = "0 0 0 1px #f70017";
        esferico_direito.style.borderRadius = "4px";
      }
    }
    if (order.prescricao_olho_esquerdo_cilindrico && !order.prescricao_olho_esquerdo_eixo) {
      mensagem = "Campos obrigatórios pendentes!<br/>";
      if (next) {
        eixo_esquerdo.style.boxShadow = "0 0 0 1px #f70017";
        eixo_esquerdo.style.borderRadius = "4px";
      }
    }
    if (order.prescricao_olho_direito_cilindrico && !order.prescricao_olho_direito_eixo) {
      mensagem = "Campos obrigatórios pendentes!<br/>";
      if (next) {
        eixo_direito.style.boxShadow = "0 0 0 1px #f70017";
        eixo_direito.style.borderRadius = "4px";
      }
    }
    if (!order.prescricao_olho_esquerdo_cilindrico && order.prescricao_olho_esquerdo_eixo) {
      mensagem = "Campos obrigatórios pendentes!<br/>";
      if (next) {
        cilindrico_esquerdo.style.boxShadow = "0 0 0 1px #f70017";
        cilindrico_esquerdo.style.borderRadius = "4px";
      }
    }
    if (!order.prescricao_olho_direito_cilindrico && order.prescricao_olho_direito_eixo) {
      mensagem = "Campos obrigatórios pendentes!<br/>";
      if (next) {
        cilindrico_direito.style.boxShadow = "0 0 0 1px #f70017";
        cilindrico_direito.style.borderRadius = "4px";
      }
    }

    var dnppertodir = document.getElementById("prescricao_olho_direito_dnp_perto");
    if (dnppertodir) {
      if ((dnppertodir.value > dnppertodir.max || dnppertodir.value < dnppertodir.min) && dnppertodir.value !== "") {
        mensagem = "O valor de um ou mais campos é inválido.<br/>";
        if (next) dnppertodir.style.boxShadow = "0 0 0 1px #f70017";
      } else if (this.checkDecimal(dnppertodir.value) > 1) {
        mensagem = "O valor de um ou mais campos é inválido.<br/>";
        if (next) dnppertodir.style.boxShadow = "0 0 0 1px #f70017";
      }
    }

    var dnppertoesq = document.getElementById("prescricao_olho_esquerdo_dnp_perto");
    if (dnppertoesq) {
      if ((dnppertoesq.value > dnppertoesq.max || dnppertoesq.value < dnppertoesq.min) && dnppertoesq.value !== "") {
        mensagem = "O valor de um ou mais campos é inválido.<br/>";
        if (next) dnppertoesq.style.boxShadow = "0 0 0 1px #f70017";
      } else if (this.checkDecimal(dnppertoesq.value) > 1) {
        mensagem = "O valor de um ou mais campos é inválido.<br/>";
        if (next) dnppertoesq.style.boxShadow = "0 0 0 1px #f70017";
      }
    }

    var dnpdir = document.getElementById("prescricao_olho_direito_dnp");
    if (dnpdir) {
      if ((dnpdir.value > dnpdir.max || dnpdir.value < dnpdir.min) && dnpdir.value !== "") {
        mensagem = "O valor de um ou mais campos é inválido.<br/>";
        if (next) dnpdir.style.boxShadow = "0 0 0 1px #f70017";
      } else if (this.checkDecimal(dnpdir.value) > 1) {
        mensagem = "O valor de um ou mais campos é inválido.<br/>";
        if (next) dnpdir.style.boxShadow = "0 0 0 1px #f70017";
      }
    }

    var dnpesq = document.getElementById("prescricao_olho_esquerdo_dnp");
    if (dnpesq) {
      if ((dnpesq.value > dnpesq.max || dnpesq.value < dnpesq.min) && dnpesq.value !== "") {
        mensagem = "O valor de um ou mais campos é inválido.<br/>";
        if (next) dnpesq.style.boxShadow = "0 0 0 1px #f70017";
      } else if (this.checkDecimal(dnpesq.value) > 1) {
        mensagem = "O valor de um ou mais campos é inválido.<br/>";
        if (next) dnpesq.style.boxShadow = "0 0 0 1px #f70017";
      }
    }

    var altdir = document.getElementById("prescricao_olho_direito_altura");
    if (altdir) {
      if ((altdir.value > altdir.max || altdir.value < altdir.min) && altdir.value !== "") {
        mensagem = "O valor de um ou mais campos é inválido.<br/>";
        if (next) altdir.style.boxShadow = "0 0 0 1px #f70017";
      } else if (this.checkDecimal(altdir.value) > 1) {
        mensagem = "O valor de um ou mais campos é inválido.<br/>";
        if (next) altdir.style.boxShadow = "0 0 0 1px #f70017";
      } else if (altdir.value % 1 !== 0) {
        if (altdir.value.toString().split(".")[1] !== "5") {
          mensagem = "O valor de um ou mais campos é inválido.<br/>";
          if (next) altdir.style.boxShadow = "0 0 0 1px #f70017";
        }
      }
    }

    var altesq = document.getElementById("prescricao_olho_esquerdo_altura");
    if (altesq) {
      if ((altesq.value > altesq.max || altesq.value < altesq.min) && altesq.value !== "") {
        mensagem = "O valor de um ou mais campos é inválido.<br/>";
        if (next) altesq.style.boxShadow = "0 0 0 1px #f70017";
      } else if (this.checkDecimal(altesq.value) > 1) {
        mensagem = "O valor de um ou mais campos é inválido.<br/>";
        if (next) altesq.style.boxShadow = "0 0 0 1px #f70017";
      } else if (altesq.value % 1 !== 0) {
        if (altesq.value.toString().split(".")[1] !== "5") {
          mensagem = "O valor de um ou mais campos é inválido.<br/>";
          if (next) altesq.style.boxShadow = "0 0 0 1px #f70017";
        }
      }
    }

    if (mensagem !== "") {
      if (next) {
        Alert.fire({
          html: mensagem,
          icon: "warning",
          title: "Aviso",
          confirmButtonColor: "#00518b",
          customClass: {
            confirmButton: "x-follow-order__filter-btn",
          },
        });
      }
      this.props.handleOrderChange({ prescricao_done: false });
    } else {
      this.props.handleOrderChange({ prescricao_done: true });
      if (next) {
        this.handleNext();
      }
    }
  };

  handleNext = async () => {
    const a = await this.finalOrder();
    const b = await this.finalNext(a);
    return b;
  };

  finalOrder = () => {
    this.props.handleOrderChange({ prescricao_done: true });
    return "right";
  };

  finalNext = a => {
    this.props.onNext(4, a);
    return true;
  };

  handleClickPrev = () => {
    this.props.onNext(2, "left");
  };

  handleBlur = e => {
    let { value, min, max } = e.target;
    if (Number(value) < Number(min) || Number(value) > Number(max)) {
      e.target.value = "";
      this.props.handleOrderChange({ [e.target.name]: undefined });
      if (e.target.name === "prescricao_olho_direito_altura") {
        this.props.handleOrderChange({
          prescricao_olho_esquerdo_altura: undefined,
        });
      }
    }
  };

  handleChange = evt => {
    this.handleClickNext(false);
    document.getElementById(evt.target.id).style.boxShadow = "0 0 0 1px #B5B5B5";
    let { value } = evt.target;

    // if (min !== '' && max !== '') {
    //   value = Math.max(Number(min), Math.min(Number(max), Number(value)))
    // }

    if (
      evt.target.name === "prescricao_dis_leitura" ||
      evt.target.name === "prescricao_wmap" ||
      evt.target.name === "prescricao_ang_pantoscopico" ||
      evt.target.name === "prescricao_curva_arm" ||
      evt.target.name === "prescricao_coeficiente" ||
      evt.target.name === "prescricao_estabilidade" ||
      evt.target.name === "prescricao_dis_prog"
    ) {
      if (evt.target.value < 0 || isNaN([evt.target.value])) {
        evt.target.value = null;
        this.props.handleOrderChange({
          [evt.target.value]: "",
        });
      } else {
        this.props.handleOrderChange({ [evt.target.name]: value });
      }
    } else if (evt.target.name === "prescricao_olho_direito_altura" && [evt.target.value] !== this.props.order.prescricao_olho_esquerdo_altura) {
      if (this.props.order.prescricao_olhos === "ambos") {
        this.props.handleOrderChange({
          prescricao_olho_esquerdo_altura: value,
          prescricao_olho_direito_altura: value,
        });
        document.getElementById("prescricao_olho_esquerdo_altura").value = evt.target.value;
      } else {
        this.props.handleOrderChange({ [evt.target.name]: value });
      }
    } else if (evt.target.name === "prescricao_dis_vert_direito" && [evt.target.value] !== this.props.order.prescricao_dis_vert_esquerdo) {
      if (this.props.order.prescricao_olhos === "ambos") {
        this.props.handleOrderChange({
          prescricao_dis_vert_esquerdo: value,
          prescricao_dis_vert_direito: value,
        });
        document.getElementById("prescricao_dis_vert_esquerdo").value = evt.target.value;
      } else {
        this.props.handleOrderChange({ [evt.target.name]: value });
      }
    } else if (evt.target.name === "prescricao_cro_direito" && [evt.target.value] !== this.props.order.prescricao_cro_esquerdo) {
      if (this.props.order.prescricao_olhos === "ambos") {
        this.props.handleOrderChange({
          prescricao_cro_esquerdo: value,
          prescricao_cro_direito: value,
        });
        document.getElementById("prescricao_cro_esquerdo").value = evt.target.value;
      } else {
        this.props.handleOrderChange({ [evt.target.name]: value });
      }
    } else if (evt.target.name === "prescricao_cvp") {
      const onlyDot = evt.target.value;
      this.props.handleOrderChange({ [evt.target.name]: onlyDot.replaceAll(",", ".") });
    } else if (evt.target.name === "prescricao_ini_clientes") {
      const valueEvt = evt.target.value
        .replace(/\s/g, "") // remove spaces
        .replace(/[0-9]/g, ""); // remove numbers

      this.props.handleOrderChange({ [evt.target.name]: valueEvt });
    } else {
      this.props.handleOrderChange({ [evt.target.name]: value });
    }
  };

  handleSelectChange = (evt, refNext) => {
    const { order } = this.props;
    this.handleClickNext(false);
    document.getElementById(evt.target.id).style.boxShadow = "0 0 0 1px #B5B5B5";

    this.props.handleOrderChange({
      [evt.target.name]: evt.target.value,
      [evt.target.name + "_value"]: evt.target.options[evt.target.selectedIndex].text,
    });

    if (order.prescricao_olhos !== "direito") {
      if (evt.target.name === "prescricao_olho_direito_adicao") {
        this.props.handleOrderChange({
          prescricao_olho_esquerdo_adicao: evt.target.value,
          prescricao_olho_esquerdo_adicao_value: evt.target.options[evt.target.selectedIndex].text,
        });
      }
    }

    if (refNext?.current) {
      refNext.current.selectAuto.current.focus();
    }
  };

  handleTogglerPussiPrisma = evt => {
    this.handleClickNext(false);
    this.props.handleOrderChange({
      [evt.target.name]: evt.target.value,
      [evt.target.name + "_value"]: document.querySelector("label[for=" + evt.target.id + "]").textContent,
    });
    if (this.props.order.prescricao_prisma === "S") {
      this.props.order.limparPrisma();
    }
  };

  handleToggler = evt => {
    this.handleClickNext(false);
    if (evt.target.value === "direito") {
      this.limparDadosOlhoEsquerdo();
    } else if (evt.target.value === "esquerdo") {
      this.limparDadosOlhoDireito();
    }

    this.props.handleOrderChange({
      [evt.target.name]: evt.target.value,
      [evt.target.name + "_value"]: document.querySelector("label[for=" + evt.target.id + "]").textContent,
    });
  };

  limparDadosOlhoDireito = () => {
    this.props.handleOrderChange({
      prescricao_olho_direito_esferico: "",
      prescricao_olho_direito_cilindrico: "",
      prescricao_olho_direito_eixo: "",
      prescricao_olho_direito_adicao: "",
      prescricao_olho_direito_dnp: "",
      prescricao_olho_direito_altura: "",
      prescricao_olho_direito_dnp_perto: "",
    });

    this.props.order.limparPrismaDireito();
  };

  limparDadosOlhoEsquerdo = () => {
    this.props.handleOrderChange({
      prescricao_olho_esquerdo_esferico: "",
      prescricao_olho_esquerdo_cilindrico: "",
      prescricao_olho_esquerdo_eixo: "",
      prescricao_olho_esquerdo_adicao: "",
      prescricao_olho_esquerdo_dnp: "",
      prescricao_olho_esquerdo_altura: "",
      prescricao_olho_esquerdo_dnp_perto: "",
    });

    this.props.order.limparPrismaEsquerdo();
  };

  handleChangePrismaHorizontalDireito = selectedOption => {
    if (selectedOption) {
      this.setState({ prescricao_olho_direito_pris_h: selectedOption });
      this.props.handleOrderChange({ prescricao_olho_direito_pris_h: selectedOption.value });
      document.getElementById("prescricao_olho_direito_pris_h").style.boxShadow = "0 0 0 0px transparent";
    } else {
      this.setState({ prescricao_olho_direito_pris_h: "" });
      this.props.handleOrderChange({ prescricao_olho_direito_pris_h: "" });
    }

    if (this.baseHorizontalDireitoRef.current) {
      this.baseHorizontalDireitoRef.current.selectRef.current.focus();
    }
  };

  handleChangePrismaHorizontalEsquerdo = selectedOption => {
    if (selectedOption) {
      this.setState({ prescricao_olho_esquerdo_pris_h: selectedOption });
      this.props.handleOrderChange({ prescricao_olho_esquerdo_pris_h: selectedOption.value });
      document.getElementById("prescricao_olho_esquerdo_pris_h").style.boxShadow = "0 0 0 0px transparent";
    } else {
      this.setState({ prescricao_olho_esquerdo_pris_h: "" });
      this.props.handleOrderChange({ prescricao_olho_esquerdo_pris_h: "" });
    }

    if (this.baseHorizontalEsquerdoRef.current) {
      this.baseHorizontalEsquerdoRef.current.selectRef.current.focus();
    }
  };

  handleChangePrismaVesticalDireito = selectedOption => {
    if (selectedOption) {
      this.setState({ prescricao_olho_direito_pris_v: selectedOption });
      this.props.handleOrderChange({ prescricao_olho_direito_pris_v: selectedOption.value });
      document.getElementById("prescricao_olho_direito_pris_v").style.boxShadow = "0 0 0 0px transparent";
    } else {
      this.setState({ prescricao_olho_direito_pris_v: "" });
      this.props.handleOrderChange({ prescricao_olho_direito_pris_v: "" });
    }

    if (this.baseVerticalDireitoRef.current) {
      this.baseVerticalDireitoRef.current.selectRef.current.focus();
    }
  };

  handleChangePrismaVesticalEsquerdo = selectedOption => {
    if (selectedOption) {
      this.setState({ prescricao_olho_esquerdo_pris_v: selectedOption });
      this.props.handleOrderChange({ prescricao_olho_esquerdo_pris_v: selectedOption.value });
      document.getElementById("prescricao_olho_esquerdo_pris_v").style.boxShadow = "0 0 0 0px transparent";
    } else {
      this.setState({ prescricao_olho_esquerdo_pris_v: "" });
      this.props.handleOrderChange({ prescricao_olho_esquerdo_pris_v: "" });
    }

    if (this.baseVerticalEsquerdoRef.current) {
      this.baseVerticalEsquerdoRef.current.selectRef.current.focus();
    }
  };

  handleChangeEsfericoDireito = (selectedOption, idNext) => {
    if (selectedOption) {
      this.setState({ prescricao_olho_direito_esferico: selectedOption });
      this.props.handleOrderChange({ prescricao_olho_direito_esferico: selectedOption.value });
      document.getElementById("prescricao_olho_direito_esferico").style.boxShadow = "0 0 0 0px transparent";
      idNext.current.selectAuto.current.select.focus();
    } else {
      this.setState({ prescricao_olho_direito_esferico: "" });
      this.props.handleOrderChange({ prescricao_olho_direito_esferico: "" });
    }
  };

  handleChangeCilindricoDireito = selectedOption => {
    if (selectedOption) {
      this.setState({ prescricao_olho_direito_cilindrico: selectedOption });
      this.props.handleOrderChange({ prescricao_olho_direito_cilindrico: selectedOption.value });
      document.getElementById("prescricao_olho_direito_cilindrico").style.boxShadow = "0 0 0 0px transparent";
      this.eixoDireitoRef.current.selectAuto.current.select.focus();
    } else {
      this.setState({ prescricao_olho_direito_cilindrico: "" });
      this.props.handleOrderChange({ prescricao_olho_direito_cilindrico: "" });
    }
  };

  handleChangeEixoDireito = selectedOption => {
    if (selectedOption) {
      this.setState({ prescricao_olho_direito_eixo: selectedOption });
      this.props.handleOrderChange({ prescricao_olho_direito_eixo: selectedOption.value });
      document.getElementById("prescricao_olho_direito_eixo").style.boxShadow = "0 0 0 0px transparent";

      if (this.adicaoDireitoRef.current) {
        this.adicaoDireitoRef.current.selectAuto.current.focus();
      } else if (this.esfericoEsquerdoRef.current) {
        this.esfericoEsquerdoRef.current.selectAuto.current.select.focus();
      }
    } else {
      this.setState({ prescricao_olho_direito_eixo: "" });
      this.props.handleOrderChange({ prescricao_olho_direito_eixo: "" });
    }
  };

  handleChangeEsfericoEsquerdo = selectedOption => {
    if (selectedOption) {
      this.setState({ prescricao_olho_esquerdo_esferico: selectedOption });
      this.props.handleOrderChange({ prescricao_olho_esquerdo_esferico: selectedOption.value });
      document.getElementById("prescricao_olho_esquerdo_esferico").style.boxShadow = "0 0 0 0px transparent";
      this.cilindricoEsquerdoRef.current.selectAuto.current.select.focus();
    } else {
      this.setState({ prescricao_olho_esquerdo_esferico: "" });
      this.props.handleOrderChange({ prescricao_olho_esquerdo_esferico: "" });
    }
  };

  handleChangeCilindricoEsquerdo = selectedOption => {
    if (selectedOption) {
      this.setState({ prescricao_olho_esquerdo_cilindrico: selectedOption });
      this.props.handleOrderChange({ prescricao_olho_esquerdo_cilindrico: selectedOption.value });
      document.getElementById("prescricao_olho_esquerdo_cilindrico").style.boxShadow = "0 0 0 0px transparent";
      this.eixoEsquerdoRef.current.selectAuto.current.select.focus();
    } else {
      this.setState({ prescricao_olho_esquerdo_cilindrico: "" });
      this.props.handleOrderChange({ prescricao_olho_esquerdo_cilindrico: "" });
    }
  };

  handleChangeEixoEsquerdo = selectedOption => {
    if (selectedOption) {
      this.setState({ prescricao_olho_esquerdo_eixo: selectedOption });
      this.props.handleOrderChange({ prescricao_olho_esquerdo_eixo: selectedOption.value });
      document.getElementById("prescricao_olho_esquerdo_eixo").style.boxShadow = "0 0 0 0px transparent";
      if (this.adicaoEsquerdoRef.current) {
        this.adicaoEsquerdoRef.current.selectAuto.current.focus();
      }
    } else {
      this.setState({ prescricao_olho_esquerdo_eixo: "" });
      this.props.handleOrderChange({ prescricao_olho_esquerdo_eixo: "" });
    }
  };

  handleChangeAdicaoDireito = selectedOption => {
    const { order } = this.props;

    this.handleClickNext(false);
    document.getElementById("prescricao_olho_direito_adicao").style.boxShadow = "0 0 0 1px #B5B5B5";

    this.props.handleOrderChange({
      prescricao_olho_direito_adicao: selectedOption.value,
      prescricao_olho_direito_adicao_value: selectedOption.value,
    });

    if (order.prescricao_olhos === "ambos") {
      document.getElementById("prescricao_olho_esquerdo_adicao").style.boxShadow = "0 0 0 1px #B5B5B5";

      this.props.handleOrderChange({
        prescricao_olho_esquerdo_adicao: selectedOption.value,
        prescricao_olho_esquerdo_adicao_value: selectedOption.value,
      });
    }

    if (this.esfericoEsquerdoRef.current) {
      this.esfericoEsquerdoRef.current.selectAuto.current.focus();
    }
  };

  handleChangeAdicaoEsquerdo = selectedOption => {
    const { order } = this.props;
    this.handleClickNext(false);
    document.getElementById("prescricao_olho_esquerdo_adicao").style.boxShadow = "0 0 0 1px #B5B5B5";

    this.props.handleOrderChange({
      prescricao_olho_esquerdo_adicao: selectedOption.value,
      prescricao_olho_esquerdo_adicao_value: selectedOption.value,
    });
    if (order.prescricao_olhos === "ambos") {
      document.getElementById("prescricao_olho_esquerdo_adicao").style.boxShadow = "0 0 0 1px #B5B5B5";

      this.props.handleOrderChange({
        prescricao_olho_direito_adicao: selectedOption.value,
        prescricao_olho_direito_adicao_value: selectedOption.value,
      });
    }
    if (this.prismaHorizontalDireitoRef.current) {
      this.prismaHorizontalDireitoRef.current.selectAuto.current.focus();
    }
  };

  render() {
    const { order } = this.props;
    var adicaoOlhoDireito = null;
    var adicaoOlhoEsquerdo = null;

    if (order.prescricao_olhos === "ambos" && order.lente_tp_produto_value !== "Visão Simples") {
      if (this.state.adicao === "OP") {
        adicaoOlhoDireito = (
          <SelectAuto
            ref={this.adicaoDireitoRef}
            className="font-order"
            label="Adição"
            width="25%"
            name="prescricao_olho_direito_adicao"
            aria="Selecione a opção da adição"
            options={adicao}
            defaultValue={
              adicao[
                adicao
                  .map(function (e) {
                    return e.value;
                  })
                  .indexOf(order.prescricao_olho_direito_adicao)
              ]
            }
            onChange={this.handleChangeAdicaoDireito}
            disabled={order.rpl ? true : false}
            placeholder="Selecione"
            onlyDot
          />
        );

        adicaoOlhoEsquerdo = (
          <SelectAuto
            ref={this.adicaoEsquerdoRef}
            className="font-order"
            label="Adição"
            width="25%"
            name="prescricao_olho_esquerdo_adicao"
            aria="Selecione a opção da adição"
            options={adicao}
            defaultValue={
              adicao[
                adicao
                  .map(function (e) {
                    return e.value;
                  })
                  .indexOf(order.prescricao_olho_esquerdo_adicao)
              ]
            }
			value={adicao[adicao.map((e) => e.value).indexOf(order.prescricao_olho_esquerdo_adicao)]}
            onChange={this.handleChangeAdicaoEsquerdo}
            disabled={order.rpl ? true : false}
            placeholder="Selecione"
            onlyDot
          />
        );
      } else if (this.state.adicao === "OB") {
        adicaoOlhoDireito = (
          <SelectAuto
            ref={this.adicaoDireitoRef}
            className="font-order"
            label="Adição*"
            width="25%"
            name="prescricao_olho_direito_adicao"
            id="prescricao_olho_direito_adicao"
            aria="Selecione a opção da adição"
            options={adicao}
            // itens={adicao}
            defaultValue={
              adicao[
                adicao
                  .map(function (e) {
                    return e.value;
                  })
                  .indexOf(order.prescricao_olho_direito_adicao)
              ]
            }
            // value={order.prescricao_olho_direito_adicao}
            onChange={this.handleChangeAdicaoDireito}
            // onChange={this.handleSelectChange}
            required
            disabled={order.rpl ? true : false}
            placeholder="Selecione"
            onlyDot
          />
        );

        adicaoOlhoEsquerdo = (
          <SelectAuto
            ref={this.adicaoEsquerdoRef}
            className="font-order"
            label="Adição*"
            width="25%"
            name="prescricao_olho_esquerdo_adicao"
            aria="Selecione a opção da adição"
            id="prescricao_olho_esquerdo_adicao"
            stylearrow="is--arrow-2 is--mobile-left"
            options={adicao}
            defaultValue={
              adicao[
                adicao
                  .map(function (e) {
                    return e.value;
                  })
                  .indexOf(order.prescricao_olho_esquerdo_adicao)
              ]
            }
			value={adicao[adicao.map((e) => e.value).indexOf(order.prescricao_olho_esquerdo_adicao)]}
            onChange={this.handleChangeAdicaoEsquerdo}
            // itens={adicao}
            // value={order.prescricao_olho_esquerdo_adicao}
            // onChange={this.handleSelectChange}
            required
            disabled={order.rpl ? true : false}
            placeholder="Selecione"
            onlyDot
          />
        );
      }
    } else if (order.prescricao_olhos === "direito" && order.lente_tp_produto_value !== "Visão Simples") {
      if (this.state.adicao === "OP") {
        adicaoOlhoDireito = (
          <SelectAuto
            ref={this.adicaoDireitoRef}
            className="font-order"
            label="Adição"
            width="25%"
            name="prescricao_olho_direito_adicao"
            aria="Selecione a opção da adição"
            options={adicao}
            defaultValue={
              adicao[
                adicao
                  .map(function (e) {
                    return e.value;
                  })
                  .indexOf(order.prescricao_olho_direito_adicao)
              ]
            }
            onChange={this.handleChangeAdicaoDireito}
            disabled={order.rpl ? true : false}
            placeholder="Selecione"
            onlyDot
          />
        );

        adicaoOlhoEsquerdo = null;
      } else if (this.state.adicao === "OB") {
        adicaoOlhoDireito = (
          <SelectAuto
            ref={this.adicaoDireitoRef}
            className="font-order"
            label="Adição*"
            width="25%"
            name="prescricao_olho_direito_adicao"
            aria="Selecione a opção da adição"
            // itens={adicao}
            options={adicao}
            defaultValue={
              adicao[
                adicao
                  .map(function (e) {
                    return e.value;
                  })
                  .indexOf(order.prescricao_olho_direito_adicao)
              ]
            }
            // value={order.prescricao_olho_direito_adicao}
            // onChange={this.handleSelectChange}
            onChange={this.handleChangeAdicaoDireito}
            required
            disabled={order.rpl ? true : false}
            placeholder="Selecione"
            onlyDot
          />
        );

        adicaoOlhoEsquerdo = null;
      }
    } else if (order.prescricao_olhos === "esquerdo" && order.lente_tp_produto_value !== "Visão Simples") {
      if (this.state.adicao === "OP") {
        adicaoOlhoDireito = null;

        adicaoOlhoEsquerdo = (
          <SelectAuto
            ref={this.adicaoEsquerdoRef}
            className="font-order"
            label="Adição"
            width="25%"
            name="prescricao_olho_esquerdo_adicao"
            aria="Selecione a opção da adição"
            options={adicao}
            defaultValue={
              adicao[
                adicao
                  .map(function (e) {
                    return e.value;
                  })
                  .indexOf(order.prescricao_olho_esquerdo_adicao)
              ]
            }
			value={adicao[adicao.map((e) => e.value).indexOf(order.prescricao_olho_esquerdo_adicao)]}
            onChange={this.handleChangeAdicaoEsquerdo}
            disabled={order.rpl ? true : false}
            placeholder="Selecione"
            onlyDot
          />
        );
      } else if (this.state.adicao === "OB") {
        adicaoOlhoDireito = null;

        adicaoOlhoEsquerdo = (
          <SelectAuto
            ref={this.adicaoEsquerdoRef}
            className="font-order"
            label="Adição*"
            width="25%"
            name="prescricao_olho_esquerdo_adicao"
            aria="Selecione a opção da adição"
            // itens={adicao}
            options={adicao}
            // value={order.prescricao_olho_esquerdo_adicao}
            defaultValue={
              adicao[
                adicao
                  .map(function (e) {
                    return e.value;
                  })
                  .indexOf(order.prescricao_olho_esquerdo_adicao)
              ]
            }
			value={adicao[adicao.map((e) => e.value).indexOf(order.prescricao_olho_esquerdo_adicao)]}
            onChange={this.handleChangeAdicaoEsquerdo}
            // onChange={this.handleSelectChange}
            required
            disabled={order.rpl ? true : false}
            placeholder="Selecione"
            onlyDot
          />
        );
      }
    }

    var croOlhoDireito = null;
    var croOlhoEsquerdo = null;

    if (this.state.cro !== "N/A") {
      croOlhoDireito = (
        <Input
          className="font-order"
          name="prescricao_cro_direito"
          label={this.state.cro === "OB" ? "CRO*" : "CRO"}
          type="number"
          min="0"
          step="0.01"
          width="20%"
          aria="Informe o CRO"
          tooltip="Centro de Rotação Ocular, medido pelo Visioffice."
          value={order.prescricao_cro_direito}
          onChange={this.handleChange}
          required={this.state.cro === "OB" ? true : false}
        />
      );

      croOlhoEsquerdo = (
        <Input
          className="font-order"
          name="prescricao_cro_esquerdo"
          label={this.state.cro === "OB" ? "CRO*" : "CRO"}
          type="number"
          min="0"
          step="0.01"
          width="20%"
          aria="Informe o CRO"
          tooltip="Centro de Rotação Ocular, medido pelo Visioffice."
          value={order.prescricao_cro_esquerdo}
          onChange={this.handleChange}
          required={this.state.cro === "OB" ? true : false}
        />
      );
    }

    var distanciaVerticeOlhoDireito = null;
    var distanciaVerticeOlhoEsquerdo = null;

    if (this.state.distVert !== "N/A") {
      distanciaVerticeOlhoDireito = (
        <Input
          className="font-order"
          name="prescricao_dis_vert_direito"
          label={this.state.distVert === "OB" ? "Distância vértice*" : "Distância vértice"}
          type="number"
          min="0"
          step="0.01"
          width="20%"
          aria="Informe a distância da vértice"
          tooltip="Distância entre o olho e as lentes, medida pelo Visioffice."
          value={order.prescricao_dis_vert_direito}
          onChange={this.handleChange}
          required={this.state.distVert === "OB" ? true : false}
        />
      );

      distanciaVerticeOlhoEsquerdo = (
        <Input
          className="font-order"
          name="prescricao_dis_vert_esquerdo"
          label={this.state.distVert === "OB" ? "Distância vértice*" : "Distância vértice"}
          type="number"
          min="0"
          step="0.01"
          width="20%"
          aria="Informe a distância da vértice"
          tooltip="Distância entre o olho e as lentes, medida pelo Visioffice."
          value={order.prescricao_dis_vert_esquerdo}
          onChange={this.handleChange}
          required={this.state.distVert === "OB" ? true : false}
        />
      );
    }

    var dnpPertoDir = null;
    var dnpPertoEsq = null;

    if (this.state.dnpPerto === "OP") {
      dnpPertoDir = (
        <Input
          className="font-order"
          name="prescricao_olho_direito_dnp_perto"
          label="DNP de Perto"
          type="number"
          width="20%"
          aria="Informe o DNP de perto"
          min="20"
          max="40"
          step="0.1"
          value={order.prescricao_olho_direito_dnp_perto}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        />
      );

      dnpPertoEsq = (
        <Input
          className="font-order"
          name="prescricao_olho_esquerdo_dnp_perto"
          label="DNP de Perto"
          type="number"
          width="20%"
          aria="Informe o DNP"
          min="20"
          max="40"
          step="0.1"
          value={order.prescricao_olho_esquerdo_dnp_perto}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        />
      );
    } else if (this.state.dnpPerto === "OB") {
      dnpPertoDir = (
        <Input
          className="font-order"
          name="prescricao_olho_direito_dnp_perto"
          label="DNP de Perto*"
          type="number"
          width="20%"
          aria="Informe o DNP de perto"
          min="20"
          max="40"
          step="0.1"
          value={order.prescricao_olho_direito_dnp_perto}
          onChange={this.handleChange}
          required
          onBlur={this.handleBlur}
        />
      );

      dnpPertoEsq = (
        <Input
          className="font-order"
          name="prescricao_olho_esquerdo_dnp_perto"
          label="DNP de Perto*"
          type="number"
          width="20%"
          aria="Informe o DNP"
          min="20"
          max="40"
          step="0.1"
          value={order.prescricao_olho_esquerdo_dnp_perto}
          onChange={this.handleChange}
          required
          onBlur={this.handleBlur}
        />
      );
    }

    var alturaEsq = null;
    var alturaDir = null;

    if (this.state.altura === "OP" || this.state.altura === "OB") {
      alturaDir = (
        <Input
          className="font-order"
          name="prescricao_olho_direito_altura"
          label={this.state.altura === "OB" ? "Altura*" : "Altura"}
          type="number"
          width="20%"
          aria="Informe a altura"
          min="12"
          max="52"
          step="0.5"
          value={order.prescricao_olho_direito_altura}
          onChange={this.handleChange}
          required={this.state.altura === "OB" ? "required" : ""}
          onBlur={this.handleBlur}
          disabled={order.rpl ? true : false}
          onlyDot
        />
      );

      alturaEsq = (
        <Input
          className="font-order"
          name="prescricao_olho_esquerdo_altura"
          label={this.state.altura === "OB" ? "Altura*" : "Altura"}
          type="number"
          width="20%"
          aria="Informe a altura"
          min="12"
          max="52"
          step="0.5"
          value={order.prescricao_olho_esquerdo_altura}
          onChange={this.handleChange}
          required={this.state.altura === "OB" ? "required" : ""}
          onBlur={this.handleBlur}
          disabled={order.rpl ? true : false}
          onlyDot
        />
      );
    }

    var dnpDir = null;
    var dnpEsq = null;

    if (this.state.dnp === "OP") {
      dnpDir = (
        <Input
          className="font-order"
          name="prescricao_olho_direito_dnp"
          label="DNP"
          type="number"
          width="20%"
          aria="Informe o DNP"
          min="20"
          max="40"
          step="0.1"
          value={order.prescricao_olho_direito_dnp}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          disabled={order.rpl ? true : false}
          onlyDot
        />
      );

      dnpEsq = (
        <Input
          className="font-order"
          name="prescricao_olho_esquerdo_dnp"
          label="DNP"
          type="number"
          width="20%"
          aria="Informe o DNP"
          min="20"
          max="40"
          step="0.1"
          value={order.prescricao_olho_esquerdo_dnp}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          disabled={order.rpl ? true : false}
          onlyDot
        />
      );
    } else if (this.state.dnp === "OB") {
      dnpDir = (
        <Input
          className="font-order"
          name="prescricao_olho_direito_dnp"
          label="DNP*"
          type="number"
          width="20%"
          aria="Informe o DNP"
          min="20"
          max="40"
          step="0.1"
          value={order.prescricao_olho_direito_dnp}
          onChange={this.handleChange}
          required
          onBlur={this.handleBlur}
          disabled={order.rpl ? true : false}
          onlyDot
        />
      );

      dnpEsq = (
        <Input
          className="font-order"
          name="prescricao_olho_esquerdo_dnp"
          label="DNP*"
          type="number"
          width="20%"
          aria="Informe o DNP"
          min="20"
          max="40"
          step="0.1"
          value={order.prescricao_olho_esquerdo_dnp}
          onChange={this.handleChange}
          required
          onBlur={this.handleBlur}
          disabled={order.rpl ? true : false}
          onlyDot
        />
      );
    }

    var etDir = null;
    var etEsq = null;

    if (this.state.et === "OP") {
      etDir = (
        <Input
          className="font-order"
          name="prescricao_olho_direito_et"
          label="ET"
          type="number"
          width="20%"
          aria="Informe o ET"
          tooltip="Espessura da Borda. De 0.5 a 3.0 em passos de 0.1"
          min="0.5"
          max="3"
          step="0.1"
          value={order.prescricao_olho_direito_et}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          disabled={order.rpl ? true : false}
          onlyDot
        />
      );

      etEsq = (
        <Input
          className="font-order"
          name="prescricao_olho_esquerdo_et"
          label="ET"
          type="number"
          width="20%"
          aria="Informe o ET"
          tooltip="Espessura da Borda. De 0.5 a 3.0 em passos de 0.1"
          min="0.5"
          max="3"
          step="0.1"
          value={order.prescricao_olho_esquerdo_et}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          disabled={order.rpl ? true : false}
          onlyDot
        />
      );
    } else if (this.state.et === "OB") {
      etDir = (
        <Input
          className="font-order"
          name="prescricao_olho_direito_et"
          label="ET*"
          type="number"
          width="20%"
          aria="Informe o ET"
          tooltip="Espessura da Borda. De 0.5 a 3.0 em passos de 0.1"
          min="0.5"
          max="3"
          step="0.1"
          value={order.prescricao_olho_direito_et}
          onChange={this.handleChange}
          required
          onBlur={this.handleBlur}
          disabled={order.rpl ? true : false}
          onlyDot
        />
      );

      etEsq = (
        <Input
          className="font-order"
          name="prescricao_olho_esquerdo_et"
          label="ET*"
          type="number"
          width="20%"
          aria="Informe o ET"
          tooltip="Espessura da Borda. De 0.5 a 3.0 em passos de 0.1"
          min="0.5"
          max="3"
          step="0.1"
          value={order.prescricao_olho_esquerdo_et}
          onChange={this.handleChange}
          required
          onBlur={this.handleBlur}
          disabled={order.rpl ? true : false}
          onlyDot
        />
      );
    }

    var ctDir = null;
    var ctEsq = null;

    if (this.state.ct === "OP") {
      ctDir = (
        <Input
          className="font-order"
          name="prescricao_olho_direito_ct"
          label="CT"
          type="number"
          width="20%"
          aria="Informe o CT"
          tooltip="Espessura do Centro. De 1.4 a 10.0 em passos de 0.1"
          min="1.4"
          max="10"
          step="0.1"
          value={order.prescricao_olho_direito_ct}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          disabled={order.rpl ? true : false}
          onlyDot
        />
      );

      ctEsq = (
        <Input
          className="font-order"
          name="prescricao_olho_esquerdo_ct"
          label="CT"
          type="number"
          width="20%"
          aria="Informe o CT"
          tooltip="Espessura do Centro. De 1.4 a 10.0 em passos de 0.1"
          min="1.4"
          max="10"
          step="0.1"
          value={order.prescricao_olho_esquerdo_ct}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          disabled={order.rpl ? true : false}
          onlyDot
        />
      );
    } else if (this.state.ct === "OB") {
      ctDir = (
        <Input
          className="font-order"
          name="prescricao_olho_direito_ct"
          label="CT*"
          type="number"
          width="20%"
          aria="Informe o CT"
          tooltip="Espessura do Centro. De 1.4 a 10.0 em passos de 0.1"
          min="1.4"
          max="10"
          step="0.1"
          value={order.prescricao_olho_direito_ct}
          onChange={this.handleChange}
          required
          onBlur={this.handleBlur}
          disabled={order.rpl ? true : false}
          onlyDot
        />
      );

      ctEsq = (
        <Input
          className="font-order"
          name="prescricao_olho_esquerdo_ct"
          label="CT*"
          type="number"
          width="20%"
          aria="Informe o CT"
          tooltip="Espessura do Centro. De 1.4 a 10.0 em passos de 0.1"
          min="1.4"
          max="10"
          step="0.1"
          value={order.prescricao_olho_esquerdo_ct}
          onChange={this.handleChange}
          required
          onBlur={this.handleBlur}
          disabled={order.rpl ? true : false}
          onlyDot
        />
      );
    }

    var baseDir = null;
    var baseEsq = null;

    if (this.state.base === "OP") {
      baseDir = (
        <Input
          className="font-order"
          name="prescricao_olho_direito_base"
          label="Base"
          type="number"
          width="20%"
          aria="Informe a Base"
          min="0.25"
          max="30"
          step="0.25"
          tooltip="Valor da Curva. De 0.25 a 30.00 em passos de 0.25"
          value={order.prescricao_olho_direito_base}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          disabled={order.rpl ? true : false}
          onlyDot
        />
      );

      baseEsq = (
        <Input
          className="font-order"
          name="prescricao_olho_esquerdo_base"
          label="Base"
          type="number"
          width="20%"
          aria="Informe a Base"
          min="0.25"
          max="30"
          step="0.25"
          tooltip="Valor da Curva. De 0.25 a 30.00 em passos de 0.25"
          value={order.prescricao_olho_esquerdo_base}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          disabled={order.rpl ? true : false}
          onlyDot
        />
      );
    } else if (this.state.base === "OB") {
      baseDir = (
        <Input
          className="font-order"
          name="prescricao_olho_direito_base"
          label="Base*"
          type="number"
          width="20%"
          aria="Informe a Base"
          min="0.25"
          max="30"
          step="0.25"
          tooltip="Valor da Curva. De 0.25 a 30.00 em passos de 0.25"
          value={order.prescricao_olho_direito_base}
          onChange={this.handleChange}
          required
          onBlur={this.handleBlur}
          disabled={order.rpl ? true : false}
          onlyDot
        />
      );

      baseEsq = (
        <Input
          className="font-order"
          name="prescricao_olho_esquerdo_base"
          label="Base*"
          type="number"
          width="20%"
          aria="Informe a Base"
          min="0.25"
          max="30"
          step="0.25"
          tooltip="Valor da Curva. De 0.25 a 30.00 em passos de 0.25"
          value={order.prescricao_olho_esquerdo_base}
          onChange={this.handleChange}
          required
          onBlur={this.handleBlur}
          disabled={order.rpl ? true : false}
          onlyDot
        />
      );
    }

    return (
      <section className={`x-web-ordering-pedido__prescription ${this.props.order.direction === "right" ? "right" : "left"} content`}>
        <div className="order-main-container">
          <div className="x-web-ordering-pedido__prescription--intro intro__form--container">
            <h2 className="order-title">Informações da receita</h2>
            <p className="order-paragraph right">*Campos obrigatórios</p>
          </div>

          <ButtonRL
            name="prescricao_olhos"
            id_both="prescricao_ambosSelect_olhos"
            id_right="prescricao_olhoSelect_direito"
            id_left="prescricao_olhoSelect_esquerdo"
            onClick={this.handleToggler}
            defaultChecked_both={order.prescricao_olhos === "ambos" ? true : false}
            defaultChecked_right={order.prescricao_olhos === "direito" ? true : false}
            defaultChecked_left={order.prescricao_olhos === "esquerdo" ? true : false}
            disabled_left={order.rpl ? true : false}
            disabled_right={order.rpl ? true : false}
          />

          <div className="x-web-ordering-pedido__prescription--body column-tablet is--mobile">
            {order.prescricao_olhos !== "esquerdo" && (
              <div className="x-web-ordering-pedido__prescription--row-both column-tablet">
                <small className="x-web-ordering-pedido__prescription--subtitle-right">Olho direito</small>

                <div className="x-web-ordering-pedido__prescription--item is--mobile">
                  <SelectAuto
                    label="Esférico*"
                    width="25%"
                    name="prescricao_olho_direito_esferico"
                    id="prescricao_olho_direito_esferico"
                    aria="Selecione a opção do esférico"
                    min="2"
                    required
                    isClearable
                    options={esferico}
                    defaultValue={
                      esferico[
                        esferico
                          .map(function (e) {
                            return e.value;
                          })
                          .indexOf(order.prescricao_olho_direito_esferico)
                      ]
                    }
                    onChange={event => this.handleChangeEsfericoDireito(event, this.cilindricoDireitoRef)}
                    disabled={order.rpl ? true : false}
                    placeholder={order.rpl ? "" : "Selecione"}
                    onlyDot
                  />

                  <SelectAuto
                    ref={this.cilindricoDireitoRef}
                    label={order.prescricao_olho_direito_eixo !== "" && order.prescricao_olho_direito_eixo !== undefined ? "Cilíndrico*" : "Cilíndrico"}
                    width="25%"
                    name="prescricao_olho_direito_cilindrico"
                    aria="Selecione a opção do cilíndrico"
                    id="prescricao_olho_direito_cilindrico"
                    options={cilindrico}
                    defaultValue={
                      cilindrico[
                        cilindrico
                          .map(function (e) {
                            return e.value;
                          })
                          .indexOf(order.prescricao_olho_direito_cilindrico)
                      ]
                    }
                    onChange={this.handleChangeCilindricoDireito}
                    required={order.prescricao_olho_direito_eixo !== "" && order.prescricao_olho_direito_eixo !== undefined ? true : false}
                    disabled={order.rpl ? true : false}
                    placeholder={order.rpl ? "" : "Selecione"}
                    isClearable
                    onlyDot
                  />

                  <SelectAuto
                    ref={this.eixoDireitoRef}
                    label={order.prescricao_olho_direito_cilindrico !== "" && order.prescricao_olho_direito_cilindrico !== undefined ? "Eixo*" : "Eixo"}
                    width="25%"
                    name="prescricao_olho_direito_eixo"
                    autocomplete="prescricao_olho_direito_eixo"
                    aria="Selecione a opção do eixo"
                    id="prescricao_olho_direito_eixo"
                    options={eixo}
                    defaultValue={
                      eixo[
                        eixo
                          .map(function (e) {
                            return e.value;
                          })
                          .indexOf(order.prescricao_olho_direito_eixo)
                      ]
                    }
                    onChange={this.handleChangeEixoDireito}
                    required={order.prescricao_olho_direito_cilindrico !== "" && order.prescricao_olho_direito_cilindrico !== undefined ? true : false}
                    disabled={order.rpl ? true : false}
                    placeholder={order.rpl ? "" : "Selecione"}
                    isClearable
                    withoutDotComma
                  />

                  {adicaoOlhoDireito}
                </div>
              </div>
            )}

            {order.prescricao_olhos !== "direito" && (
              <div className="x-web-ordering-pedido__prescription--row-both column-tablet">
                <small className="x-web-ordering-pedido__prescription--subtitle-left ">Olho esquerdo</small>

                <div className="x-web-ordering-pedido__prescription--item is--mobile">
                  <SelectAuto
                    ref={this.esfericoEsquerdoRef}
                    label="Esférico*"
                    width="25%"
                    name="prescricao_olho_esquerdo_esferico"
                    aria="Selecione a opção do esférico"
                    id="prescricao_olho_esquerdo_esferico"
                    required
                    isClearable
                    options={esferico}
                    defaultValue={
                      esferico[
                        esferico
                          .map(function (e) {
                            return e.value;
                          })
                          .indexOf(order.prescricao_olho_esquerdo_esferico)
                      ]
                    }
                    onChange={this.handleChangeEsfericoEsquerdo}
                    disabled={order.rpl ? true : false}
                    placeholder={order.rpl ? "" : "Selecione"}
                    onlyDot
                  />

                  <SelectAuto
                    ref={this.cilindricoEsquerdoRef}
                    label={order.prescricao_olho_esquerdo_eixo !== "" && order.prescricao_olho_esquerdo_eixo !== undefined ? "Cilíndrico*" : "Cilíndrico"}
                    width="25%"
                    name="prescricao_olho_esquerdo_cilindrico"
                    aria="Selecione a opção do cilíndrico"
                    id="prescricao_olho_esquerdo_cilindrico"
                    options={cilindrico}
                    defaultValue={
                      cilindrico[
                        cilindrico
                          .map(function (e) {
                            return e.value;
                          })
                          .indexOf(order.prescricao_olho_esquerdo_cilindrico)
                      ]
                    }
                    onChange={this.handleChangeCilindricoEsquerdo}
                    required={order.prescricao_olho_esquerdo_eixo !== "" && order.prescricao_olho_esquerdo_eixo !== undefined ? true : false}
                    disabled={order.rpl ? true : false}
                    placeholder={order.rpl ? "" : "Selecione"}
                    isClearable
                    onlyDot
                  />

                  <SelectAuto
                    ref={this.eixoEsquerdoRef}
                    label={order.prescricao_olho_esquerdo_cilindrico !== "" && order.prescricao_olho_esquerdo_cilindrico !== undefined ? "Eixo*" : "Eixo"}
                    width="25%"
                    name="prescricao_olho_esquerdo_eixo"
                    aria="Selecione a opção do eixo"
                    id="prescricao_olho_esquerdo_eixo"
                    options={eixo}
                    defaultValue={
                      eixo[
                        eixo
                          .map(function (e) {
                            return e.value;
                          })
                          .indexOf(order.prescricao_olho_esquerdo_eixo)
                      ]
                    }
                    onChange={this.handleChangeEixoEsquerdo}
                    required={order.prescricao_olho_esquerdo_cilindrico !== "" && order.prescricao_olho_esquerdo_cilindrico !== undefined ? true : false}
                    disabled={order.rpl ? true : false}
                    placeholder={order.rpl ? "" : "Selecione"}
                    isClearable
                    withoutDotComma
                  />

                  {adicaoOlhoEsquerdo}
                </div>
              </div>
            )}
          </div>

          {!order.isLentePronta() && (
            <ButtonYN
              label="Possui prisma?"
              name="prescricao_prisma"
              id_yes="prisma_sim"
              id_no="prisma_nao"
              onClick={this.handleTogglerPussiPrisma}
              defaultChecked_yes={order.prescricao_prisma === "S" ? true : false}
              defaultChecked_no={order.prescricao_prisma === "N" ? true : false}
            />
          )}

          {!order.isLentePronta() && order.prescricao_prisma === "S" && (
            <div className="x-web-ordering-pedido__prescription--body column-tablet is--mobile ">
              {order.prescricao_olhos === "ambos" && (
                <>
                  <div className="x-web-ordering-pedido__prescription--row-both column-tablet">
                    <small className="x-web-ordering-pedido__prescription--subtitle-right">Olho direito</small>

                    <div className="x-web-ordering-pedido__prescription--item">
                      <SelectAuto
                        ref={this.prismaHorizontalDireitoRef}
                        label={
                          order.prescricao_olho_direito_base_h !== "" && order.prescricao_olho_direito_base_h !== undefined
                            ? "Prisma Horizontal*"
                            : "Prisma Horizontal"
                        }
                        width="25%"
                        name="prescricao_olho_direito_pris_h"
                        aria="Selecione a opção do Prisma Horizontal"
                        isClearable
                        options={prisma}
                        required={order.prescricao_olho_direito_base_h !== "" && order.prescricao_olho_direito_base_h !== undefined ? true : false}
                        defaultValue={
                          prisma[
                            prisma
                              .map(function (e) {
                                return e.value;
                              })
                              .indexOf(order.prescricao_olho_direito_pris_h)
                          ]
                        }
                        onChange={this.handleChangePrismaHorizontalDireito}
                        placeholder={"Selecione"}
                        onlyDot
                      />

                      <Select
                        ref={this.baseHorizontalDireitoRef}
                        label={order.prescricao_olho_direito_pris_h !== "" && order.prescricao_olho_direito_pris_h !== undefined ? "Base*" : "Base"}
                        width="25%"
                        name="prescricao_olho_direito_base_h"
                        aria="Selecione a opção da Base"
                        itens={[
                          { label: "Nasal", value: "NASAL" },
                          { label: "Temporal", value: "TEMPORAL" },
                        ]}
                        value={order.prescricao_olho_direito_base_h}
                        required={order.prescricao_olho_direito_pris_h !== "" && order.prescricao_olho_direito_pris_h !== undefined ? true : false}
                        onChange={(evt) => this.handleSelectChange(evt, this.prismaVerticalDireitoRef)}
                      />

                      <SelectAuto
                        ref={this.prismaVerticalDireitoRef}
                        label={
                          order.prescricao_olho_direito_base_v !== "" && order.prescricao_olho_direito_base_v !== undefined
                            ? "Prisma Vertical*"
                            : "Prisma Vertical"
                        }
                        width="25%"
                        name="prescricao_olho_direito_pris_v"
                        aria="Selecione a opção do Prisma Vertical"
                        isClearable
                        options={prisma}
                        required={order.prescricao_olho_direito_pris_v !== "" && order.prescricao_olho_direito_pris_v !== undefined ? true : false}
                        defaultValue={
                          prisma[
                            prisma
                              .map(function (e) {
                                return e.value;
                              })
                              .indexOf(order.prescricao_olho_direito_pris_v)
                          ]
                        }
                        onChange={this.handleChangePrismaVesticalDireito}
                        placeholder={"Selecione"}
                        onlyDot
                      />

                      <Select
                        ref={this.baseVerticalDireitoRef}
                        label={order.prescricao_olho_direito_pris_v !== "" && order.prescricao_olho_direito_pris_v !== undefined ? "Base*" : "Base"}
                        width="25%"
                        name="prescricao_olho_direito_base_v"
                        aria="Selecione a opção da Base"
                        itens={[
                          { label: "Superior", value: "SUPERIOR" },
                          { label: "Inferior", value: "INFERIOR" },
                        ]}
                        value={order.prescricao_olho_direito_base_v}
                        required={order.prescricao_olho_direito_pris_v !== "" && order.prescricao_olho_direito_pris_v !== undefined ? true : false}
                        onChange={(evt) => this.handleSelectChange(evt, this.prismaHorizontalEsquerdoRef)}
                      />
                    </div>
                  </div>

                  <div className="x-web-ordering-pedido__prescription--row-both column-tablet">
                    <small className="x-web-ordering-pedido__prescription--subtitle-left">Olho esquerdo</small>

                    <div className="x-web-ordering-pedido__prescription--item">
                      <SelectAuto
                        ref={this.prismaHorizontalEsquerdoRef}
                        label={
                          order.prescricao_olho_esquerdo_base_h !== "" && order.prescricao_olho_esquerdo_base_h !== undefined
                            ? "Prisma Horizontal*"
                            : "Prisma Horizontal"
                        }
                        width="25%"
                        name="prescricao_olho_esquerdo_pris_h"
                        aria="Selecione a opção do Prisma Horizontal"
                        isClearable
                        options={prisma}
                        required={order.prescricao_olho_esquerdo_base_h !== "" && order.prescricao_olho_esquerdo_base_h !== undefined ? true : false}
                        defaultValue={
                          prisma[
                            prisma
                              .map(function (e) {
                                return e.value;
                              })
                              .indexOf(order.prescricao_olho_esquerdo_pris_h)
                          ]
                        }
                        onChange={this.handleChangePrismaHorizontalEsquerdo}
                        placeholder={"Selecione"}
                        onlyDot
                      />

                      <Select
                        ref={this.baseHorizontalEsquerdoRef}
                        label={order.prescricao_olho_esquerdo_pris_h !== "" && order.prescricao_olho_esquerdo_pris_h !== undefined ? "Base*" : "Base"}
                        width="25%"
                        name="prescricao_olho_esquerdo_base_h"
                        aria="Selecione a opção da Base"
                        itens={[
                          { label: "Nasal", value: "NASAL" },
                          { label: "Temporal", value: "TEMPORAL" },
                        ]}
                        value={order.prescricao_olho_esquerdo_base_h}
                        required={order.prescricao_olho_esquerdo_pris_h !== "" && order.prescricao_olho_esquerdo_pris_h !== undefined ? true : false}
                        onChange={(evt) => this.handleSelectChange(evt, this.prismaVerticalEsquerdoRef)}
                      />

                      <SelectAuto
                        ref={this.prismaVerticalEsquerdoRef}
                        label={
                          order.prescricao_olho_esquerdo_base_v !== "" && order.prescricao_olho_esquerdo_base_v !== undefined
                            ? "Prisma Vertical*"
                            : "Prisma Vertical"
                        }
                        width="25%"
                        name="prescricao_olho_esquerdo_pris_v"
                        aria="Selecione a opção do Prisma Vertical"
                        isClearable
                        options={prisma}
                        required={order.prescricao_olho_esquerdo_base_v !== "" && order.prescricao_olho_esquerdo_base_v !== undefined ? true : false}
                        defaultValue={
                          prisma[
                            prisma
                              .map(function (e) {
                                return e.value;
                              })
                              .indexOf(order.prescricao_olho_esquerdo_pris_v)
                          ]
                        }
                        onChange={this.handleChangePrismaVesticalEsquerdo}
                        placeholder={"Selecione"}
                        onlyDot
                      />

                      <Select
                        ref={this.baseVerticalEsquerdoRef}
                        label={order.prescricao_olho_esquerdo_pris_v !== "" && order.prescricao_olho_esquerdo_pris_v !== undefined ? "Base*" : "Base"}
                        width="25%"
                        name="prescricao_olho_esquerdo_base_v"
                        aria="Selecione a opção da Base"
                        itens={[
                          { label: "Superior", value: "SUPERIOR" },
                          { label: "Inferior", value: "INFERIOR" },
                        ]}
                        value={order.prescricao_olho_esquerdo_base_v}
                        required={order.prescricao_olho_esquerdo_pris_v !== "" && order.prescricao_olho_esquerdo_pris_v !== undefined ? true : false}
                        onChange={this.handleSelectChange}
                      />
                    </div>
                  </div>
                </>
              )}

              {order.prescricao_olhos === "direito" && (
                <div className="x-web-ordering-pedido__prescription--row column-tablet flex-row gap">
                  <small className="x-web-ordering-pedido__prescription--subtitle-right">Olho direito</small>

                  <div className="x-web-ordering-pedido__prescription--item">
                    <SelectAuto
                      ref={this.prismaHorizontalDireitoRef}
                      label={
                        order.prescricao_olho_direito_base_h !== "" && order.prescricao_olho_direito_base_h !== undefined
                          ? "Prisma Horizontal*"
                          : "Prisma Horizontal"
                      }
                      width="25%"
                      name="prescricao_olho_direito_pris_h"
                      aria="Selecione a opção do Prisma Horizontal"
                      isClearable
                      options={prisma}
                      defaultValue={
                        prisma[
                          prisma
                            .map(function (e) {
                              return e.value;
                            })
                            .indexOf(order.prescricao_olho_direito_pris_h)
                        ]
                      }
                      onChange={this.handleChangePrismaHorizontalDireito}
                      placeholder={"Selecione"}
                      onlyDot
                      required={order.prescricao_olho_direito_base_h !== "" && order.prescricao_olho_direito_base_h !== undefined ? true : false}
                    />

                    <Select
                      ref={this.baseHorizontalDireitoRef}
                      label={order.prescricao_olho_direito_pris_h !== "" && order.prescricao_olho_direito_pris_h !== undefined ? "Base*" : "Base"}
                      width="25%"
                      name="prescricao_olho_direito_base_h"
                      aria="Selecione a opção da Base"
                      itens={[
                        { label: "Nasal", value: "NASAL" },
                        { label: "Temporal", value: "TEMPORAL" },
                      ]}
                      value={order.prescricao_olho_direito_base_h}
                      required={order.prescricao_olho_direito_pris_h !== "" && order.prescricao_olho_direito_pris_h !== undefined ? true : false}
                      onChange={(evt) => this.handleSelectChange(evt, this.prismaVerticalDireitoRef)}
                    />

                    <SelectAuto
                      ref={this.prismaVerticalDireitoRef}
                      label={
                        order.prescricao_olho_direito_base_v !== "" && order.prescricao_olho_direito_base_v !== undefined
                          ? "Prisma Vertical*"
                          : "Prisma Vertical"
                      }
                      width="25%"
                      name="prescricao_olho_direito_pris_v"
                      aria="Selecione a opção do Prisma Vertical"
                      isClearable
                      options={prisma}
                      defaultValue={
                        prisma[
                          prisma
                            .map(function (e) {
                              return e.value;
                            })
                            .indexOf(order.prescricao_olho_direito_pris_v)
                        ]
                      }
                      onChange={this.handleChangePrismaVesticalDireito}
                      placeholder={"Selecione"}
                      onlyDot
                      required={order.prescricao_olho_direito_base_v !== "" && order.prescricao_olho_direito_base_v !== undefined ? true : false}
                    />

                    <Select
                      ref={this.baseVerticalDireitoRef}
                      label={order.prescricao_olho_direito_pris_v !== "" && order.prescricao_olho_direito_pris_v !== undefined ? "Base*" : "Base"}
                      width="25%"
                      name="prescricao_olho_direito_base_v"
                      aria="Selecione a opção da Base"
                      itens={[
                        { label: "Superior", value: "SUPERIOR" },
                        { label: "Inferior", value: "INFERIOR" },
                      ]}
                      value={order.prescricao_olho_direito_base_v}
                      onChange={(evt) => this.handleSelectChange(evt, this.prismaHorizontalEsquerdoRef)}
                      required={order.prescricao_olho_direito_pris_v !== "" && order.prescricao_olho_direito_pris_v !== undefined ? true : false}
                    />
                  </div>
                </div>
              )}

              {order.prescricao_olhos === "esquerdo" && (
                <div className="x-web-ordering-pedido__prescription--row is--mobile flex-row gap">
                  <small className="x-web-ordering-pedido__prescription--subtitle-left">Olho esquerdo</small>

                  <div className="x-web-ordering-pedido__prescription--item">
                    <SelectAuto
                      ref={this.prismaHorizontalEsquerdoRef}
                      label={
                        order.prescricao_olho_esquerdo_base_h !== "" && order.prescricao_olho_esquerdo_base_h !== undefined
                          ? "Prisma Horizontal*"
                          : "Prisma Horizontal"
                      }
                      width="25%"
                      name="prescricao_olho_esquerdo_pris_h"
                      aria="Selecione a opção do Prisma Horizontal"
                      isClearable
                      options={prisma}
                      defaultValue={
                        prisma[
                          prisma
                            .map(function (e) {
                              return e.value;
                            })
                            .indexOf(order.prescricao_olho_esquerdo_pris_h)
                        ]
                      }
                      onChange={this.handleChangePrismaHorizontalEsquerdo}
                      placeholder={"Selecione"}
                      onlyDot
                      required={order.prescricao_olho_esquerdo_base_h !== "" && order.prescricao_olho_esquerdo_base_h !== undefined ? true : false}
                    />

                    <Select
                      ref={this.baseHorizontalEsquerdoRef}
                      label={order.prescricao_olho_esquerdo_pris_h !== "" && order.prescricao_olho_esquerdo_pris_h !== undefined ? "Base*" : "Base"}
                      width="25%"
                      name="prescricao_olho_esquerdo_base_h"
                      aria="Selecione a opção da Base"
                      itens={[
                        { label: "Nasal", value: "NASAL" },
                        { label: "Temporal", value: "TEMPORAL" },
                      ]}
                      value={order.prescricao_olho_esquerdo_base_h}
                      onChange={(evt) => this.handleSelectChange(evt, this.prismaVerticalEsquerdoRef)}
                      required={order.prescricao_olho_esquerdo_pris_h !== "" && order.prescricao_olho_esquerdo_pris_h !== undefined ? true : false}
                    />

                    <SelectAuto
                      ref={this.prismaVerticalEsquerdoRef}
                      label={
                        order.prescricao_olho_esquerdo_base_v !== "" && order.prescricao_olho_esquerdo_base_v !== undefined
                          ? "Prisma Vertical*"
                          : "Prisma Vertical"
                      }
                      width="25%"
                      name="prescricao_olho_esquerdo_pris_v"
                      aria="Selecione a opção do Prisma Vertical"
                      isClearable
                      options={prisma}
                      defaultValue={
                        prisma[
                          prisma
                            .map(function (e) {
                              return e.value;
                            })
                            .indexOf(order.prescricao_olho_esquerdo_pris_v)
                        ]
                      }
                      onChange={this.handleChangePrismaVesticalEsquerdo}
                      placeholder={"Selecione"}
                      onlyDot
                      required={order.prescricao_olho_esquerdo_base_v !== "" && order.prescricao_olho_esquerdo_base_v !== undefined ? true : false}
                    />

                    <Select
                      ref={this.baseVerticalEsquerdoRef}
                      label={order.prescricao_olho_esquerdo_pris_v !== "" && order.prescricao_olho_esquerdo_pris_v !== undefined ? "Base*" : "Base"}
                      width="25%"
                      name="prescricao_olho_esquerdo_base_v"
                      aria="Selecione a opção da Base"
                      itens={[
                        { label: "Superior", value: "SUPERIOR" },
                        { label: "Inferior", value: "INFERIOR" },
                      ]}
                      value={order.prescricao_olho_esquerdo_base_v}
                      onChange={this.handleSelectChange}
                      required={order.prescricao_olho_esquerdo_pris_v !== "" && order.prescricao_olho_esquerdo_pris_v !== undefined ? true : false}
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          {(dnpDir ||
            alturaDir ||
            dnpPertoDir ||
            dnpEsq ||
            alturaEsq ||
            dnpPertoEsq ||
            croOlhoDireito ||
            distanciaVerticeOlhoDireito ||
            croOlhoEsquerdo ||
            distanciaVerticeOlhoEsquerdo) &&
            (order.quantidade === 1 || order.quantidade === "1") && (
              <>
                <h2 className="intro__form--subtitle-lg-2">Medidas do cliente</h2>

                <div className="x-web-ordering-pedido__prescription--body column-tablet is--mobile">
                  {order.prescricao_olhos !== "esquerdo" && (
                    <div className="x-web-ordering-pedido__prescription--row-both column-tablet">
                      <small className="x-web-ordering-pedido__prescription--subtitle-right">Olho direito</small>

                      <div className="x-web-ordering-pedido__prescription--item is--mobile">
                        {dnpDir}

                        {alturaDir}

                        {dnpPertoDir}

                        {croOlhoDireito}

                        {distanciaVerticeOlhoDireito}

                        {etDir}

                        {ctDir}

                        {baseDir}
                      </div>
                    </div>
                  )}

                  {order.prescricao_olhos !== "direito" && (
                    <div className="x-web-ordering-pedido__prescription--row-both column-tablet">
                      <small className="x-web-ordering-pedido__prescription--subtitle-left flex-start">Olho esquerdo</small>
                      <div className="x-web-ordering-pedido__prescription--item is--mobile">
                        {dnpEsq}

                        {alturaEsq}

                        {dnpPertoEsq}

                        {croOlhoEsquerdo}

                        {distanciaVerticeOlhoEsquerdo}

                        {etEsq}

                        {ctEsq}

                        {baseEsq}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}

          <div className="x-web-ordering-pedido__prescription--body column-tablet">
            {(this.state.iniciais !== "N/A" ||
              this.state.distLeitura !== "N/A" ||
              this.state.cvp !== "N/A" ||
              this.state.cro !== "N/A" ||
              this.state.angPanto !== "N/A" ||
              this.state.curvArma !== "N/A" ||
              this.state.coefHE !== "N/A" ||
              this.state.coefST !== "N/A" ||
              this.state.distProg !== "N/A" ||
              this.state.distVert !== "N/A" ||
              this.state.olhoDom !== "N/A") &&
              (order.quantidade === 1 || order.quantidade === "1") && <h2 className="intro__form--subtitle-lg-2">Medidas personalizadas</h2>}

            <div className="x-web-ordering-pedido__prescription--row">
              <div className="x-web-ordering-pedido__prescription--item no--gap">
                {this.state.iniciais !== "N/A" && (
                  <Input
                    className="font-order"
                    name="prescricao_ini_clientes"
                    label={this.state.iniciais === "OB" ? "Iniciais do cliente*" : "Iniciais do cliente"}
                    type="text"
                    width="25%"
                    aria="Informe as iniciais do cliente"
                    tooltip="Informe até 4 letras que serão gravadas nas lentes."
                    value={order.prescricao_ini_clientes.slice(0, 4)}
                    onChange={this.handleChange}
                    required={this.state.iniciais === "OB" ? true : false}
                    maxlength={4}
                  />
                )}

                {this.state.distLeitura !== "N/A" && (
                  <Input
                    className="font-order"
                    name="prescricao_dis_leitura"
                    label={this.state.distLeitura === "OB" ? "Distância de Leitura*" : "Distância de Leitura"}
                    type="number"
                    min="0"
                    step="0.01"
                    width="25%"
                    aria="Informe a distância de leitura"
                    tooltip="Medido pelo Visioffice."
                    value={order.prescricao_dis_leitura}
                    onChange={this.handleChange}
                    required={this.state.distLeitura === "OB" ? true : false}
                  />
                )}

                {this.state.cvp !== "N/A" && (
                  <Input
                    className="font-order"
                    name="prescricao_cvp"
                    label={this.state.cvp === "OB" ? "CVP*" : "CVP"}
                    type="text"
                    width="25%"
                    aria="Informe o CVP"
                    tooltip="Comportamento na Visão de Perto, medido pelo Visioffice."
                    value={order.prescricao_cvp}
                    onChange={this.handleChange}
                    required={this.state.cvp === "OB" ? true : false}
                  />
                )}
              </div>
            </div>
            <div className="x-web-ordering-pedido__prescription--row">
              <div className="x-web-ordering-pedido__prescription--item no--gap">
                {/* {this.state.wMap !== "N/A" && <Input
									name='prescricao_wmap'
									label={this.state.wMap === 'OB' ? 'Wmap*' : 'Wmap'}
									type='number'
									min='0'
									step='0.01'
									width='25%'
									aria='Informe o Wmap'
									value={order.prescricao_wmap}
									onChange={this.handleChange}
									required={this.state.wMap === 'OB' ? true : false}
								/>} */}

                {this.state.angPanto !== "N/A" && (
                  <Input
                    className="font-order"
                    name="prescricao_ang_pantoscopico"
                    label={this.state.angPanto === "OB" ? "Ângulo pantoscópico*" : "Ângulo pantoscópico"}
                    type="number"
                    min="0"
                    step="0.01"
                    width="25%"
                    aria="Informe o ângulo pantoscópico"
                    tooltip="Inclinação frontal da armação."
                    value={order.prescricao_ang_pantoscopico}
                    onChange={this.handleChange}
                    required={this.state.angPanto === "OB" ? true : false}
                  />
                )}

                {this.state.curvArma !== "N/A" && (
                  <Input
                    className="font-order"
                    name="prescricao_curva_arm"
                    label={this.state.curvArma === "OB" ? "Curvatura da armação*" : "Curvatura da armação"}
                    type="number"
                    min="0"
                    step="0.01"
                    width="25%"
                    aria="Informe a curvatura da amação"
                    tooltip="Ângulo de curvatura da armação. Não confundir com curva da lente."
                    value={order.prescricao_curva_arm}
                    onChange={this.handleChange}
                    required={this.state.curvArma === "OB" ? true : false}
                  />
                )}

                {this.state.coefHE !== "N/A" && (
                  <Input
                    className="font-order"
                    name="prescricao_coeficiente"
                    label={this.state.coefHE === "OB" ? "Coeficiente cabeça-olhos*" : "Coeficiente cabeça-olhos"}
                    type="number"
                    min="0"
                    step="0.01"
                    width="25%"
                    aria="Informe o coeficiente"
                    tooltip="Medido pelo Visioffice."
                    value={order.prescricao_coeficiente}
                    onChange={this.handleChange}
                    required={this.state.coefHE === "OB" ? true : false}
                  />
                )}
              </div>
            </div>
            <div className="x-web-ordering-pedido__prescription--row">
              <div className="x-web-ordering-pedido__prescription--item no--gap">
                {this.state.coefST !== "N/A" && (
                  <Input
                    className="font-order"
                    name="prescricao_estabilidade"
                    label={this.state.coefST === "OB" ? "Estabilidade*" : "Estabilidade"}
                    type="number"
                    min="0"
                    step="0.01"
                    width="25%"
                    aria="Informe a estabilidade"
                    tooltip="Medido pelo Visioffice."
                    value={order.prescricao_estabilidade}
                    onChange={this.handleChange}
                    required={this.state.coefST === "OB" ? true : false}
                  />
                )}

                {this.state.distProg !== "N/A" && (
                  <Input
                    className="font-order"
                    name="prescricao_dis_prog"
                    label={this.state.distProg === "OB" ? "Distância de progressão*" : "Distância de progressão"}
                    type="number"
                    min="0"
                    step="0.01"
                    width="25%"
                    aria="Informe a diatância da progressão"
                    tooltip="Medido pelo Visioffice."
                    value={order.prescricao_dis_prog}
                    onChange={this.handleChange}
                    required={this.state.distProg === "OB" ? true : false}
                  />
                )}

                {this.state.olhoDom !== "N/A" && (
                  <Select
                    className="font-order"
                    name="prescricao_olho_dom"
                    label={this.state.olhoDom === "OB" ? "Olho dominante*" : "Olho dominante"}
                    width="25%"
                    aria="Informe o olho dominante"
                    tooltip="Medido pelo Visioffice."
                    value={order.prescricao_olho_dom}
                    onChange={this.handleSelectChange}
                    required={this.state.olhoDom === "OB" ? true : false}
                    itens={[
                      { label: "Ambos", value: "AMBOS" },
                      { label: "Olho Direito", value: "DIREITO" },
                      { label: "Olho Esquerdo", value: "ESQUERDO" },
                    ]}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="order-buttons">
            <div className="order-prev-button">
              <CustomButton label="Anterior" width="11.125rem" icon_arrow_left onClick={this.handleClickPrev} />
            </div>

            <div className="order-next-button">
              <CustomButton label="Próximo" width="11.125rem" icon_arrow_right onClick={this.handleClickNext} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default OrderPerscription;
