import React, { Component } from "react";

//Import CSS

//Import internal components
import AppContext from "../appContext";
import Terms from "./modals/terms";
import Privacy from "./modals/privacy";
import Input from "../components/inputs/input";

//Import external components
import Alert from "sweetalert2";
import Swal from "sweetalert2";
import MaskedInput from "react-text-mask";
import ReactGA from "react-ga";
import Password from "../components/inputs/password";
import Moment from "moment";

//Import images

class SignupBody extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cnpj: "",
      name: "",
      surname: "",
      email: "",
      email1: "",
      datanasc: "",
      cpf: "",
      rg: "",
      telephone: "",
      cellphone: "",
      password: "",
      confirmPassword: "",
      passwordIsConfirm: true,
      emailIsConfirm: true,
      redirectL: false,
      opacity: "signup-right",
      loading: false,
      disabled: false,
      lock: false,
      lock1: true,
      terms: false,
      privacy: false,
      newsletter: false,
      nomePF: "",
    };

    SignupBody.contextType = AppContext;
    this.handleChange = this.handleChange.bind(this);
    this.cadastrar = this.cadastrar.bind(this);
    this.setBorder = this.setBorder.bind(this);
    this.webserviceCreate = this.webserviceCreate.bind(this);
    this.onPaste = this.onPaste.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.onClickCloseTerms = this.onClickCloseTerms.bind(this);
    this.onClickClosePrivacy = this.onClickClosePrivacy.bind(this);
    this.handleClickSignup = this.handleClickSignup.bind(this);
    this.handleClickOk = this.handleClickOk.bind(this);
    this.handleChangeConfirmPassword = this.handleChangeConfirmPassword.bind(this);
    this.handleChangeConfirmEmail = this.handleChangeConfirmEmail.bind(this);
  }

  handleChangeConfirmEmail(e) {
    const { name, value, id } = e.target;
    this.setState({ [name]: value }, () => {
      if (this.state.email !== this.state.email1) {
        this.setState({ emailIsConfirm: false });
        document.getElementById(id).style.borderColor = "red";
        document.getElementById(id).style.marginBottom = "0px";
      } else {
        this.setState({ emailIsConfirm: true });
        document.getElementById(id).style.borderColor = "#B5B5B5";
        document.getElementById(id).style.marginBottom = "10px";
      }
    });
  }

  handleChangeConfirmPassword(e) {
    const { name, value, id } = e.target;
    this.setState({ [name]: value }, () => {
      if (this.state.password !== this.state.confirmPassword) {
        this.setState({ passwordIsConfirm: false });
        document.getElementById("password").style.borderColor = "red";
        document.getElementById("password").style.marginBottom = "0px";
        document.getElementById("confirmPassword").style.borderColor = "red";
        document.getElementById("confirmPassword").style.marginBottom = "0px";
      } else {
        this.setState({ passwordIsConfirm: true });
        document.getElementById("password").style.borderColor = "#B5B5B5";
        document.getElementById("password").style.marginBottom = "10px";
        document.getElementById("confirmPassword").style.borderColor = "#B5B5B5";
        document.getElementById("confirmPassword").style.marginBottom = "10px";
      }
    });
  }

  handleChange(e) {
    const { name, value, id } = e.target;
    document.getElementById(id).style.borderColor = "#B5B5B5";
    this.setState({ [name]: value });
  }

  handleCheck(e) {
    const { name, checked, id } = e.target;
    this.setState({ [name]: checked });
  }

  onPaste(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }

  webserviceCreate() {
    const { onlyS9 } = this.props;

    this.context.showLoading("Concluindo cadastro...");
    var cnpjtrue = this.props.cnpj
      .replace(/[^0-9]/g, "")
      .replace("/", "")
      .replace("-", "");
    var cpftrue = this.state.cpf
      .replace(/[^0-9]/g, "")
      .replace("/", "")
      .replace("-", "");
    var rgtrue = this.state.rg.replace("/", "").replace("-", "");
    var teltrue = this.state.telephone
      .replace(/[^0-9]/g, "")
      .replace("/", "")
      .replace("-", "");
    var celtrue = this.state.cellphone
      .replace(/[^0-9]/g, "")
      .replace("/", "")
      .replace("-", "");
    var datanasc = this.state.datanasc.substr(6, 9) + "-" + this.state.datanasc.substr(3, 2) + "-" + this.state.datanasc.substr(0, 2);

    var email = this.state.email.toLowerCase();

    var ddd = "";
    var cellphone = "";

    if (this.state.cellphone && this.state.cellphone.indexOf(")")) {
      ddd = this.state.cellphone.split(")")[0].replace("(", "");
      cellphone = this.state.cellphone.split(")")[1].trim();
    }

    const requestOptions3 = {
      method: "POST",
      body: JSON.stringify({
        nomePF: onlyS9 ? this.state.name : this.state.nomePF,
        name: this.state.name,
        surname: this.state.surname,
        email: email,
        cpf: cpftrue,
        rg: rgtrue,
        telephone: teltrue,
        cellphone: celtrue,
        password: this.state.password,
        cnpj: cnpjtrue,
        datanasc: datanasc,
        newsletter: this.state.newsletter,
        ddd: ddd,
        cellphoneLead: cellphone,
        onlyS9: onlyS9,
      }),
    };
    fetch(process.env.REACT_APP_LABS_API + "create_user.php", requestOptions3)
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.status === 201 || json.status === 200) {
          document.getElementById("main-body").style.pointerEvents = "";
          this.setState({
            opacity: "signup-right",
            loading: false,
            disabled: false,
          });
          //fechando loading
          this.context.hideLoading();
          Swal.fire({
            html: "Usuário criado com sucesso!<br />Por favor, faça o login para acessar o sistema.",
            icon: "success",
            title: "Pronto!",
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonColor: "#00518b",
            confirmButtonText: "Ok!",
          }).then(result => {
            this.handleClickOk();
            if (result.value) {
              this.setState({
                redirectL: true,
              });
            }
          });
        } else if (json.status === 409) {
          let message = json.message.includes("CPF já cadastrado no Essilor Conecta. Por favor, faça login com seu e-mail e senha.") 
          ? `CPF já cadastrado no Essilor Conecta com o e-mail <b> ${email}</b>. Por favor, faça login com seu e-mail e senha.`: json.message
          document.getElementById("main-body").style.pointerEvents = "";
          this.setState({
            opacity: "signup-right",
            loading: false,
            disabled: false,
          });
          //fechando loading
          this.context.hideLoading();
          Swal.fire({
            html: message,
            icon: "warning",
            title: "Aviso",
            allowOutsideClick: false,
            confirmButtonColor: "#00518b",
            customClass: {
              confirmButton: "x-follow-order__filter-btn",
            },
          }).then(result => {
            if (result.value) {
              this.setState({
                redirectL: true,
              });
            }
          });
        } else {
          document.getElementById("main-body").style.pointerEvents = "";
          this.setState({
            opacity: "signup-right",
            loading: false,
            disabled: false,
          });
          //fechando loading
          this.context.hideLoading();
          Alert.fire({
            text: "Erro interno. Tente novamente mais tarde.",
            icon: "error",
            title: "Aviso",
            confirmButtonColor: "#00518b",
            customClass: {
              confirmButton: "x-follow-order__filter-btn",
            },
          });
        }
      });
  }

  validacaoCampos() {
    const { onlyS9 } = this.props;

    // confere a quantidade de digitos das informações, a senha não está sendo enviada no momento do cadastro
    var text = "";
    text = this.validacaoEmail(text);
    if (!onlyS9 || this.state.cpf) {
      text = this.validacaoCPF(text);
    }
    text = this.validacaoDataNascimento(text);
    text = this.validacaoTelefone(text);
    text = this.validacaoCelular(text);

    if (!this.state.passwordIsConfirm) {
      text = text + "Confirmação de senha não corresponde á senha informada.<br />";
    }

    if (text === "") {
      return true;
    } else {
      Alert.fire({
        html: text,
        icon: "warning",
        title: "Aviso",
        confirmButtonColor: "#00518b",
        customClass: {
          confirmButton: "x-follow-order__filter-btn",
        },
      });

      return false;
    }
  }

  validacaoEmail(text) {
    if (this.state.email !== this.state.email1) {
      text = text + "Confirmação de e-mail não corresponde ao e-mail informado.<br />";
    }

    return text;
  }

  validacaoCPF(text) {
    const { cpf } = require("cpf-cnpj-validator");

    var cpftrue = this.state.cpf
      .replace(/[^0-9]/g, "")
      .replace("/", "")
      .replace("-", "");

    if (cpftrue.length !== 11) {
      text = text + "Campo CPF com formato inválido.<br />";
    }

    if (!cpf.isValid(cpftrue)) {
      document.getElementById("main-body").style.pointerEvents = "";
      this.setState({
        opacity: "signup-right",
        loading: false,
        disabled: false,
      });
      text = text + "O CPF utilizado é inválido.<br />";

      // this.setState({
      // 	redirectL: false,
      // 	lock: true
      // })
    }

    return text;
  }

  validacaoTelefone(text) {
    var teltrue = this.state.telephone
      .replace(/[^0-9]/g, "")
      .replace("/", "")
      .replace("-", "");

    if (teltrue) {
      if (teltrue.length !== 10) {
        text = text + "Campo Telefone com formato inválido.<br />";
      }
    }

    return text;
  }

  validacaoCelular(text) {
    var celtrue = this.state.cellphone
      .replace(/[^0-9]/g, "")
      .replace("/", "")
      .replace("-", "");

    if (celtrue.length !== 11) {
      text = text + "Campo Celular com formato inválido.<br />";
    }

    return text;
  }

  validacaoDataNascimento(text) {
    var datanasc = Moment(this.state.datanasc, "DD/MM/YYYY", true);

    if (!datanasc.isValid()) {
      text = text + "Campo Data de nascimento com formato inválido.<br />";
    }

    return text;
  }

  validacaoAceiteTermo() {
    var terms = document.getElementById("terms");

    if (!terms.checked) {
      Alert.fire({
        text: "Você deve aceitar os Termos de Serviço para concluir o cadastro.",
        icon: "warning",
        title: "Aviso",
        confirmButtonColor: "#00518b",
        customClass: {
          confirmButton: "x-follow-order__filter-btn",
        },
      });

      return false;
    }

    return true;
  }

  validarCpfDataNascimento(cpftrue, callback) {
    // usado apenas na API de consulta de CPF, deve estar no formato 00/00/0000
    var datanasc = this.state.datanasc;

    const requestOptions3 = {
      method: "POST",
      body: JSON.stringify({
        cpf: cpftrue,
        datanasc: datanasc,
      }),
    };

    fetch(process.env.REACT_APP_LABS_API + "validate_cpf_nascimento.php", requestOptions3).then(response => {
      if (response.status === 200) {
        response.json().then(json => {
          this.setState({ nomePF: json.nome });
          callback();
        });
      } else if (response.status === 404) {
        response.json().then(json => {
          if (json.status === 404) {
            document.getElementById("main-body").style.pointerEvents = "";

            this.setState({
              opacity: "signup-right",
              loading: false,
              disabled: false,
            });

            //fechando loading
            this.context.hideLoading();

            Alert.fire({
              html:
                "<strong>CPF:</strong> " +
                this.state.cpf +
                " <br/>" +
                "<strong>Data de nascimento:</strong> " +
                datanasc +
                " <br/>" +
                "<br/>" +
                "Estas informações estão corretas?",
              icon: "question",
              title: "Aviso",
              confirmButtonColor: "#00518b",
              customClass: {
                confirmButton: "x-follow-order__filter-btn",
              },
              showCancelButton: true,
              cancelButtonColor: "#d33",
              confirmButtonText: "Sim",
              cancelButtonText: "Não",
            }).then(result => {
              if (result.value) {
                this.context.showLoading();
                callback();
              }
            });
          } else {
            document.getElementById("main-body").style.pointerEvents = "";

            this.setState({
              opacity: "signup-right",
              loading: false,
              disabled: false,
            });

            //fechando loading
            this.context.hideLoading();

            Alert.fire({
              html: "O sistema está passando por uma instabilidade tente novamente mais tarde!",
              icon: "error",
              title: "Aviso",
              confirmButtonColor: "#00518b",
              customClass: {
                confirmButton: "x-follow-order__filter-btn",
              },
              confirmButtonText: "Ok",
            }).then(result => {
              if (result.value) {
              }
            });
          }
        });
      } else {
        document.getElementById("main-body").style.pointerEvents = "";

        this.setState({
          opacity: "signup-right",
          loading: false,
          disabled: false,
        });

        //fechando loading
        this.context.hideLoading();

        Alert.fire({
          html: "O sistema está passando por uma instabilidade tente novamente mais tarde!",
          icon: "error",
          title: "Aviso",
          confirmButtonColor: "#00518b",
          customClass: {
            confirmButton: "x-follow-order__filter-btn",
          },
          confirmButtonText: "Ok",
        }).then(result => {
          if (result.value) {
          }
        });
      }
    });
  }

  validarCpfDataNascimentoOut(cpftrue, callback) {
    // usado apenas na API de consulta de CPF, deve estar no formato 00/00/0000
    var datanasc = this.state.datanasc;

    const requestOptions2 = {
      method: "GET",
    };
    fetch(process.env.REACT_APP_HUBDEV_WS + "v2/cpf/?cpf=" + cpftrue + "&data=" + datanasc + "&token=75416675IolNqEyuKU136162520", requestOptions2)
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.return === "OK") {
          this.setState({ nomePF: json.result.nome_da_pf });
          callback();
        } else {
          document.getElementById("main-body").style.pointerEvents = "";

          this.setState({
            opacity: "signup-right",
            loading: false,
            disabled: false,
          });

          //fechando loading
          this.context.hideLoading();

          Alert.fire({
            html:
              "<strong>CPF:</strong> " +
              this.state.cpf +
              " <br/>" +
              "<strong>Data de nascimento:</strong> " +
              datanasc +
              " <br/>" +
              "<br/>" +
              "Estas informações estão corretas?",
            icon: "question",
            title: "Aviso",
            confirmButtonColor: "#00518b",
            customClass: {
              confirmButton: "x-follow-order__filter-btn",
            },
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
          }).then(result => {
            if (result.value) {
              this.context.showLoading();
              callback();
            }
          });
        }
      });
  }

  cadastrar(e) {
    e.preventDefault();
    const { onlyS9 } = this.props;

    var cpftrue = this.state.cpf
      .replace(/[^0-9]/g, "")
      .replace("/", "")
      .replace("-", "");

    //quando os termos são aceitos, realiza as validações do cadastro
    if (this.state.passwordIsConfirm && this.state.emailIsConfirm) {
      if (this.validacaoCampos() && this.validacaoAceiteTermo()) {
        document.getElementById("main-body").style.pointerEvents = "none";
        this.setState({
          opacity: "signup-right signup-main-opacity",
          loading: true,
          disabled: true,
        });

        this.handleClickSignup();

        //abrindo loading
        this.context.showLoading("");

        // if (!onlyS9 || cpftrue) {
        //   this.validarCpfDataNascimento(cpftrue, () => {
        //     this.webserviceCreate();
        //   });
        // } else {
        this.webserviceCreate();
        // }
      }
    }
  }

  setBorder(e) {
    const { id } = e.target;
    document.getElementById(id).style.borderColor = "red";
  }

  handleCheckbox(e) {
    if (!this.state.terms_value) {
      this.setState({
        terms: true,
        terms_value: e.target.checked,
      });
    } else {
      this.setState({
        terms_value: e.target.unchecked,
      });
    }
  }

  onClickCloseTerms() {
    this.setState({
      terms: false,
      privacy: true,
    });
  }

  onClickClosePrivacy() {
    this.setState({
      privacy: false,
    });
  }

  handleClickSignup() {
    ReactGA.event({
      category: "button",
      action: "click",
      label: "cadastrar",
    });
  }

  handleClickOk() {
    ReactGA.event({
      category: "button",
      action: "click",
      label: "ok-final",
    });
  }

  render() {
    const { name, surname, email, email1, datanasc, cpf, rg, telephone, cellphone, redirectL, password, confirmPassword } = this.state;

    const { onlyS9 } = this.props;

    if (redirectL) {
      window.location.href = "/";
    }

    return (
      <div className={this.state.opacity}>
        <div className="signup-right__next-account" id="secondForm">
          <h2 className="signup-right__next-account--title">
            Crie sua conta vinculada à <span>{this.props.otica}</span>
          </h2>
          <p className="signup-right__next-account--warning">Campos obrigatórios</p>

          <form className="signup-right__next-account--form" autoComplete="off" onSubmit={this.cadastrar} novalidate>
            <div className="row">
              <div className="col-12 col-md-6">
                <Input
                  name="name"
                  id="name"
                  type="text"
                  value={name}
                  label="Nome*"
                  aria="nome"
                  autocomplete="off"
                  defaultValue=""
                  required={true}
                  width="100%"
                  onInvalid={this.setBorder}
                  onChange={this.handleChange}
                  onInput={this.handleChange}
                />
              </div>
              <div className="col-12 col-md-6">
                <Input
                  name="surname"
                  id="surname"
                  title="Preencher este campo"
                  type="text"
                  value={surname}
                  label="Sobrenome*"
                  aria="Sobrenome"
                  autocomplete="off"
                  required={true}
                  width="100%"
                  onInvalid={this.setBorder}
                  onChange={this.handleChange}
                  onInput={this.handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <Input
                  name="email"
                  id="email"
                  title="ex:. email@email.com"
                  pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$"
                  type="email"
                  value={email}
                  label="E-mail*"
                  aria="E-mail"
                  autocomplete="off"
                  required={true}
                  width="100%"
                  onInvalid={this.setBorder}
                  onChange={this.handleChange}
                  onInput={this.handleChange}
                />
              </div>
              <div className="col-12 col-md-6">
                <small className="signup-right__next-account--reminder">
                  Este e-mail fará sua autenticação no Essilor Conecta. Você deve informar um email válido e que apenas você acesse.
                </small>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <Input
                  name="email1"
                  id="email-confirmation"
                  title="ex:. email@email.com"
                  pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$"
                  type="email"
                  onPaste={this.onPaste}
                  value={email1}
                  label="Confirmar e-mail*"
                  aria="Confirmar e-mail"
                  autocomplete="off"
                  required={true}
                  width="100%"
                  onInvalid={this.setBorder}
                  onChange={this.handleChange}
                  onInput={this.handleChangeConfirmEmail}
                />
                {!this.state.emailIsConfirm && <small style={{ color: "red", fontSize: 12 }}>Os e-mails não conferem.</small>}
              </div>
              <div className="col-12 col-md-6">
                <Input
                  title="ex: 01/01/2002"
                  type="text"
                  name="datanasc"
                  id="datanasc"
                  mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                  value={datanasc}
                  label="Data de nascimento*"
                  aria="Data de nascimento"
                  autocomplete="off"
                  required={true}
                  width="100%"
                  onInvalid={this.setBorder}
                  onChange={this.handleChange}
                  onInput={this.handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <Input
                  title="ex:. 000.000.000-00"
                  type="text"
                  name="cpf"
                  id="cpf"
                  guide={false}
                  mask={[/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
                  value={cpf}
                  label={onlyS9 ? "CPF" : "CPF*"}
                  aria="CPF"
                  autocomplete="off"
                  required={onlyS9 ? false : true}
                  width="100%"
                  onInvalid={this.setBorder}
                  onChange={this.handleChange}
                  onInput={this.handleChange}
                  data-contact="cpf"
                  ref={ref => (this.myRef = ref)}
                />
              </div>
              <div className="col-12 col-md-6">
                <Input
                  name="rg"
                  id="rg"
                  type="text"
                  value={rg}
                  label={onlyS9 ? "RG" : "RG*"}
                  aria="RG"
                  autocomplete="off"
                  required={onlyS9 ? false : true}
                  width="100%"
                  onInvalid={this.setBorder}
                  onChange={this.handleChange}
                  onInput={this.handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <Input
                  name="telephone"
                  id="telephone"
                  data-contact="telefone"
                  title="ex:. (11) 5555-5555"
                  type="text"
                  value={telephone}
                  label="Telefone"
                  aria="Telefone"
                  autocomplete="off"
                  required={false}
                  width="100%"
                  onInvalid={this.setBorder}
                  onChange={this.handleChange}
                  onInput={this.handleChange}
                  guide={false}
                  mask={["(", /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                />
              </div>
              <div className="col-12 col-md-6">
                <Input
                  name="cellphone"
                  id="cellphone-number"
                  data-contact="celular"
                  title="ex:. (11) 95555-5555"
                  type="text"
                  value={cellphone}
                  label="Celular*"
                  aria="Celular"
                  autocomplete="off"
                  required={true}
                  width="100%"
                  onInvalid={this.setBorder}
                  onChange={this.handleChange}
                  onInput={this.handleChange}
                  guide={false}
                  mask={["(", /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <Password
                  label="Senha*"
                  width="100%"
                  name="password"
                  id="password"
                  required={true}
                  type="password"
                  value={password}
                  onInvalid={this.setBorder}
                  onChange={this.handleChangeConfirmPassword}
                  onInput={this.handleChangeConfirmPassword}
                />
              </div>
              <div className="col-12 col-md-6">
                <Password
                  label="Confirmação de Senha*"
                  width="100%"
                  name="confirmPassword"
                  id="confirmPassword"
                  required={true}
                  type="password"
                  value={confirmPassword}
                  onInvalid={this.setBorder}
                  onChange={this.handleChangeConfirmPassword}
                  onInput={this.handleChangeConfirmPassword}
                />
                {!this.state.passwordIsConfirm && <small style={{ color: "red", fontSize: 12 }}>As senhas não conferem.</small>}
              </div>
            </div>

            <div className="signup-right__next-account--checkbox">
              <input
                className="signup-right__next-account--input-check js--input-terms"
                name="terms"
                id="terms"
                type="checkbox"
                value={this.state.terms_value}
                onClick={this.handleCheckbox}
              />
              <label className="signup-right__next-account--label-check js--input-terms" htmlFor="terms">
                Aceito os Termos e Condições de Uso e a Política de Privacidade
              </label>

              <input
                className="signup-right__next-account--input-check js--input-newsletter"
                name="newsletter"
                id="newsletter"
                type="checkbox"
                value={this.state.newsletter}
                onClick={this.handleCheck}
              />
              <label className="signup-right__next-account--label-check js--input-newsletter" htmlFor="newsletter">
                Aceito receber comunicações por e-mail e celular do grupo Essilor e suas marcas
              </label>
            </div>
            <div class="text-center">
              <button className="signup-right__next-account--button is--blue" type="submit" disabled={this.state.disabled}>
                Cadastrar
              </button>
            </div>
          </form>
        </div>
        {this.state.terms && <Terms onClick={this.onClickCloseTerms} />}
        {this.state.privacy && <Privacy onClick={this.onClickClosePrivacy} />}
      </div>
    );
  }
}

export default SignupBody;
