import React, { Component } from "react";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import "./roles-management-accordion.css";
import RolesManagementTableAccordionItem from "./roles-management-accordion-item";
import ReactPaginate from "react-paginate";

class RolesManagementTableAccordion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentEventKey: 0,
      data_tabela: [],
      pageCount: Math.ceil(props.listaUsuarios.length / 10),
      offset: 0,
    };
    this.paginateData = this.paginateData.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  handleChange = obj => {
    this.setState(obj);
  };
  componentDidUpdate(prevProps) {
    if ((prevProps.listaUsuarios.length === 0) && (this.props.listaUsuarios.length !==0)){
      this.state.data_tabela = this.props.listaUsuarios;
      this.state.pageCount = Math.ceil(this.props.listaUsuarios.length / 10);
      this.state.offset = 0;
      this.paginateData();
    }
  }
  paginateData() {
    const dataPagination = this.props.listaUsuarios.slice(this.state.offset, this.state.offset + 10);
    this.setState({ data_tabela: dataPagination });
  }

  handlePageClick = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * 10;

    this.setState({ offset: offset}, this.paginateData);
  };
  render() {
    const { alterarPerfilUsuario, removerUsuario, listaPapeis, inativarUsuario, isInativar } = this.props;

    return (
      <>
        {this.state.data_tabela.length > 0 && (
          
            
            <Accordion activeKey={this.state.currentEventKey}>
            
              {this.state.data_tabela.length > 0 && (<div className="pagination-container"><div>
              <ReactPaginate
                previousLabel={"Anterior"}
                nextLabel={"Próximo"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
              </div>
                <Table className="table-striped">
                  <thead className="text-center">
                    <tr>
                      <th style={{ color: "#fff" }}>Usuários</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data_tabela.map((item, index) => {
                      return (
                        <RolesManagementTableAccordionItem
                          id={item.id}
                          nome={item.nome + " " + item.sobrenome}
                          perfil={item.id_perfil}
                          email={item.email}
                          cnpj={item.cnpj}
                          cpf={item.cpf}
                          inativo={item.inativo}
                          userLogado={item.userLogado}
                          removerUsuario={removerUsuario}
                          inativarUsuario={inativarUsuario}
                          alterarPerfilUsuario={alterarPerfilUsuario}
                          current={this.state}
                          handleAccordionChange={this.handleChange}
                          eventKey={parseInt(index + 1)}
                          listaPapeis={listaPapeis}
                          isInativar={isInativar}
                          erro={item.erro}
                        />
                      );
                    })}
                  </tbody>
                </Table>
              </div>)}
            </Accordion>
          
        )}
      </>
    );
  }
}

export default RolesManagementTableAccordion;
