async function createLogsOEC(usuario, origem, descricao, tipoDeEvento) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ usuario: usuario, origem: origem, descricao: descricao, tipoDeEvento: tipoDeEvento}),
  };
  try {
    return fetch(process.env.REACT_APP_LABS_API + "Logs/post_logs.php", requestOptions);
  } catch (error) {
    console.error("Erro ao buscar os dados: ", error);
  }
}

export { createLogsOEC };
