import React, { Component } from "react";
import "./index.css";

import AppContext from "../../appContext";
import Table from "../../components/table";
class CanaisAtendimento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      tipo: [],
    };
    CanaisAtendimento.contextType = AppContext;
  }

  componentDidMount() {
    this.context.showLoading("Carregando...");

    const requestOptions = {
      method: "GET",
    };
    fetch(process.env.REACT_APP_LABS_API + "OEC/canais_atendimento/get_tipos_contato_atendimento.php", requestOptions)
      .then(response => {
        this.context.hideLoading();
        return response.json();
      })
      .then(data => {
        this.setState({ tipo: data });
      })
      .catch(error => {
        console.error("Erro ao buscar os dados:", error);
      });

    fetch(process.env.REACT_APP_LABS_API + "OEC/canais_atendimento/get_canais_atendimento.php", requestOptions)
      .then(response => {
        this.context.hideLoading();
        return response.json();
      })
      .then(data => {
        this.setState({ list: data });
      })
      .catch(error => {
        console.error("Erro ao buscar os dados:", error);
      });
  }

  render() {
    return (
      <>
        <h1 className="suporte_title">Canais de Comunicação</h1>
       <div className="container-canais-comunicacao">
        <div>
          <div className="container-box-legend">
            {this.state.tipo.map((item, index) => {
              return (
                <div className="card-box-legend">
                  <spam className="box-legend"> {item.nome}</spam>
                  <div style={{ background: item.cor }} className="container-color-box-legend"></div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="container-dotted-line">
          <spam class="dotted-line"></spam>
        </div>

        <Table list={this.state.list} />
        </div>
      </>
    );
  }
}

export default CanaisAtendimento;
