import React, { Component, Fragment } from "react";

//Import CSS
import "./App.css";
import "./global.css";
//Import internal components
import { createBrowserHistory } from "history";
import AppContext from "./appContext";
// import { RouteWithSubRoutes } from "./essilor-routes";

//Import external components
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { Switch, Route, Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

//Import route components
import Index from "./index/index";
import NotFound from "./main/not-found";
import Signup from "./signup/signup";
import Header from "./main/header/header";
import Footer from "./main/footer";
import Home from "./home/home";
import Profile from "./profile/profile";
import Tracking from "./tracking/tracking";
import Reset from "./password-reset/reset";
import NewPassword from "./password-reset/newPassword";
import Faq from "./faq/faq";
import WOHome from "./web-ordering/home/web-ordering-home";
import WOOrder from "./web-ordering/order/order";
import Confirmed from "./web-ordering/order-confirmed/confirmed";
import EssilorLoading from "./main/essilor-loader";
import MarketingCenter from "./media-center/marketing-center";
import RolesManagement from "./roles-management/roles-management";
//remoção do crm
// import CRM from "./crm/crm";
// import CRMDashboard from "./crm/crm-dashboard/crm-dashboard";
// import CRMManageCustomer from "./crm/crm-manage-customers/crm-manage-customers";
// import CRMPageConfig from "./crm/crm-page-config/crm-page-config";
import MediaUpload from "./media-upload/media-upload";
import Documentation from "./components/doc/documentation";
import Teste from "./test/teste";
import DashboardLab from "./dashboard-lab/dashboard-lab";
import Lab from "./lab/lab";
import Maintenance from "./main/maintenance/maintenance";
import AlertCookie from "./main/alert-cookie/alert-cookie";

//atacado
import PedidoAtacado from "./pedido-atacado/pedido-atacado";
import ConfirmedAtacado from "./pedido-atacado/pedido-atacado-confirmed/confirmed";
import AtacadoLab from "./pedido-atacado/home/atacado-lab-home";

//financeiro
import Financeiro from "./financeiro/financeiro";

// suporte
import Suporte from "./suporte/suporte";

// Financial Center
import PrecosEServicos from "./precos-e-servicos/precos-e-servicos";

//Import image
import useravatar from "./main/header/img/img-user.png";
import Otica from "./otica-management/otica";
import { getMultiContas } from "./service/multi-contas/multi-contas-service";
import ProductManagement from "./product-management/product-management";
import ModuloPapel from "./roles-management/modulo-papel/modulo-papel";

const history = createBrowserHistory();
history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const advancedMatching = { em: "email@email.com" };
// optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};

ReactPixel.init("701527900459527", advancedMatching, options);
ReactPixel.pageView();

class App extends Component {
  constructor(props) {
    super(props);
    var maintenance = process.env.REACT_APP_MAINTENANCE;
    this.state = {
      maintenance: maintenance,
    };
    this.reloadMultiContas = this.reloadMultiContas.bind(this);
    if (maintenance !== "true") this.reloadJwt();
  }

  reloadJwt() {
    var token = localStorage.getItem("token");
    var session_token = localStorage.getItem("session_token");

    if (token && token !== "undefined") {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(""),
      );

      var jwt = JSON.parse(jsonPayload);

      var base64Url_session_token = session_token.split(".")[1];
      var base64_session_token = base64Url_session_token.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload_session_token = decodeURIComponent(
        atob(base64_session_token)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(""),
      );

      var jwt_session_token = JSON.parse(jsonPayload_session_token);

      this.getProfileImg(session_token, jwt_session_token.userId);

      this.getApelidoConta(jwt.conta);

      this.state = {
        isReady: false,
        loading: false,
        message: null,
        load_img: "",
        user_id: jwt_session_token.userId,
        especialista: jwt_session_token.especialista,
        especialistaPremiere: jwt_session_token.premiere,
        userSession: {
          nome: jwt.usuario.nome,
          sobrenome: jwt.usuario.sobrenome,
          cpf: jwt.usuario.cpf,
          email: jwt.usuario.email,
          papel: jwt.papel,
          modulos: jwt.modulos,
          laboratorios: jwt.laboratorios,
          conta: jwt.conta,
          papelCategoria: jwt.papelCategoria,
          redeOticas: jwt.redeOticas,
          precosEServicos: jwt.usuario.FinancialCenter_c,
          precosEServicosFolders: jwt.usuario.FinancialCenterFolders_c,
        },
        lista_multi_contas: null,
      };
      // this.setCookie(token)
    } else {
      this.state = {
        isReady: true,
        loading: false,
        message: null,
        load_img: "",
        especialista: false,
        especialistaPremiere: "",
        userSession: null,
      };
    }
  }

  getProfileImg(session_token, user_id) {
    const requestOptions = {
      headers: new Headers({
        Authorization: "Bearer " + session_token,
      }),
    };

    fetch(process.env.REACT_APP_LABS_API + `upload_avatar.php?profileUser=${user_id}`, requestOptions)
      .then(response => response.json())
      .then(json => {
        if (json.success) {
          this.setState({
            avatar: process.env.REACT_APP_LABS_ROOT + `${json.path}`,
          });
        } else {
          this.setState({
            avatar: useravatar,
          });
        }
      });
  }

  reloadMultiContas() {
    this.getApelidoConta(this.state.userSession.conta);
  }
  getApelidoConta = async conta => {

    await getMultiContas(conta).then(response => {
      if (response.items) {
        this.setState({ lista_multi_contas: response.items });
        response.items.map((item, index) => {
          if (item.conta.cnpj === conta) {
            this.setState({
              apelidoConta: item.conta.apelido ? item.conta.apelido : item.conta.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
            });
          }
        });
      } else {
        this.setState({ lista_multi_contas: [] });
      }
    });
  };

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }

  showSnack = (snackMessage, snackSeverity) => {
    this.setState({
      snackMessage,
      snackSeverity,
      openSnack: true,
    });
  };

  hideSnack = () => {
    this.setState({
      openSnack: false,
    });
  };

  reloadApelido = apelido =>
    this.setState({
      apelidoConta: apelido,
    });

  showLoading = (message, load_img) =>
    this.setState({
      loading: true,
      load_img: "",
      message,
    });

  hideLoading = () => this.setState({ loading: false });

  render() {
    const { showLoading, hideLoading, reloadJwt, showSnack, hideSnack, reloadApelido, emailLoginSwitch } = this;

    const value = {
      ...this.state,
      showLoading,
      hideLoading,
      reloadJwt,
      showSnack,
      hideSnack,
      reloadApelido,
      emailLoginSwitch,
    };

    //remoção do crm
    // const routes = [
    //   {
    //     path: "/crm",
    //     component: CRM,
    //     routes: [
    //       {
    //         path: "/crm/dash",
    //         component: CRMDashboard,
    //       },
    //       {
    //         path: "/crm/manage",
    //         component: CRMManageCustomer,
    //       },
    //       {
    //         path: "/crm/config",
    //         component: CRMPageConfig,
    //       },
    //     ],
    //   },
    // ];

    return (
      <div>
        <AppContext.Provider value={value}>
          <AppContext.Consumer>
            {({
              showLoading,
              hideLoading,
              reloadJwt,
              reloadApelido,
              emailLoginSwitch,
              loading,
              message,
              load_img,
              snackMessage,
              snackSeverity,
              openSnack,
              userSession,
              emailLoginPage,
            }) => (
              <Router history={history}>
                {this.state.maintenance === "true" && <Maintenance />}
                {this.state.maintenance !== "true" && (
                  <Fragment>
                    <Switch>
                      <Route path="/" exact={true} component={Index} />
                      <Route path="/signup" exact={true} component={Signup} />
                      <Route path="/resetpassword" component={Reset} />
                      <Route path="/newPassword" component={NewPassword} />
                      <Route path="/faq" component={Faq} />
                      <Route path="/mediaupload" exact={true} component={MediaUpload} />
                      <div>
                        <Header lista_multi_contas={this.state.lista_multi_contas} />
                        <Switch>
                          <Route path="/home" component={Home} />
                          <Route path="/profile"  render={(props) => <Profile {...props} reloadMultiContas={this.reloadMultiContas} />} />
                          <Route path="/tracking" exact={true} component={Tracking} />
                          <Route path="/roles" component={RolesManagement} />
                          <Route path="/modulopapel" component={ModuloPapel} />
                          <Route path="/web-ordering" component={WOHome} />
                          <Route path="/order" component={WOOrder} />
                          <Route path="/confirmed" component={Confirmed} />
                          <Route path="/labs" component={Lab} />
                          <Route path="/marketingcenter" component={MarketingCenter} />
                          <Route path="/dashlab" component={DashboardLab} />
                          <Route path="/gestaoprodutos" component={ProductManagement} />
                          <Route path="/doc" component={Documentation} />
                          <Route path="/empresa" component={Otica} />
                          <Route path="/teste" component={Teste} />
                          <Route path="/atacado-labs" component={AtacadoLab} />
                          <Route path="/atacado" component={PedidoAtacado} />
                          <Route path="/confirmed-atacado" component={ConfirmedAtacado} />
                          <Route path="/financeiro" component={Financeiro} />
                          <Route path="/suporte" component={Suporte} />
                          <Route path="/precoseservicos" component={PrecosEServicos} />
                          <Route path="*" component={NotFound} />
                          {/* {routes.map((route, i) => (
                            <RouteWithSubRoutes key={i} {...route} />
                          ))} */}
                        </Switch>

                        <Footer />
                      </div>
                    </Switch>
                    {/* Loader */}
                    <EssilorLoading {...{ loading, message, load_img }} />
                    {/* SNACKBAR */}
                    <Snackbar open={openSnack} autoHideDuration={5000} onClose={hideSnack}>
                      <Alert elevation={6} variant="filled" onClose={hideSnack} severity={snackSeverity}>
                        {snackMessage}
                      </Alert>
                    </Snackbar>
                    <AlertCookie />
                  </Fragment>
                )}
              </Router>
            )}
          </AppContext.Consumer>
        </AppContext.Provider>
      </div>
    );
  }
}

export default App;
