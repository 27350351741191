import React, { Component } from "react";

//Import CSS
import "./table.css";
import AppContext from "../../appContext";
import PDFOrder from "../../web-ordering/pdf-order/pdf-order";
import PDFOrderAtacado from "../../pedido-atacado/pdf-order/pdf-order";

//Import internal components
import { Button } from "react-bootstrap";
import { FileEarmarkTextFill } from "react-bootstrap-icons";

//Import external components

//Import images

export class TrackingTableLineDetailCard extends Component {
  constructor(props) {
    super(props);

    TrackingTableLineDetailCard.contextType = AppContext;
  }

  render() {
    return (
      <div className="card-pedido">
        <h3>Detalhes do pedido</h3>

        {/* <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="32.000000pt" height="16.000000pt" viewBox="0 0 1280.000000 640.000000" preserveAspectRatio="xMidYMid meet">
                    <metadata>
                        Created by potrace 1.15, written by Peter Selinger 2001-2017
                    </metadata>
                    <g transform="translate(0.000000,640.000000) scale(0.100,-0.100)" fill="#00518B" stroke="none">
                        <path d="M9960 5300 c-437 -22 -803 -74 -1665 -236 -1084 -203 -1397 -240 -1980 -231 -450 7 -643 32 -1290 167 -432 90 -507 105 -650 129 -265 45 -907 123 -1245 153 -221 19 -816 16 -1015 -5 -342 -37 -631 -86 -1275 -217 -217 -45 -491 -99 -609 -120 -118 -22 -216 -41 -217 -43 -2 -2 -6 -99 -10 -217 -7 -242 6 -359 51 -453 14 -31 69 -101 132 -170 244 -265 284 -327 333 -508 109 -395 195 -665 308 -959 130 -340 283 -670 358 -776 257 -360 822 -612 1564 -696 72 -8 228 -12 415 -12 517 2 848 55 1160 189 516 222 912 629 1294 1333 93 172 131 267 197 494 83 292 111 373 169 493 64 132 106 184 177 216 51 23 61 24 266 22 256 -2 295 -11 371 -79 120 -108 184 -230 296 -568 167 -504 330 -837 560 -1144 92 -123 257 -298 362 -384 554 -453 1471 -661 2303 -522 689 115 1242 461 1537 961 116 198 216 471 332 913 199 756 275 919 495 1064 58 38 87 64 96 86 13 32 14 49 13 325 0 83 0 182 1 222 0 58 -3 77 -20 100 -31 41 -90 71 -169 83 -165 27 -338 65 -605 135 -370 97 -454 113 -885 174 -580 82 -815 98 -1155 81z m-6371 -280 c261 -13 423 -32 641 -76 865 -173 1309 -446 1410 -866 22 -93 27 -277 10 -388 -42 -270 -153 -575 -367 -1005 -148 -296 -260 -461 -437 -644 -377 -387 -864 -606 -1476 -661 -386 -36 -984 32 -1286 146 -365 137 -646 396 -815 751 -132 276 -291 849 -345 1243 -26 184 -26 586 -1 693 31 130 87 228 192 332 200 200 494 312 1005 384 300 42 682 78 965 90 264 12 281 12 504 1z m6191 0 c522 -23 1096 -91 1385 -165 447 -115 694 -298 789 -585 73 -221 51 -669 -55 -1142 -56 -250 -174 -628 -254 -809 -167 -384 -473 -667 -866 -804 -114 -40 -373 -95 -569 -120 -171 -23 -512 -31 -680 -16 -700 62 -1252 346 -1637 841 -131 169 -309 495 -452 830 -73 171 -155 422 -183 560 -30 150 -32 368 -4 475 106 404 507 660 1314 839 252 56 447 82 714 95 230 12 245 12 498 1z"/>
                    </g>
                </svg> */}

        <p className="tracking-detail-card-title">Nome do consumidor</p>
        <p className="tracking-detail-card-p">
          {this.props.nome_consumidor !== "" && typeof this.props.nome_consumidor !== "undefined" ? this.props.nome_consumidor : "Não informado"}
        </p>
        <p className="tracking-detail-card-title mobile tablet">Pedido ótica</p>
        <p className="tracking-detail-card-p mobile tablet">{this.props.pedido_otica}</p>
        <p className="tracking-detail-card-title mobile">Data prevista</p>
        <p className="tracking-detail-card-p mobile">{this.props.data_prevista}</p>
        <p className="tracking-detail-card-title">Produto</p>
        {this.props.tipoPedido !== "ATACADO" ? (
          <p className="tracking-detail-card-p">
            {this.props.produto_direito !== "" && typeof this.props.produto_direito !== "undefined" ? this.props.produto_direito : this.props.produto_esquerdo}
          </p>
        ) : (
          <p className="tracking-detail-card-p">Vários - Pedido Atacado</p>
        )}
        <p className="tracking-detail-card-title">Data de faturamento</p>
        <p className="tracking-detail-card-p">
          {this.props.data_faturamento !== "" && typeof this.props.data_faturamento !== "undefined"
            ? this.props.data_faturamento
            : "O pedido ainda não foi faturado"}
        </p>
        <p className="tracking-detail-card-title">Número da nota fiscal</p>
        <p className="tracking-detail-card-p">
          {this.props.nota_fiscal == "" || typeof this.props.nota_fiscal == "undefined" || this.props.nota_fiscal == "0"
            ? "O pedido ainda não possui nota fiscal"
            : this.props.nota_fiscal}
        </p>

        {this.props.detailOrder && this.props.tipoPedido !== "ATACADO" && (
          <div className="text-end mt-3">
            <PDFOrder
              icon={true}
              prazo={this.props.data_prevista}
              laboratorio={this.props.laboratorio_cnpj}
              otica={this.props.otica_cnpj}
              pedido_laboratotio={this.props.pedido_laboratotio}
              laboratorio_nome={this.props.laboratorio_nome}
              local={"tracking"}
            />
          </div>
        )}
        {this.props.detailOrder && this.props.tipoPedido === "ATACADO" && (
          <div className="text-end mt-3">
            <PDFOrderAtacado
              icon={true}
              prazo={this.props.data_prevista}
              laboratorio={this.props.laboratorio_cnpj}
              otica={this.props.otica_cnpj}
              pedido_laboratotio={this.props.pedido_laboratotio}
              laboratorio_nome={this.props.laboratorio_nome}
              local={"tracking"}
            />
          </div>
        )}
        {this.props.nota_fiscal !== "" &&
        typeof this.props.nota_fiscal !== "undefined" &&
        this.props.nota_fiscal !== "0" &&
        process.env.REACT_APP_GA_ID !== "prod" ? (
          <div className="text-end">
            <Button className={"pdf-button"} variant="link">
              <FileEarmarkTextFill size={22} className="me-2" />
              Baixar Nota Fiscal
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default TrackingTableLineDetailCard;
