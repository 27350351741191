import React, { Component } from "react";

import "./suporte.css";

import ConsultarCupom from "./consultar-cupom/consultar-cupom";
import InfoCliente from "./info-cliente/info-cliente";
import LinksUteis from "./links-uteis/links-uteis";
import CanaisAtendimento from "./canais-atendimento";
import MenuLateral from "../components/menu/menu-lateral/index.js";

import AppContext from "../appContext";
import { Redirect } from "react-router-dom";

import Back from "../main/back";

class Suporte extends Component {
  constructor() {
    super();

    this.state = {
      selected_option: 2,
      redirectL: false,
    };

    if (localStorage.getItem("token") !== null) {
      Suporte.contextType = AppContext;

      let role = false;

      role = Suporte.contextType._currentValue.userSession.modulos.find(element => element === "Suporte");
      if (!role) {
        this.state = { redirect: true };
      }
    } else {
      this.state = { redirectL: true };
    }
  }

  handleChangeOption(option) {
    if (option !== this.state.selected_option) {
      this.setState({
        selected_option: option,
      });
    }
  }

  render() {
    const { redirectL } = this.state;

    if (this.state.redirect) {
      return <Redirect to="/home" />;
    }

    if (redirectL) {
      return <Redirect to="/" />;
    }
    const menuOptions = [
      {
        id: 1,
        label: "Consultar Cupom",
        component: <ConsultarCupom />,
      },
      {
        id: 2,
        label: "Informações do Cliente",
        component: <InfoCliente />,
      },
      ,
      {
        id: 3,
        label: "Links Úteis",
        component: <LinksUteis />,
      },
      ,
      {
        id: 4,
        label: "Canais de Atendimento",
        component: <CanaisAtendimento  />,
      },
    ];
    return (
      <main className="suporte__main">
        <div className="back">
          <Back />
        </div>
        <div className="suporte__container">
          <MenuLateral menuOptions={menuOptions} />
        </div>
      </main>
    );
  }
}

export default Suporte;
