import React, { Component } from "react";
import Accordion from "react-bootstrap/Accordion";
import "./roles-management-accordion.css";
import AppContext from "../../appContext";
import { Modal, Button } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { QuestionCircleFill } from "react-bootstrap-icons";
import { XCircleFill, PersonDashFill } from "react-bootstrap-icons";

class RolesManagementTableAccordionItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listaPapeis: [],
    };

    RolesManagementTableAccordionItem.contextType = AppContext;
  }
  removerAcessoUsuario = () => {
    const { removerUsuario, id, cnpj } = this.props;

    removerUsuario(id, cnpj);
  };

  alterarUsuario = e => {
    const { name, value, selectedIndex } = e.target;
    const { alterarPerfilUsuario, id, cnpj } = this.props;

    alterarPerfilUsuario(id, value, e.target.options[selectedIndex].text, cnpj);
  };

  inativarUsuario = () => {
    const { id, email, inativarUsuario } = this.props;

    inativarUsuario(id, email);
  };

  setShow = (show, showButton) => {
    this.setState({ showModal: show, showButton: showButton });
  };

  render() {
    const { current, handleAccordionChange, eventKey, id, nome, perfil, listaPapeis, email, isInativar, cpf, inativo, cnpj, userLogado, erro } = this.props;

    var cpfMask = cpf ? cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : "";
    var cnpjMask = cnpj ? cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : "";

    var desvinculado = listaPapeis.some(elem => {
      return elem.id === perfil;
    });
    var perfilAux = perfil;
    var desvinculadoInativo = inativo;
    perfilAux = desvinculado ? perfil : "default";

    if (!desvinculado && !cnpj) {
      perfilAux = "default";
      desvinculadoInativo = !desvinculado;
    }

    function click(e, item) {
      e.preventDefault();
      e.target.classList.toggle("active");
      if (e.target.classList.contains("active")) {
        handleAccordionChange({
          currentEventKey: e.target.dataset.currenteventkey,
        });
      } else {
        handleAccordionChange({ currentEventKey: 0 });
      }
    }

    function CustomToggle(props) {
      return (
        <div className="text-end col-6">
          <p
            className={`pointer accordion-btn-small ${parseInt(current.currentEventKey) === parseInt(props.eventKey) ? "active" : ""}`}
            data-currentEventKey={props.eventKey}
            onClick={click}>
            {props.children}
          </p>
        </div>
      );
    }

    const handleClose = () => this.setShow(false, false);
    const handleShow = () => this.setShow(true, true);

    return (
      <>
        <tr>
          <td>
            <div className="row">
              <div className="text-left col-6">
                <p className="accordion-title">
                  <strong>{nome}</strong>
                </p>
              </div>
              <CustomToggle eventKey={eventKey.toString()}>Ver Detalhes</CustomToggle>
            </div>
            <Accordion.Collapse eventKey={eventKey.toString()}>
              <div>
                <div>
                  <p className="accordion-title">
                    E-mail: {email} <br />
                    CPF: {cpfMask} <br />
                    CNPJ: {cnpjMask} <br />
                  </p>
                </div>

                {inativo || userLogado ? (
                  <div className="row mt-3" class="text-center align-middle" style={{ color: "gray" }}>
                    <strong>{erro}</strong>
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={<Tooltip id="button-tooltip">Usuário não pode ser editado.</Tooltip>}>
                      <span color="red" className="ms-2" style={{ cursor: "pointer", color: "#0394db" }}>
                        <QuestionCircleFill />
                      </span>
                    </OverlayTrigger>
                  </div>
                ) : (
                  <>
                    <small className="accordion-title-small">
                      Perfil de acesso
                      {(this.context.userSession.papel === "Proprietário de Óptica" || this.context.userSession.papel === "Gerente de Óptica") && (
                        <span variant="primary" onClick={handleShow} className="ms-2" style={{ cursor: "pointer", color: "#0394db" }}>
                          <QuestionCircleFill />
                        </span>
                      )}
                    </small>
                    <div className="roles-box">
                      <span className="roles-select-arrow"></span>
                      <select
                        title="Clique nesse campo"
                        name="perfil"
                        id="perfil"
                        value={perfilAux}
                        onChange={this.alterarUsuario}
                        className="roles-select"
                        disabled={inativo}>
                        <option key="0" value="default" disabled selected className="roles-select-option">
                          Selecione
                        </option>
                        {listaPapeis.map((item, index) => (
                          <option value={item.id} className="roles-select-option" disabled={item.disabled}>
                            {item.nome}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="row mt-3">
                      <div className="text-left col-6">
                        <small className="accordion-title-small">
                          Desvincular empresa
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id="button-tooltip">Remove o usuário da empresa. O usuário fica sem CNPJ até informar uma nova empresa.</Tooltip>
                            }>
                            <span variant="primary" className="ms-2" style={{ cursor: "pointer", color: "#0394db" }}>
                              <QuestionCircleFill />
                            </span>
                          </OverlayTrigger>
                        </small>
                        <Button variant="link" className="accordion-btn-excluir" onClick={this.removerAcessoUsuario} disabled={desvinculadoInativo}>
                          <div className="row">
                            <div className="col-4">
                              <XCircleFill color="#0394db" size={25} />
                            </div>
                            <div className="col-8 justify-content-center align-self-center">
                              <small>Desvincular</small>
                            </div>
                          </div>
                        </Button>
                      </div>
                      <div className="text-end col-6">
                        {isInativar && (
                          <>
                            <small className="accordion-title-small">
                              Inativar Usuário
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="button-tooltip">Inativa o usuário da base do Conecta.</Tooltip>}>
                                <span variant="primary" className="ms-2" style={{ cursor: "pointer", color: "#0394db" }}>
                                  <QuestionCircleFill />
                                </span>
                              </OverlayTrigger>
                            </small>
                            <Button variant="link" className="accordion-btn-excluir" onClick={this.inativarUsuario} disabled={inativo}>
                              <div className="row">
                                <div className="col-4">
                                  <PersonDashFill color="red" size={25} />
                                </div>
                                <div className="col-8 justify-content-center align-self-center">
                                  <small>Inativar</small>
                                </div>
                              </div>
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Accordion.Collapse>
          </td>
        </tr>
        <Modal centered show={this.state.showModal} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">Informações</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <strong>Novo Usuário Óptica</strong> - Usuário sem módulos disponíveis. Gerente deve promovê-lo a Vendedor ou Balconista.
              </li>
              <li class="list-group-item">
                <strong>Marketing </strong> - Usuário responsável pelo Marketing da empresa.
              </li>
              <li class="list-group-item">
                <strong>Balconista</strong> - Vendedor sem visibilidade de orçamento ao final do pedido realizado.
              </li>
              <li class="list-group-item">
                <strong>Vendedor de Óptica</strong> - Vendedor com visibilidade de orçamento ao final do pedido realizado.
              </li>
              <li class="list-group-item">
                <strong>Gerente de Óptica</strong> - Gerente da empresa. Tem permissão para mudar os papéis dos funcionários.
              </li>
              <li class="list-group-item">
                <strong>Proprietário de Óptica</strong> - Proprietário da empresa. Tem permissão para mudar os papéis dos funcionários.
              </li>
            </ul>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default RolesManagementTableAccordionItem;
