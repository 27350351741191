import React, { Component } from "react";
import { get_users_data } from "../../Functions/usuario/get-users-data";

import "./info-cliente.css";
import SearchButton from "../suporte-inputs/search-button";
import InfoClienteResultCard from "./info-cliente-resultcard";
import InputCNPJ from "../suporte-inputs/Input-cnpj";
import InputEmail from "../suporte-inputs/Input-email";
import Form from "react-bootstrap/Form";

import Swal from "sweetalert2";

import AppContext from "../../appContext";

class InfoCliente extends Component {
  constructor() {
    super();

    this.state = {
      cnpj_empresa: "",
      email_cliente: "",
      email_clienteAUX: "",
      customer_data: "",
      search_method: "cnpj",
      precosServicos: [],
      shearchCustomer: 0,
    };

    InfoCliente.contextType = AppContext;

    this.handleChangeProps = this.handleChangeProps.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.SearchCustomer = this.SearchCustomer.bind(this);
  }


   componentDidMount() {
    document.addEventListener("keypress", this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener("keypress", this.handleKeyPress);
  }

  handleChangeProps(enable) {
    // 0 = inical busca
    // 1 = mudar quando for busca na proxima vez para 0
    // 2 = não buscar
    if (enable === 2) {
      this.setState({ shearchCustomer: 1 });
    } else if (this.state.shearchCustomer === 1) {
      this.setState({ shearchCustomer: 0 });
    } else {
      this.setState({ shearchCustomer: 0 });
    }
  }
  handleChange(event) {
    const { id, name, value } = event.target;

    if (name === "search_method") {
      this.setState({ [name]: id, cnpj_empresa: null, email_cliente: "", email_clienteAUX: "", customer_data: "", precosServicos: [] });

      return;
    }

    this.setState({ [name]: value });
  }

  async SearchCustomer() {
    this.setState({ shearchCustomer: 0 });
    this.context.showLoading("Buscando cliente...");

    this.setState({
      precosServicos: [],
    });
    this.state.precosServicos = [];
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (this.state.search_method === "cnpj") requestOptions["body"] = JSON.stringify({ cnpj: this.state.cnpj_empresa.replace(/[^0-9]/g, "") });
    else requestOptions["body"] = JSON.stringify({ email: this.state.email_cliente });

    fetch(process.env.REACT_APP_LABS_API + "get_account_data.php", requestOptions)
      .then(response => response.json())
      .then(json => {
        if (json?.status === 404) {
          this.context.hideLoading();

          Swal.fire({
            icon: "error",
            title: this.state.search_method === "cnpj" ? "CNPJ não encontrado" : "E-mail não encontrado",
            confirmButtonColor: "#00518b",
            customClass: {
              confirmButton: "x-follow-order__filter-btn",
            },
          });
          this.setState({ customer_data: "", shearchCustomer: 1 });
        } else {
          const data = json;
          requestOptions["body"] = JSON.stringify({ cnpj: data.cnpj });

          fetch(process.env.REACT_APP_LABS_API + "get_account_labs.php", requestOptions)
            .then(response => response.json())
            .then(json => {
              this.context.hideLoading();

              data["laboratorios"] = json?.laboratorios;
              data["email"] = this.state.search_method === "email" ? this.state.email_cliente : "";

              this.setState({
                customer_data: data,
              });
            });
        }
      });

    /// TK-083-000288 - [Suporte] Em Informações do Cliente, trazer a quais pastas do Preços e Serviços o usuário tem acesso (quando busca por email)

    const email = this.state.email_cliente;
    const parametros = {
      email: email,
      motivo: "financeiroArquivos",
    };

    const folders = await get_users_data(parametros);
    this.setState({ precosServicos: folders });
  }

  handleKeyPress = event => {
    if (event.key === "Enter" && this.state.shearchCustomer === 0) {
      this.SearchCustomer();
      this.setState({ shearchCustomer: 0 });
    } else if (this.state.shearchCustomer === 1) {
      this.setState({ shearchCustomer: 0 });
    }
  };
  render() {
    return (
      <div className="info-cliente_container d-flex justify-content-center">
        <div id="info-cliente-main">
          <div className="title-container">
            <h1 className="main-title">Informações do Cliente</h1>
            <span className="title-shape"></span>
          </div>
          <div className="row">
            <Form>
              <div key={`inline-radio`} className="mt-5 d-flex justify-content-center">
                <Form.Check defaultChecked inline label="CNPJ" name="search_method" type="radio" id="cnpj" onChange={this.handleChange} />
                <Form.Check inline label="E-mail" name="search_method" type="radio" id="email" onChange={this.handleChange} />
              </div>
            </Form>
          </div>

          <div className="row">
            {this.state.search_method === "cnpj" && (
              <InputCNPJ label="CNPJ da Empresa" name="cnpj_empresa" value={this.state.cnpj_empresa} onChange={this.handleChange} disabled={false} width={'60%'}/>
            )}
            {this.state.search_method === "email" && (
              <InputEmail label="E-mail do cliente" name="email_cliente" value={this.state.email_cliente} onChange={this.handleChange} disabled={false}  width={'60%'}/>
            )}
          </div>

          <div className="row">
            <SearchButton text="Consultar" onClick={this.SearchCustomer} />
          </div>

          {this.state.customer_data && (
            <InfoClienteResultCard
              data={this.state.customer_data}
              handleChangeProps={this.handleChangeProps}
              folders={this.state?.precosServicos}
              email_cliente={this.state.email_clienteAUX}
              search_method={this.state.search_method}
            />
          )}
        </div>
      </div>
    );
  }
}

export default InfoCliente;
