import React, { Component } from "react";
import AppContext from "../appContext";
import Back from "../main/back";
import "./pedido-atacado.css";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import logoAtacado from "./img/atacado_fundo_cinza_large.png";
import CustomButton from "../components/buttons/button";
import PedidoAtacadoEtapaUm from "./etapa-1/pedido-atacado-etapa-um";
import PedidoAtacadoEtapaDois from "./etapa-2/pedido-atacado-etapa-dois";
import PedidoAtacadoEtapaTres from "./etapa-3/pedido-atacado-etapa-tres";
import PedidoAtacadoEtapaQuatro from "./etapa-4/pedido-atacado-etapa-quatro";
import PedidoAtacadoEtapaCinco from "./etapa-5/pedido-atacado-etapa-cinco";
import { validateFields, reset } from "../components/utils/Validate-Fields";

import { useAccordionButton } from "react-bootstrap";

//Import external components
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { redirectPage } from "../components/utils/redirectpage";
import { Redirect } from "react-router";
import { DashCircleFill, PlusCircleFill, XCircleFill } from "react-bootstrap-icons";
import { DIAMETRO } from "../web-ordering/order/3_order_perscription/order-perscription-lists";

class PedidoAtacado extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      redirectL: false,
      redirectWO: false,
      numeroPedido: "",
      laboratorio: [],
      listaLaboratorio: [],
      onlyPreferidos: false,
      hasPreferidos: false,
      allPreferidos: false,
      hasLaboratorio: false,
      otica: {
        redeOticas: false,
      },
      redeOticas:false,
      empresaFaturamento: "",
      empresaFaturamento_cnpj: "",
      codigoParticipante: "",
      dados_empresa_faturamento_cnpj:"",
      produto: "",
      produtos: [],
      produtosList: [
        {
          id: 0,
          codigo: "",
          nome: "",
          quantidade: 0,
        },
      ],
      produtosTabela: [],
      tipo_produto: "",
      placeholder: "Nenhum resultado encontrado",
      dioptria: {
        id: 1,
        grau: 0,
        adicao: 0,
        quantidade: 1,
        base: 0,
        olho: "",
        diametro: 0,
        cilindrico: 0,
        esferico: 0,
        borderRed: "",
      },
      hasOrcamento: false,
      disableFields: false,
      observacao: "",
      resetarDioptria: false,
      orcamento: {
        dataPrevistaEntrega: "",
        valorLiquido: 0,
      },
      tipo_produtosOEC: {},
      open: "",
      arrow: "",
      adicao: 0,
      base: 0,
      cilindrico: 0,
      diametro: 0,
      esferico: 0,
      dioptrias: true,
    };

    this.refToFirstField = React.createRef();

    this.openSummary = this.openSummary.bind(this);

    PedidoAtacado.contextType = AppContext;
    this.setlabs = this.setlabs.bind(this);
    this.novaDioptria = this.novaDioptria.bind(this);
    this.changeDioptria = this.changeDioptria.bind(this);
    this.validarPedido = this.validarPedido.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.trocarLaboratorio = this.trocarLaboratorio.bind(this);
    this.corrigirPedido = this.corrigirPedido.bind(this);
    this.cleanFields = this.cleanFields.bind(this);
    this.inserirNovoProduto = this.inserirNovoProduto.bind(this);
    this.renderTableData = this.renderTableData.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.resetarForm = this.resetarForm.bind(this);
    this.changeTipoProduto = this.changeTipoProduto.bind(this);
    this.changeResetarDioptria = this.changeResetarDioptria.bind(this);
    this.validarDioptria = this.validarDioptria.bind(this);
    this.removerProduto = this.removerProduto.bind(this);
    this.changeTipoProdutosOEC = this.changeTipoProdutosOEC.bind(this);
    this.alterarQuantidadeProduto = this.alterarQuantidadeProduto.bind(this);

    if (localStorage.getItem("token") !== null) {
      var token = localStorage.getItem("token");

      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(""),
      );

      var jwt = JSON.parse(jsonPayload);
      this.setState({ name: jwt.usuario.nome, cpf: jwt.usuario.cpf });

      var myHeaders = new Headers({
        Authorization: "Bearer " + localStorage.getItem("session_token"),
      });

      const requestOptions = {
        method: "POST",
        body: JSON.stringify({ email: jwt.usuario.email }),
        headers: myHeaders,
      };

      fetch(process.env.REACT_APP_LABS_API + "getUserOEC.php", requestOptions)
        .then(response => {
          return response.json();
        })
        .then(json => {
          this.setState({ name: json.name, cpf: jwt.usuario.cpf });
        });
      const role = jwt.modulos.find(element => element === "Atacado");
      if (!role) {
        this.state = { redirect: true };
      }
      if (this.props.location.state === undefined) {
        this.state = { redirectWO: true };
      }
    } else {
      this.state = { redirectL: true };
    }
  }
  handleChange = (obj, callback) => {
    if (callback) {
      this.setState(obj, callback);
    } else {
      this.setState(obj);
    }
  };
  openSummary(e) {
    this.setState({ [e.target.id]: e.target.value }, () => {
      if (this.state.open) {
        this.setState({ open: "", arrow: "" });
      } else {
        this.setState({ open: "show-summary", arrow: "active-arrow" });
      }
    });
  }

  componentDidMount() {
    redirectPage(this.context.userSession, "/atacado");
    this.context.showLoading("");

    if (this.props.location.state !== undefined) {
      const hasLaboratorio = this.props.location.state.labCnpj;
      const laboratorio = this.props.location.state.laboratorio;

      if (hasLaboratorio && laboratorio) {
        this.handleChange({ hasLaboratorio: this.props.location.state.labCnpj });
        this.handleChange({ laboratorio: this.props.location.state.laboratorio });
        this.setlabs(this.props.location.state.laboratorio);
      }
    }
    // this.context.hideLoading();
  }

  setlabs(change) {
    this.context.showLoading("");
    this.handleChange(change, () => {
      this.getOtica();
      this.handleChange({ hasLaboratorio: this.state.laboratorio.cnpj });
    });
  }

  getOtica() {
    // this.context.showLoading("");
    const requestOptions1 = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cnpj: this.context.userSession.conta }),
    };

    fetch(process.env.REACT_APP_LABS_API + "get_account_data.php", requestOptions1)
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (this.context.userSession.redeOticas) {
          this.setState(
            {
              otica: {
                nome: json.nome,
                cnpj: json.cnpj,
              },
              empresaFaturamento: json.nome,
              cnpjOticaPrincipal:json.cnpj
            },           
            this.listarRedesOticas(),
          );
        } else if (!this.state.empresaFaturamento) {
          this.setState({
            otica: {
              nome: json.nome,
              cnpj: json.cnpj,
            },
            redeOticas: false,
            empresaFaturamento: json.nome,
            dados_empresa_faturamento: json.nome,
            dados_empresa_faturamento_cnpj: json.cnpj,
            hasLaboratorio: this.state.laboratorio.cnpj,
          });
          this.context.hideLoading();
        }
      });
  }

  listarRedesOticas = () => {
    this.context.showLoading("");
    if (this.context.userSession.modulos.includes("Consulta Rede")) {
      const requestOptions = {
        method: "GET",
        headers: {
          access_token: localStorage.getItem("token"),
          laboratorio: this.state.laboratorio.cnpj,
        },
      };

      this.context.showLoading("");
      fetch(process.env.REACT_APP_ESSILOR_API + "gestao-laboratorios/v1/redes", requestOptions)
        .then(response => {
          return response.json().then(json => {
            if (json.participantes) {
              const redes = [];
              json.participantes.map((value, index) => {
                if (value.cnpj === this.state.cnpjOticaPrincipal) {
                  this.setState({
                    otica: {
                      nome: value.descricao,
                      cnpj: value.cnpj,
                    },
                    empresaFaturamento: value.descricao,
                    dados_empresa_faturamento_cnpj: value.cnpj,
                  });
                }

                var rede = {
                  label: value.descricao,
                  value: value.cnpj,
                };

                redes.push(rede);
              });
              this.setState({
                redes: redes,
                redeOticas: true,
              });

              // this.context.hideLoading();
            } else {
              this.setState({
                redeOticas: false,
                empresaFaturamento: this.state.otica.nome,
              });
            }

            this.context.hideLoading();
          });
        })
        .catch(error => {
          if (error.response) {
            // erros 50x e 4xx com resposta do servidor
            if (error.response.status === 403) {
              this.context.hideLoading();
              Swal.fire({
                title: "Aviso",
                text: "Laboratório não disponível para este usuário no momento.",
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
              }).then(result => {
                if (result.value) {
                  this.setState({ redirect: true });
                }
              });
            } else if (error.response.status !== 200 && error.response.status !== 404) {
              this.setState({
                redeOticas: false,
                empresaFaturamento: this.state.otica.nome,
              });
            } /*else {
            // erros 50x sem resposta do servidor
            this.context.hideLoading();
            Swal.fire({
              title: "Aviso",
              text: "Não foi possível verificar se esta ótica pertence a um grupo dentro do laboratório.",
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ok",
            }).then(result => {
              if (result.value) {
                this.setState({ redirect: true });
              }
            });*/
          }
        });
    } else {
      this.setState({
        redeOticas: false,
        empresaFaturamento: this.state.otica.nome,
      });

      this.context.hideLoading();
    }
  };

  validarDioptria() {
    let valido = true;
    let mensagem = "Preencha os campos obrigatórios da dioptria para continuar.";
    let tipo_prod = this.state.tipo_produto;
    let dioptria = this.state.dioptria;

    if ((tipo_prod === "VISAO_SIMPLES" && !dioptria.cilindrico) || ("VISAO_SIMPLES" && !dioptria.esferico)) {
      valido = false;
    } else if ((tipo_prod === "VISAO_SIMPLES_SURFACADO" && !dioptria.cilindrico) || (tipo_prod === "VISAO_SIMPLES_SURFACADO" && !dioptria.esferico)) {
      valido = false;
    } else if ((tipo_prod === "MULTIFOCAL" && !dioptria.cilindrico) || (tipo_prod === "MULTIFOCAL" && !dioptria.esferico)) {
      valido = false;
    } else if ((tipo_prod === "MULTIFOCAL_ACABADO" && !dioptria.cilindrico) || (tipo_prod === "MULTIFOCAL_ACABADO" && !dioptria.esferico)) {
      valido = false;
    } else if ((tipo_prod === "BIFOCAL" && !dioptria.cilindrico) || (tipo_prod === "BIFOCAL" && !dioptria.esferico)) {
      valido = false;
    } else if ((tipo_prod === "BIFOCAL_ACABADO" && !dioptria.cilindrico) || (tipo_prod === "BIFOCAL_ACABADO" && !dioptria.esferico)) {
      valido = false;
    }

    if (valido) {
      this.novaDioptria();
    } else {
      Swal.fire({
        text: mensagem,
        icon: "error",
        title: "Aviso",
        confirmButtonColor: "#00518b",
        customClass: {
          confirmButton: "x-follow-order__filter-btn",
        },
      });
    }
  }

  novaDioptria() {
    let dioptriaAux = this.state.produtosList;
    let { codigo, nome } = this.state.produto;
    let novoId = 0;

    if (this.state.produtosList[this.state.produtosList.length - 1]) {
      novoId = this.state.produtosList[this.state.produtosList.length - 1].id + 1;
    }

    dioptriaAux.push({
      id: novoId,
      codigo: codigo,
      nome: nome,
      dioptria: this.state.dioptria,
    });

    this.handleChange({ dioptria: dioptriaAux });
  }

  changeDioptria(dioptria) {
    this.setState({ dioptria: dioptria });
  }

  handleChange(change, callback) {
    
    this.setState(change, () => {
      if (callback) {
        callback();
      }
    });
  }

  validarPedido() {
    let valido = true;
    let mensagem = "";
    let prodList = this.state.produtosTabela;
    const validateFields = this.validateFields("validar");
    if (validateFields && prodList.length === 0) {
      mensagem = "Insira ao menos um produto para prosseguir.";
      valido = false;
    } else if (
      validateFields &&
      prodList.every(row => {
        // Verifica se todas as quantidades dos produtos são válidas
        if (row.quantidade <= 0 || !Number.isInteger(row.quantidade)) {
          mensagem = `Corrija a quantidade do produto ${row.descricao} para prosseguir.`;
          return false;
        }
        return true;
      })
    ) {
      mensagem = "Insira ao menos um produto para prosseguir.";
      valido = true;
    } else {
      valido = false; // Pelo menos uma condição falhou, então não é válido
    }
    if (valido) {
      this.context.showLoading("Validando pedido...");
      let body = {
        idPedidoOtica: this.state.numeroPedido,
        observacao: this.state.observacao !== "" ? this.state.observacao : "",
        produtos: this.state.produtosTabela,
        vendedor: {
          nome: this.context.userSession.nome + " " + this.context.userSession.sobrenome,
          cpf: this.context.userSession.cpf,
        },
      };
      if (this.state.codigoParticipante) {
        body.programaLaboratorio = {
          codigoParticipante: this.state.codigoParticipante,
        };
      }
      let requestOptions1 = [];
      if ((this.state?.redes?.length > 0 && this.context.userSession.redeOticas) || this.context.userSession.papelCategoria === "ESSILOR") {
        requestOptions1 = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            access_token: localStorage.getItem("token"),
            laboratorio: this.state.laboratorio.cnpj,
            otica: this.state?.dados_empresa_faturamento_cnpj,
          },
          body: JSON.stringify(body),
        };
      } else {
        requestOptions1 = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            access_token: localStorage.getItem("token"),
            laboratorio: this.state.laboratorio.cnpj,
          },
          body: JSON.stringify(body),
        };
      }
      fetch(`${process.env.REACT_APP_ESSILOR_API}gestao-pedidos-laboratorio/v1/orcamentos-atacado`, requestOptions1)
        .then(response => {
          if (response.status === 201) {
            return response.json();
          } else if (response.status >= 400 && response.status < 499) {
            return response.json().then(json => {
              this.context.hideLoading();
              Swal.fire({
                html: json[0].mensagem,
                icon: "warning",
                title: "Aviso",
                width: "600px",
                confirmButtonColor: "#00518b",
                customClass: {
                  confirmButton: "x-follow-order__filter-btn",
                },
              }).then(result => {});
            });
          } else if (response.status === 504) {
            this.context.hideLoading();
            Swal.fire({
              html: "A requisição atingiu o tempo máximo de processamento. Tente novamente mais tarde.",
              icon: "warning",
              title: "Aviso",
              width: "600px",
              confirmButtonColor: "#00518b",
              customClass: {
                confirmButton: "x-follow-order__filter-btn",
              },
            }).then(result => {});
          } else {
            this.context.hideLoading();
            Swal.fire({
              html: "Tivemos uma falha momentânea de comunicação com o laboratório.",
              icon: "error",
              width: "600px",
              confirmButtonColor: "#00518b",
              customClass: {
                confirmButton: "x-follow-order__filter-btn",
              },
            }).then(result => {});
          }
        })
        .then(json => {
          if (json) {
            this.setState({
              orcamento: {
                prazo: json.dataEntregaPrevista,
                valorLiquido: json.valorLiquido,
              },
            });

            this.setState({
              orcamento: {
                prazo: json.dataEntregaPrevista,
                valorLiquido: json.valorLiquido,
              },
            });
            this.setState({ hasOrcamento: true, disableFields: true });
            this.context.hideLoading();
          }
        });
    } else if (Boolean(validateFields) !== false) {
      Swal.fire({
        text: mensagem,
        icon: "warning",
        title: "Aviso",
        confirmButtonColor: "#00518b",
        customClass: {
          confirmButton: "x-follow-order__filter-btn",
        },
      });
    }
  }

  corrigirPedido() {
    this.setState({ hasOrcamento: false, disableFields: false });
  }
  cleanFields(area = false) {
    if (area === "dioptrias") {
      this.refs.etapaTres.removeDioptria();
      // limpar so as dioptrias
      this.setState({ adicao: [], base: [], esferico: [], cilindrico: [], dioptrias: [] });
      this.validateFields("limparProduto", true);
    } else if (area === "produto") {
      this.setState({ produto: { codigo: "", nome: "" } });
    } else {
      //limpa tudo
      this.setState({
        adicao: [],
        base: [],
        esferico: [],
        cilindrico: [],
        diametro: [],
        produto: [],
        produtos: [],
      });
    }
  }
  trocarLaboratorio() {
    this.setState({
      redirect: false,
      redirectL: false,
      redirectWO: false,
      laboratorio: [],
      hasLaboratorio: false,
      produto: "",
      dioptria: [
        {
          id: 1,
          grau: 0,
          adicao: 0,
          quantidade: 1,
          base: 0,
          olho: "",
          diametro: 0,
          cilindrico: 0,
          esferico: 0,
          borderRed: "",
        },
      ],
    });
  }

  validateFields = (parametro, resetStyles = false) => {
    const camposMap = {
      dioptrias: ["inputProduto"],
      Adicionar: ["tipo_produto", "inputProduto", "esferico", "Adição", "base", "cilindrico"],
      validar: this.state.redeOticas ? ["numeroPedido", "dados_empresa_faturamento_cnpj"] : ["numeroPedido"],
      limparTipoProduto: ["tipo_produto", "inputProduto", "esferico", "Adição", "base", "cilindrico"],
      limparProduto: ["esferico", "Adição", "base", "cilindrico"],
    };

    const camposPreConfigurados = [
      { id: "tipo_produto", mensagem: "Tipo do produto", tipo: "" },
      { id: "inputProduto", mensagem: "Produto", tipo: "autoSelectDIV", idName: "produto" },
      { id: "esferico", mensagem: "Esférico", tipo: "autoSelectDIV", idName: "esferico" },
      { id: "Adição", mensagem: "Adição", tipo: "autoSelectDIV", idName: "Adição" },
      { id: "base", mensagem: "Base", tipo: "autoSelectDIV", idName: "base" },
      { id: "cilindrico", mensagem: "Cilíndrico", tipo: "autoSelectDIV", idName: "cilindrico" },
      { id: "numeroPedido", mensagem: "Nº do pedido da ótica", tipo: "" },
      { id: "dados_empresa_faturamento_cnpj", mensagem: "Empresa de faturamento", tipo: "" },
    ];
    const campos = camposMap[parametro]
      .map(campoId => {
        const campoEncontrado = camposPreConfigurados.find(preConfigurado => preConfigurado.id === campoId);
        return campoEncontrado ? campoEncontrado : null;
      })
      .filter(campo => campo !== null);

    if (resetStyles === true) {
      const resetStyles = reset(campos);
    } else {
      const camposValidados = validateFields(campos);

      return camposValidados;
    }
  };

  changeTipoProduto(tipo) {
    this.validateFields("limparTipoProduto", true);
    this.setState({ tipo_produto: tipo.tipo_produto });
    this.setState({
      dioptria: {
        id: 1,
        grau: 0,
        adicao: 0,
        quantidade: 1,
        base: 0,
        olho: "",
        diametro: 0,
        cilindrico: 0,
        esferico: 0,
        borderRed: "",
      },
    });
    this.setState({ resetarDioptria: true });
  }

  changeTipoProdutosOEC(tipo) {
    this.setState({ tipo_produtosOEC: tipo });
  }

  changeResetarDioptria(state) {
    this.setState({ resetarDioptria: state });
  }

  resetarForm() {
    this.setState({
      dioptria: {
        id: 1,
        grau: 0,
        adicao: 0,
        quantidade: 1,
        base: 0,
        olho: "",
        diametro: 0,
        cilindrico: 0,
        esferico: 0,
        borderRed: "",
      },
    });
    this.setState({ resetarDioptria: true });
  }

  inserirNovoProduto = async () => {
    if (this.validateFields("Adicionar") === false) return;

    let dioptria = this.state.dioptria;
    let tipo_prod = this.state.tipo_produto;
    let campo1 = "";
    let campo2 = "";
    let value1 = 0;
    let value2 = 0;
    let olho = "";

    let mensagem = "";
    let tipo = "";
    let titulo = "";
    let novoId = 0;
    let produtosListAux = [...this.state.produtosList];
    let { codigo } = this.state.produto;

    let produtosTabela = this.state.produtosTabela;

    if (tipo_prod === "LENTE_SIMPLES") {
      campo1 = "grauEsferico";
      campo2 = "grauCilindrico";
      value1 = dioptria.esferico.value;
      value2 = dioptria.cilindrico.value;
    } else if (tipo_prod === "BLOCO_VISAO_SIMPLES") {
      campo1 = "base";
      campo2 = "diametro";
      value1 = dioptria.base.value;
      value2 = dioptria.diametro.value;
      // Para não ser feito a validão do diametro
      // valido = !dioptria.base || !dioptria.diametro ? false : true;
    } else if (tipo_prod === "BIFOCAL_ACABADO") {
      campo1 = "grauEsferico";
      campo2 = "adicao";
      value1 = dioptria.esferico.value;
      value2 = dioptria.adicao.value;
    } else if (tipo_prod === "BIFOCAL") {
      campo1 = "base";
      campo2 = "adicao";
      value1 = dioptria.base.value;
      value2 = dioptria.adicao.value;
    } else if (tipo_prod === "MULTIFOCAL_ACABADO") {
      campo1 = "grauEsferico";
      campo2 = "adicao";
      value1 = dioptria.esferico.value;
      value2 = dioptria.adicao.value;
      olho = dioptria.olho;
    } else if (tipo_prod === "MULTIFOCAL") {
      campo1 = "base";
      campo2 = "adicao";
      value1 = dioptria.base.value;
      value2 = dioptria.adicao.value;
      olho = dioptria.olho;
    } else if (tipo_prod === "VISAO_INTERMEDIARIA") {
      campo1 = "grauEsferico";
      campo2 = "adicao";
      value1 = dioptria.esferico.value;
      value2 = dioptria.adicao.value;
      olho = dioptria.olho;
    }

    if (produtosTabela[produtosTabela.length - 1]) {
      novoId = produtosTabela[produtosTabela.length - 1].id + 1;
    } else {
      novoId = this.state.produtosList[this.state.produtosList.length - 1].id + 1;
    }

    this.context.showLoading("");
    var url = `${process.env.REACT_APP_ESSILOR_API}gestao-produtos-laboratorio/v1/produtos-atacado?tipoProduto=${tipo_prod}&codigoLinhaProduto=${codigo}&${campo1}=${value1}&${campo2}=${value2}`;
    if (olho && olho !== "") {
      url = url + `&olho=${olho}`;
    }
    let requestOptions = [];
    if ((this.state?.redes?.length > 0 && this.context.userSession.redeOticas)) {
      requestOptions = {
        method: "GET",
        headers: {
          access_token: localStorage.getItem("token"),
          client_id: localStorage.getItem("clientid"),
          laboratorio: this.state.laboratorio.cnpj,
          otica: this.state?.dados_empresa_faturamento_cnpj,
        },
      };
    } else {
      requestOptions = {
        method: "GET",
        headers: {
          access_token: localStorage.getItem("token"),
          client_id: localStorage.getItem("clientid"),
          laboratorio: this.state.laboratorio.cnpj,
        },
      };
    }

    await fetch(url, requestOptions)
      .then(response => {
        if (response.status === 200) {
          if (campo1 == "grauCilindrico") campo1 = "Cilíndrico";
          if (campo1 == "grauEsferico") campo1 = "Esférico";
          if (campo1 == "base") campo1 = "Base";
          if (campo1 == "adicao") campo1 = "Adição";
          if (campo2 == "grauCilindrico") campo2 = "Cilíndrico";
          if (campo2 == "grauEsferico") campo2 = "Esférico";
          if (campo2 == "base") campo2 = "Base";
          if (campo2 == "adicao") campo2 = "Adição";
          mensagem = `Os seguintes produtos não foram encontrados no laboratório: <br><b> &bull; ${this.state.produto.nome} </b> com <b>${
            campo1 && value1 ? `${campo1} ${value1}</b>` : ""
          } ${campo2 && value2 ? `</b> e <b>${campo2} ${value2}</b>` : ""}`;
          titulo = "Aviso";
          tipo = "warning";
          return response.json();
        } else if (response.status !== 200) {
          mensagem = "Falha de comunicação com o laboratório. Tente novamente mais tarde.";
          titulo = "Aviso";
          tipo = "warning";
        }
      })
      .then(json => {
        if (json && json[0]) {
          for (var i = 0; i < json.length; i++) {
            produtosListAux.push({
              codigo: json[i].codigo,
              descricao: json[i].descricao,
              quantidade: 1,
            });
          }

          let tabela = [...produtosTabela, ...produtosListAux]
            .filter(el => el.codigo !== "")
            .reduce((acc, el) => {
              const checkValue = acc.filter(elem => elem.codigo === el.codigo && elem.nome === el.nome);
              if (checkValue.length > 0) {
                checkValue[0].quantidade += el.quantidade;
                return [...acc];
              } else {
                return [...acc, el];
              }
            }, []);

          this.handleChange({
            produtosTabela: [...tabela],
          });
          this.resetarForm();
          this.cleanFields("dioptrias");
        } else {
          Swal.fire({
            html: mensagem,
            icon: tipo,
            title: titulo,
            width: "600px",
            confirmButtonColor: "#00518b",
            customClass: {
              confirmButton: "x-follow-order__filter-btn",
            },
          }).then(result => {});
        }
      });
    this.cleanFields("dioptrias");
    this.context.hideLoading();
  };

  renderTableData() {
    let produtos = this.state.produtosTabela;
    if (produtos) {
      return produtos.map((prod, i) => {
        if (prod.codigo !== "" || prod.descricao !== "" || prod.quantidade !== 0) {
          return (
            <tr key={prod.id + prod.codigo + prod.descricao} role="row" className="linha-produto">
              <td className="product-list-td" data-label="Código">
                <div>{prod.codigo}</div>
              </td>
              <td className="product-list-td" data-label="Descrição" style={{ minWidth: "300px" }}>
                <div>{prod.descricao}</div>
              </td>
              <td className="product-list-td dupla" data-label="Quantidade">
                <div>
                  <div className="row justify-content-center">
                    <div className="col-1 text-center justify-content-center align-self-center" style={{ padding: "0", width: "17px" }}>
                      <button
                        tabIndex="998"
                        type="button"
                        onClick={() => this.alterarQuantidadeProduto(prod.codigo, prod.quantidade, "diminuir")}
                        style={{ margin: "0 auto", width: "16px", height: "16px" }}>
                        <DashCircleFill className="me-2 icon-dash-plus" />
                      </button>
                    </div>
                    <div className="col-6 inputDiv">
                      <input
                        className="inputQntTable"
                        type="number"
                        value={prod.quantidade}
                        onChange={e => this.alterarQuantidadeProduto(prod.codigo, e.target.value, "input")}
                        inputmode="numeric"
                        pattern="[0-9]*"
                        min="1"
                        max="999"
                        minlength="1"
                        aria="Quantidade"
                      />
                    </div>
                    <div className="botao col-1 text-center justify-content-center align-self-center" style={{ padding: "0", width: "17px" }}>
                      <button
                        type="button"
                        onClick={() => this.alterarQuantidadeProduto(prod.codigo, prod.quantidade, "aumentar")}
                        tabIndex="999"
                        style={{ margin: "0 auto", width: "16px", height: "16px" }}>
                        <PlusCircleFill className="me-2 icon-dash-plus" />
                      </button>
                    </div>
                  </div>
                </div>
              </td>
              <td className="excluir">
                <div>
                  <Button
                    variant="link"
                    className="btn-excluir ps-1 p-0"
                    onClick={() => this.removerProduto(prod.codigo, prod.descricao)}
                    disabled={produtos.length < 1}
                    value={prod.id}>
                    <div className="row">
                      <div className="col-2">
                        <XCircleFill color="#dd0000" size={20} />
                      </div>
                    </div>
                  </Button>
                </div>
              </td>
            </tr>
          );
        }
      });
    }
  }

  removerProduto(codProd, nomeProd) {
    let auxProdList = this.state.produtosTabela;
    let indx = auxProdList.findIndex(v => v.codigo === codProd && v.descricao === nomeProd);
    auxProdList.splice(indx, indx >= 0 ? 1 : 0);

    this.handleChange({
      produtosTabela: auxProdList,
    });

    this.renderTableData();
  }

  alterarQuantidadeProduto(codProd, quantidade, modo) {
    let auxProdList = this.state.produtosTabela;
    const newData = auxProdList.map(row => {
      if (row.codigo === codProd && modo === "input") {
        return {
          ...row,
          quantidade: parseInt(quantidade),
        };
      } else if (row.codigo === codProd && modo === "diminuir") {
        let quant = !Number.isInteger(quantidade) ? 1 : parseInt(quantidade);
        return {
          ...row,
          quantidade: quant > 1 ? quant - 1 : quant,
        };
      } else if (row.codigo === codProd && modo === "aumentar") {
        let quant = !Number.isInteger(quantidade) ? 1 : parseInt(quantidade) + 1;
        return {
          ...row,
          quantidade: quant,
        };
      }
      return row;
    });

    this.setState({
      produtosTabela: newData,
    });

    this.renderTableData();
  }

  render() {
    const { produtosList, disableFields, tipo_produto, produto } = this.state;

    if (this.state.redirect) {
      return <Redirect to="/home" />;
    }
    if (this.state.redirectL) {
      return <Redirect to="/" />;
    }
    if (this.state.redirectWO) {
      return <Redirect to="/atacado-labs" />;
    }

    function CustomToggle({ children, eventKey, handleClick, classToggle }) {
      const decoratedOnClick = useAccordionButton(eventKey, () => {
        handleClick();
      });

      return (
        <li className={`x-web-ordering-pedido__summary--item`} onClick={decoratedOnClick}>
          <a
            href="javascript:;"
            className={`x-web-ordering-pedido__summary--link js--menu-pedido ${classToggle}`}
            data-stage={eventKey}
            onClick={decoratedOnClick}>
            {children}
          </a>
        </li>
      );
    }

    return (
      <main className="pedido-atacado">
        <div className="row m-0" style={{ position: "relative", overflow: "hidden" }}>
          {this.state.hasLaboratorio && (
            <>
              <div className="col-12 col-sm-8 p-5">
                <div className="mb-5">
                  <Back />
                </div>
                <div className="row container">
                  {!this.state.hasOrcamento ? (
                    <>
                      <div className="col-12">
                        <PedidoAtacadoEtapaUm
                          empresaFaturamento={this.state.empresaFaturamento}
                          otica={this.state.otica}
                          redes={this.state.redes}
                          redeOticas={this.state.redeOticas}
                          numeroPedido={this.state.numeroPedido}
                          disableFields={disableFields}
                          dados_empresa_faturamento_cnpj={this.state.dados_empresa_faturamento_cnpj}
                          onChange={this.handleChange}
                          PossuiProgramaFidelidadeProprio_c={this.props.location.state.laboratorio.PossuiProgramaFidelidadeProprio_c}
                        />
                      </div>
                      <div className="col-12">
                        <PedidoAtacadoEtapaDois
                          labcnpj={this.state.laboratorio.cnpj}
                          labId={this.props.location.state.labId}
                          tipo_produto={this.state.tipo_produto}
                          tipo_produtosOEC={this.state.tipo_produtosOEC?.OrdemCampos_c}
                          changeTipoProduto={this.changeTipoProduto}
                          changeTipoProdutosOEC={this.changeTipoProdutosOEC}
                          onChange={this.handleChange}
                          disableFields={disableFields}
                          refToFirstField={this.refToFirstField}
                          dioptrias={this.state.dioptrias}
                          produto={this.state.produto}
                          cleanFields={() => this.cleanFields("produto")}
                          validateFields={() => this.validateFields("limparTipoProduto", true)}
                          dados_empresa_faturamento_cnpj={this.state.dados_empresa_faturamento_cnpj}
                          redes={this.state.redes}
                        />
                      </div>
                      <div className="col-12 dioptria-section">
                        {produtosList.map((elem, index) => {
                          const { dioptria } = elem;
                          return (
                            <PedidoAtacadoEtapaTres
                              resetarDioptria={this.state.resetarDioptria}
                              changeResetarDioptria={this.changeResetarDioptria}
                              changeDioptria={this.changeDioptria}
                              handleOrderChange={this.handleChange}
                              disableFields={disableFields}
                              tipo_produto={this.state.tipo_produto}
                              produto={this.state.produto}
                              produtosTabela={this.state.produtosTabela}
                              produto_nome={this.state.produto.nome}
                              tipo_produtosOEC={this.state.tipo_produtosOEC}
                              laboratorio={this.state.laboratorio.cnpj}
                              refToFirstField={this.refToFirstField}
                              base={this.state.base}
                              cilindrico={this.state.cilindrico}
                              adicao={this.state.adicao}
                              diametro={this.state.diametro}
                              esferico={this.state.esferico}
                              dioptrias={this.state.dioptrias}
                              ref={"etapaTres"}
                              validateFields={() => this.validateFields("dioptrias")}
                              cleanFields={() => this.cleanFields("dioptrias")}
                              empresaFaturamento={this.state.empresaFaturamento}
                              dados_empresa_faturamento_cnpj={this.state.dados_empresa_faturamento_cnpj}
                              redes={this.state.redes}
                              numeroPedido={this.state.numeroPedido}
                            />
                          );
                        })}
                        <div className="row my-5">
                          <div className="col-12 text-center">
                            <CustomButton
                              label="Adicionar"
                              width="200px"
                              icon_search={false}
                              icon_arrow_left={false}
                              icon_arrow_right={false}
                              onClick={() => this.inserirNovoProduto()}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="tabela-produtos">
                          {this.state.produtosTabela.length > 0 && (
                            <table className="text-center table-striped table">
                              <thead className="thead-dark">
                                <tr className="titulo-tabela">
                                  <th className="" scope="col">
                                    Código
                                  </th>
                                  <th className="" scope="col">
                                    Descrição
                                  </th>
                                  <th className="" scope="col">
                                    Quantidade
                                  </th>
                                  <th className="" scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>{this.renderTableData()}</tbody>
                            </table>
                          )}
                        </div>

                        <PedidoAtacadoEtapaQuatro
                          disableFields={disableFields}
                          produto={this.state.produto}
                          onChange={this.handleChange}
                          observacao={this.state.observacao}
                          redes={this.state.redes}
                        />
                      </div>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-12">
                            <div className="float-end">
                              <CustomButton
                                label="Validar Pedido"
                                width="200px"
                                icon_search={false}
                                icon_arrow_left={false}
                                icon_arrow_right={true}
                                onClick={this.validarPedido}
                                
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <PedidoAtacadoEtapaCinco
                      pedidoOtica={this.state.numeroPedido}
                      prazo={this.state.orcamento.prazo}
                      empresa_faturamento={this.state.empresaFaturamento}
                      dados_empresa_faturamento_cnpj={this.state.dados_empresa_faturamento_cnpj}
                      empresa_faturamento_cnpj={this.state.hasLaboratorio}
                      pedido={this.state.jsonBody?.id}
                      labName={this.state.laboratorio && this.state.laboratorio.nome ? this.state.laboratorio.nome : ""}
                      corrigirPedido={this.corrigirPedido}
                      produtos={this.state.produtosTabela}
                      orcamento={this.state.orcamento}
                      jsonBody={this.state.jsonBody}
                      observacao={this.state.observacao}
                      otica={this.state.otica}
                      redeOticas={this.state.otica.redeOticas}
                      codigoParticipante={this.state.codigoParticipante}
                      redes={this.state.redes}
                    />
                  )}
                </div>
              </div>

              <div
                className={`x-web-ordering-pedido__summary_Atacado ${this.state.open}`}
                style={{
                  background: "#f3f3f5",
                  minHeight: "400px",
                }}>
                <span className={`x-web-ordering-pedido__summary--arrow ${this.state.arrow}`} onClick={this.openSummary}></span>
                <div className="row x-web-ordering-pedido__summary--container">
                  <div className="col-12">
                    <div className="x-user-consultant__left">
                      <h1 id="logoAtacado" className="x-user-consultant__title">
                        <img
                          src="https://ec2.beta.essilorconecta.com.br/modulos/atacado_fundo_cinza_large.984c6841.png"
                          width="45%"
                          className="x-web-ordering-tracking__summary--icon"
                          alt="Logo Financeiro"
                        />
                        {/* <img src={logoAtacado} width="178" height="120"></img> */}
                      </h1>
                      <p className="x-user-consultant__paragraph"></p>
                    </div>
                  </div>

                  <div className="col-12"></div>

                  <div className="logolab col-12 my-4">
                    <img ref="imagem" src={this.state.laboratorio.logo} width="178" height="120" />
                  </div>

                  <div className="col-12">
                    <p className="x-web-ordering-pedido__summary--paragraph mt-3">
                      <b>Dúvidas? Entre em contato com o laboratório:</b>
                      <br />
                      Telefone: {this.state.laboratorio && this.state.laboratorio.telefone ? this.state.laboratorio.telefone : ""}
                      <br />
                      Email: {this.state.laboratorio && this.state.laboratorio.email ? this.state.laboratorio.email : ""}
                      <br />
                      Site: {this.state.laboratorio && this.state.laboratorio.site ? this.state.laboratorio.site : ""}
                      <br />
                    </p>
                  </div>

                  <div className="col-12 text-center"></div>

                  {this.state.hasOrcamento && (
                    <div className="col-12 x-web-ordering-pedido__main" style={{ paddingLeft: 0 }}>
                      <ul className="x-web-ordering-pedido__summary--body" style={{ paddingLeft: 0 }}>
                        <Accordion>
                          <Card
                            style={{
                              backgroundColor: "rgba(243, 243, 245, 1)",
                              borderLeft: 0,
                              borderRight: 0,
                              borderBottom: "1px solid",
                              borderBottomColor: "#D8D8DA",
                            }}>
                            <Card.Header
                              as={Card.Header}
                              eventKey="0"
                              style={{
                                borderBottom: 0,
                                backgroundColor: "rgba(243, 243, 245, 1)",
                              }}>
                              <CustomToggle
                                as={Card.Header}
                                eventKey={"0"}
                                handleClick={() => {
                                  this.state.activeKey === "0"
                                    ? this.setState({
                                        activeKey: null,
                                      })
                                    : this.setState({
                                        activeKey: "0",
                                      });
                                }}
                                classToggle={this.state.activeKey === "0" ? "sign-show" : "sign-hiden"}>
                                Dados do Pedido
                              </CustomToggle>
                            </Card.Header>

                            <Accordion.Collapse eventKey="0">
                              <div>
                                <ul className="x-web-ordering-pedido__summary--card-text">
                                  <li className="x-web-ordering-pedido__summary--subitem-text title">Nº do pedido da ótica</li>
                                  <li className="x-web-ordering-pedido__summary--subitem-text desc">{this.state.numeroPedido}</li>
                                </ul>

                                <ul className="x-web-ordering-pedido__summary--card-text">
                                  <li className="x-web-ordering-pedido__summary--subitem-text title">Empresa de faturamento</li>
                                  <li className="x-web-ordering-pedido__summary--subitem-text desc">{this.state.empresaFaturamento}</li>
                                </ul>
                              </div>
                            </Accordion.Collapse>
                          </Card>
                          <Card
                            style={{
                              backgroundColor: "rgba(243, 243, 245, 1)",
                              borderLeft: 0,
                              borderRight: 0,
                              borderBottom: "1px solid",
                              borderBottomColor: "#D8D8DA",
                            }}>
                            <Card.Header
                              as={Card.Header}
                              eventKey="1"
                              style={{
                                borderBottom: 0,
                                backgroundColor: "rgba(243, 243, 245, 1)",
                              }}>
                              <CustomToggle
                                as={Card.Header}
                                eventKey={"1"}
                                handleClick={() => {
                                  this.state.activeKey === "1"
                                    ? this.setState({
                                        activeKey: null,
                                      })
                                    : this.setState({
                                        activeKey: "1",
                                      });
                                }}
                                classToggle={this.state.activeKey === "1" ? "sign-show" : "sign-hiden"}>
                                Produtos
                              </CustomToggle>
                            </Card.Header>

                            <Accordion.Collapse eventKey="1">
                              <div>
                                {this.state.produtosTabela.map((elem, index) => {
                                  return (
                                    <>
                                      <h3 className="x-web-ordering-pedido__summary--text">Produto {index + 1}</h3>
                                      <ul className="x-web-ordering-pedido__summary--card shape">
                                        <li className="x-web-ordering-pedido__summary--subitem">{elem.descricao || "-"}</li>
                                      </ul>

                                      <ul className="x-web-ordering-pedido__summary--card shape">
                                        <li className="x-web-ordering-pedido__summary--subitem">Código</li>
                                        <li className="x-web-ordering-pedido__summary--subitem right">{elem.codigo || "-"}</li>
                                      </ul>

                                      <ul className="x-web-ordering-pedido__summary--card shape">
                                        <li className="x-web-ordering-pedido__summary--subitem">Quantidade</li>
                                        <li className="x-web-ordering-pedido__summary--subitem right">{elem.quantidade || "-"}</li>
                                      </ul>
                                    </>
                                  );
                                })}
                              </div>
                            </Accordion.Collapse>
                          </Card>
                          <Card
                            style={{
                              backgroundColor: "rgba(243, 243, 245, 1)",
                              borderLeft: 0,
                              borderRight: 0,
                              borderBottom: "1px solid",
                              borderBottomColor: "#D8D8DA",
                            }}>
                            <Card.Header
                              as={Card.Header}
                              eventKey="2"
                              style={{
                                borderBottom: 0,
                                backgroundColor: "rgba(243, 243, 245, 1)",
                              }}>
                              <CustomToggle
                                as={Card.Header}
                                eventKey={"3"}
                                handleClick={() => {
                                  this.state.activeKey === "3"
                                    ? this.setState({
                                        activeKey: null,
                                      })
                                    : this.setState({
                                        activeKey: "3",
                                      });
                                }}
                                classToggle={this.state.activeKey === "3" ? "sign-show" : "sign-hiden"}>
                                Dados Adicionais
                              </CustomToggle>
                            </Card.Header>

                            <Accordion.Collapse eventKey="3">
                              <div>
                                <ul className="x-web-ordering-pedido__summary--card shape">
                                  <li className="x-web-ordering-pedido__summary--subitem">Observação</li>
                                  <li className="x-web-ordering-pedido__summary--subitem right">{this.state.observacao}</li>
                                </ul>
                              </div>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </main>
    );
  }
}

export default PedidoAtacado;
