async function getAccountData(cnpj) {
  
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({ cnpj: cnpj }),
    };
    try {
      return fetch(process.env.REACT_APP_LABS_API + "get_account_data.php", requestOptions)
        .then(response => {
          return response.json();
        })
        .then(json => {
          return json;
        })
        .catch(error => {
          console.error("Erro ao buscar os dados: ", error);
        });
    } catch (error) {
      console.error("Erro ao buscar os dados: ", error);
    }
  }
  
  export { getAccountData };
